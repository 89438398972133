module.exports = {
    debugMode: true,
    server: {
        api: {
            securityApi: process.env.REACT_APP_SECURITY_API || 'https://security-dev.yogabot.app/security/',
            biometricMatrixApi: process.env.REACT_APP_BIOMETRICMATRIX_API || 'https://biometricmatrix-dev.yogabot.app/biometricmatrix/',
            progressApi: process.env.REACT_APP_PROGRESS_API || 'https://progress-dev.yogabot.app/progress/',
            sessionApi: process.env.REACT_APP_SESSIONS_API || 'https://sessions-dev.yogabot.app/session/',
            questionApi: process.env.REACT_APP_QUESTION_API || 'https://questions-dev.yogabot.app/question/',
            paymentApi: process.env.REACT_APP_PAYMENT_API || "https://payments-dev.yogabot.app/payment/",
            schoolApi: process.env.REACT_APP_SCHOOL_API || 'https://school-dev.yogabot.app/school/',
            filesApi: process.env.REACT_APP_FILES_API || 'https://files-dev.yogabot.app/files/',
            statsApi: process.env.REACT_APP_STATS_API || 'https://stats-dev.yogabot.app/stat/',
            timeout: 20000,
            headTimeout: 2000
        }
    },
    firebase: {
        apiKey: "AIzaSyBAzGEKHZueNtT5gZuthsM6Ve92bIR1Aog",
        authDomain: "yogabot-a7a07.firebaseapp.com",
        databaseURL: "https://yogabot-a7a07.firebaseio.com",
        projectId: "yogabot-a7a07",
        storageBucket: "yogabot-a7a07.appspot.com",
        messagingSenderId: "947474339915",
        appId: "1:947474339915:web:298ed3ccf3a69f4777d5ce",
        measurementId: "G-ZFSZGZMK82"
    }
};