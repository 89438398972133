import React, { useState } from 'react';
import _ from 'lodash';
import { FrameClose, FrameModal, ModalContent, Actions } from '../../sharedStyles';
import OButton from '../../../../styled/components/button';
import { Checkbox, Form, Icon, Radio } from 'semantic-ui-react';
import { BlockForm, DayWrapper, FormItem, Time, TimeWrapper } from './styles/styled';
import { daysOfWeek } from '../../../../../config/constants/daysOfWeek';
import I18n from '../../../../../i18n';
import moment from 'moment';

const format = 'HH:mm';

/***
 * * SCHEDULE MODAL
 * 
 * @param buttons Array Objects
 * @param onClose Function
 * @param title String
 * @params @buttons { text, callback, options = { any button props } }
 */

export default function ScheduleModal ({ title = {},  buttons = [], onClose, ...restProps }) {

    const [time, onChangeTime ] = useState(_.cloneDeep(daysOfWeek));
    const [all, onChangeAllTime ] = useState({
        ini: moment().hour(0).minute(0),
        end: moment().hour(0).minute(0)
    });

    const [inline, toggleInline] = useState(false);

    const onCallback = callback => {

        let callbackValues = [];

        if (typeof callback === 'function') {

            _.each(time, vtime => {

                if (inline && (vtime.checked && all.ini && all.end) ) {

                    callbackValues.push({dayOfWeek: vtime.dayOfWeek, ini: all.ini, end: all.end });

                } else if (!inline && (vtime.checked && vtime.ini && vtime.end) ) {

                    callbackValues.push({dayOfWeek: vtime.dayOfWeek, ini: vtime.ini, end: vtime.end });

                }

            })

            callback(_.cloneDeep(callbackValues));

        }

    }

    const onChangeInitEndTime = (value, name) => {

        let tempTime = _.cloneDeep(time);
        _.set(tempTime, name, value );
        onChangeTime(tempTime);

    }

    const onChangeAllInitEndTime = (value, name) => {

        let tempTime = _.cloneDeep(all);
        _.set(tempTime, name, value);
        onChangeAllTime(tempTime);

    }

    const onChecked = (name, checked) => {

        let tempTime = _.cloneDeep(time);
        _.set(tempTime, name, checked);
        onChangeTime(tempTime);

    }

    const onToggleInline = state => {


        toggleInline(state);
        //reset to default values
        onChangeTime(_.cloneDeep(daysOfWeek));
        

    }

    return (
        <FrameModal>
            <FrameClose>
                <Icon name="close" size="large" onClick={ onClose }/>
            </FrameClose>
            <ModalContent>
                {!_.isEmpty(title) && <p className={title.classes.join(" ")} >{title.text}</p>}
                <BlockForm>
                    <Form>
                        <Form.Field>
                            <Radio label={I18n.t('profile.sameDayEveryDay')} onChange={(e) => onToggleInline(true)} checked={inline}/>
                        </Form.Field>
                        <Form.Field>
                            <Radio label={I18n.t('profile.differentDays')} onChange={(e) => onToggleInline(false)} checked={!inline}/>
                        </Form.Field>
                        <FormItem inline={inline}>
                            <DayWrapper>
                                <Checkbox label={I18n.t('profile.monday')} name="monday.checked" checked={time.monday.checked} onChange={(e, { name, checked }) => onChecked(name, checked)}/>
                            </DayWrapper>
                            {!inline && <TimeWrapper>
                                <Time
                                    name="monday.ini"
                                    value={time.monday.ini}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'monday.ini')}
                                    disabled={!time.monday.checked}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                />
                                <span className="labels regular" style={{margin:'0 5px'}}>a</span>
                                <Time
                                    name="monday.end"
                                    value={time.monday.end}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'monday.end')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.monday.checked}
                                />
                            </TimeWrapper>}
                        </FormItem>
                        <FormItem inline={inline}>
                            <DayWrapper>
                                <Checkbox label={I18n.t('profile.tuesday')} name="tuesday.checked" checked={time.tuesday.checked} onChange={(e, { name, checked }) => onChecked(name, checked)} />
                            </DayWrapper>
                            {!inline && <TimeWrapper>
                                <Time
                                    name="tuesday.ini"
                                    value={time.tuesday.ini}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'tuesday.ini')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    icon={false}
                                    disabled={!time.tuesday.checked}
                                />
                                <span className="labels regular" style={{margin:'0 5px'}}>a</span>
                                <Time
                                    name="tuesday.end"
                                    value={time.tuesday.end}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'tuesday.end')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.tuesday.checked}
                                />
                            </TimeWrapper>}
                        </FormItem>
                        <FormItem inline={inline}>
                            <DayWrapper>
                                <Checkbox label={I18n.t('profile.wednesday')} name="wednesday.checked" checked={time.wednesday.checked} onChange={(e, { name, checked }) => onChecked(name, checked)} />
                            </DayWrapper>
                            {!inline && <TimeWrapper>
                                <Time
                                    name="wednesday.ini"
                                    value={time.wednesday.ini}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'wednesday.ini')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.wednesday.checked}
                                />
                                <span className="labels regular" style={{margin:'0 5px'}}>a</span>
                                <Time
                                    name="wednesday.end"
                                    value={time.wednesday.end}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'wednesday.end')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.wednesday.checked}
                                />
                            </TimeWrapper>}
                        </FormItem>
                        <FormItem inline={inline}>
                            <DayWrapper>
                                <Checkbox label={I18n.t('profile.thursday')}  name="thursday.checked" checked={time.thursday.checked} onChange={(e, { name, checked }) => onChecked(name, checked)} />
                             </DayWrapper>
                             {!inline && <TimeWrapper>
                                <Time
                                    name="thursday.ini"
                                    value={time.thursday.ini}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'thursday.ini')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.thursday.checked}
                                />
                                <span className="labels regular" style={{margin:'0 5px'}}>a</span>
                                <Time
                                    name="thursday.end"
                                    value={time.thursday.end}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'thursday.end')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.thursday.checked}
                                />
                            </TimeWrapper>}
                        </FormItem>
                        <FormItem inline={inline}>
                            <DayWrapper>
                                <Checkbox label={I18n.t('profile.friday')} name="friday.checked" checked={time.friday.checked} onChange={(e, { name, checked }) => onChecked(name, checked)} />
                            </DayWrapper>
                            {!inline && <TimeWrapper>
                                <Time
                                    name="friday.ini"
                                    value={time.friday.ini}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'friday.ini')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.friday.checked}
                                />
                                <span className="labels regular" style={{margin:'0 5px'}}>a</span>
                                <Time
                                    name="friday.end"
                                    value={time.friday.end}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'friday.end')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.friday.checked}
                                />
                            </TimeWrapper>}
                        </FormItem>
                        <FormItem inline={inline}>
                            <DayWrapper>
                                <Checkbox label={I18n.t('profile.saturday')} name="saturday.checked" checked={time.saturday.checked} onChange={(e, { name, checked }) => onChecked(name, checked)} />
                            </DayWrapper>
                            {!inline && <TimeWrapper>
                                <Time
                                    name="saturday.ini"
                                    value={time.saturday.ini}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'saturday.ini')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.saturday.checked}
                                />
                                <span className="labels regular" style={{margin:'0 5px'}}>a</span>
                                <Time
                                    name="saturday.end"
                                    value={time.saturday.end}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'saturday.end')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.saturday.checked}

                                />
                            </TimeWrapper>}
                        </FormItem>
                        <FormItem inline={inline}>
                            <DayWrapper>
                                <Checkbox label={I18n.t('profile.sunday')} name="sunday.checked" checked={time.sunday.checked} onChange={(e, { name, checked }) => onChecked(name, checked)} />
                            </DayWrapper>
                            {!inline && <TimeWrapper>
                                <Time
                                    name="sunday.ini"
                                    value={time.sunday.ini}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'sunday.ini')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.sunday.checked}
                                />
                                <span className="labels regular" style={{margin:'0 5px'}}>a</span>
                                <Time
                                    name="sunday.end"
                                    value={time.sunday.end}
                                    onChange={(mtime) => onChangeInitEndTime(mtime, 'sunday.end')}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    disabled={!time.sunday.checked}

                                />
                            </TimeWrapper>}
                        </FormItem>
                        {inline && 
                            <FormItem>
                                <Time
                                    name="ini"
                                    value={all.ini}
                                    onChange={(mtime) => onChangeAllInitEndTime(mtime, 'ini')}
                                    disabled={!_.find(time, item => item.checked)}
                                    showSecond={false}
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    
                                />
                                <span className="labels regular" style={{margin:'0 5px'}}>a</span>
                                <Time
                                    name="end"
                                    value={all.end}
                                    onChange={(mtime) => onChangeAllInitEndTime(mtime, 'end')}
                                    icon={false}
                                    showSecond={false}
                                    disabled={!_.find(time, item => item.checked) }
                                    inputReadOnly
                                    minuteStep={15}
                                    format={format}
                                    
                                />
                            </FormItem>
                        }

                    </Form>
                </BlockForm>
            </ModalContent>
            <Actions>
                    {
                        _.map(buttons, ({text, callback, options }, index) => (
                            <OButton key={index} type="button" {...options} onClick={ () => onCallback(callback) }><span>{text}</span></OButton>
                        ))
                    }
                </Actions>
        </FrameModal>
    )

}
