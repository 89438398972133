import _ from 'lodash';
import React from 'react';

import { FrameCircle, FrameCirclePercent, CircleSvg, CircleShape, CircleNumber } from './styles';

function CircleProgress({percent}) {
 
    return !_.isNaN(percent) && (
        <FrameCircle>
            <FrameCirclePercent>
                <CircleSvg>
                    <CircleShape r="43" cx="43" cy="43"></CircleShape>
                    <CircleShape r="43" cx="43" cy="43" style={{ stroke: getProgressColor(percent || 0) }}>
                        <animate attributeType="XML" begin="1s" attributeName="stroke-dashoffset" from="270" to={( 270 - (270 * percent || 0) / 100) } dur="2s" fill="freeze"/> 
                    </CircleShape>
                </CircleSvg>
                <CircleNumber ><span style={{ color: getProgressColor(percent) }} className="heading-3">{ percent }%</span></CircleNumber>
            </FrameCirclePercent>
        </FrameCircle>
    );

}


function getProgressColor(percent) {

    if (percent < 50) {

        return '#e20d0d';

    } else if (percent >= 50 && percent < 75) {

        return '#ffcf00';

    } else {

        return '#1FD5B9'
    }

}

export default CircleProgress;