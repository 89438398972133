import React from 'react';
import _ from 'lodash';
import { Checkbox, Form } from 'semantic-ui-react';

class CheckboxList extends React.Component {

    onChange(value, checked) {

        let result = [ ...this.props.input.value ];

        if (!checked) {

            result.splice(_.findIndex(result, item => item === value), 1);

        } else {

            result.push(value);

        }

        this.props.input.onChange(result);

    }


    render() {

        return (

            <Form.Field className={ this.props.fieldClasses || this.props.className}>
                <div className="inline field">
                    <Checkbox
                        value={ this.props.id }
                        onChange={ (e, v) => { this.onChange(this.props.id, v.checked) } }
                        checked={ _.findIndex(this.props.input.value, item => item === this.props.id) > -1 }
                        disabled={ this.props.disabled || false }
                    />
                </div>
            </Form.Field>
        )
    }

};

export default CheckboxList;