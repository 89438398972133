
export const DEFAULT_ADVISOR = {

    advisorSuggestions: "",
    advisorSequenceSuggestions: [],
    advisorSchoolSuggestions: [],
    advisorRestrictions: "",
    advisorToPowered: "",
    advisorSummary: "",
    advisorRoutine: [],

    tutorSuggestions: "",
    tutorSequenceSuggestions: [],
    tutorSchoolSuggestions: [],
    tutorRestrictions: "",
    tutorToPowered: "",
    tutorSummary: "",
    tutorRoutine: [],
    tutorMessageToAdvisor: "",
    tutorMessageToStudent: "",

    suggestions: "",
    sequenceSuggestions: [],
    schoolSuggestions: [],
    restrictions: "",
    toPowered: "",
    summary: "",
    routine: [],
    
    messageToChecker: "",
    messageToTutor: "",
    messageToStudent: "",
    messageToAdvisor: "",

    
}