import _ from 'lodash';
import React, { Component } from 'react';
import I18n from '../../../../../i18n';
import DonutChartView from './view';

class DonutChartContainer extends Component {


    constructor(props) {
        super(props);

        this.state = {
            transformedData:[]
        }

    }
    componentDidMount() {

        this.transformData();

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.primaryData, this.props.primaryData) || !_.isEqual(prevProps.secondaryData, this.props.secondaryData)) {
            
            this.transformData();

        }
    
    }
    
    transformData = () => {

        const { enviroment } = this.props;

        switch(enviroment) {

            case 'queriesAcceptedAndDenied':
                
                this.transformQueriesAcceptedAndDenied();

                break;
            case 'queriesCorrectedAndIncorrected':

                this.transformQueriesCorrectedAndIncorrected();

                break;
            default:
                return null;

        }

    }

    transformQueriesCorrectedAndIncorrected = () => {

        const { primaryData } = this.props;

        let correctedQueries = _.filter(primaryData, ({corrected}) => corrected);

        let transformedData = [
            {
                category : I18n.t('resumen.correct'),
                value : _.size(correctedQueries)
            },
            {
                category: I18n.t('resumen.incorrect'),
                value : (primaryData.length - _.size(correctedQueries)) || 0
            }
        ]

        this.setState({transformedData});

    }

    transformQueriesAcceptedAndDenied = () => {

        const { primaryData , secondaryData } = this.props;

        let transformedData = [
            {
                category : I18n.t('resumen.accepted'),
                value : _.size(primaryData)
            },
            {
                category: I18n.t('resumen.denies'),
                value : _.size(secondaryData)
            }
        ]

        this.setState({transformedData});

    }

    render() {

        const { transformedData } = this.state;
        const { chartText } = this.props;

        return (
            <DonutChartView data={transformedData} chartText={chartText}/>
        );
    }
}

export default DonutChartContainer;