import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import CustomInput from '../../../../../components/form/input'
import _ from 'lodash';
import I18n from '../../../../../../i18n';
import { Form } from 'semantic-ui-react';
import { Col, Row } from '../../../../../styled/components/wrappers';
import OButton from '../../../../../styled/components/button';

 class EditEmailForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
                 
        }

    }

    onEditEmail = email => this.props.onSaveEmail(email);
        
    render() {
        return (
            <Form name="profileEmailForm" noValidate onSubmit={this.props.handleSubmit(this.onEditEmail)} style={{marginTop: '10px'}}>
                <Row>
                    <Col equal>
                        <Field
                            component={ CustomInput }
                            name='email'
                            label={ I18n.t("auth.email") }
                            />
                    </Col>
                </Row>
                <Row flexEnd>
                    <Col>
                        <OButton primary type="submit" color="#fff" regular size="small" >
                            <span>{I18n.t('actions.save')}</span>
                        </OButton>
                    </Col>
                </Row>
                
            </Form>
        )
    }
}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    return errors;

};

export default reduxForm({
    form: 'profileEmailForm',
    touchOnBlur: true,
    touchOnChange: true,
    enableReinitialize:true,
    validate
})(connect(null, { })(EditEmailForm));
