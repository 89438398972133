import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import Modals from '../ui/components/modals';

import { getProfile } from '../redux-store/auth';
import { openLoader, closeLoader } from '../redux-store/loader';
import { getUser } from '../redux-store/role';

//rutas
import MyQueries from './routes/queries';
import MyUsers from './routes/users';
import MyTeam from './routes/myTeam';
import MyResume from './routes/resume';
import Profile from './routes/profile';
import NotFoundPage from './routes/404';
import Login from './routes/auth/login';
import RecoveryStep1 from './routes/auth/recovery/step1';
import RecoveryStep2 from './routes/auth/recovery/step2';
import RecoveryStep3 from './routes/auth/recovery/step3';
import VerifyEmail from './routes/auth/recovery/verifyEmail';
import RegisterStep1Fill from './routes/auth/register/step1/fill';
import User from './routes/users/components/user';
import Query from './routes/queries/components/query';
import { Tos } from './routes/legal/components/tos';

import I18n from '../i18n';

const PrivateRoute = ({ ...props }) => window.localStorage.getItem('jwt') ? <Route { ...props } /> : <Redirect to="/auth/login" />;

class App extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loaded: false
        }

    }

    async componentDidMount() {

        try {

            this.props.openLoader();

            if (window.localStorage.getItem('jwt') && moment(JSON.parse(atob(window.localStorage.getItem('jwt').split('.')[1])).exp * 1000).diff(moment(), 'hour') > 1) {

                await this.props.getProfile();
                await this.props.getUser();

            } else {

                window.localStorage.removeItem('jwt');

            }

        } catch (codeError) {

            console.error('App: codeError', codeError)

        } finally {

            this.setState({ loaded: true });
            this.props.closeLoader();

        }

    }

    render() {

        return this.state.loaded && (
            <I18nextProvider i18n={ I18n }>
                <HashRouter>
                    <Switch>
                        <Route path="/auth/login" exact component={ Login } />
                        <Route path="/auth/recovery/step/1" exact component={ RecoveryStep1 } />
                        <Route path="/auth/recovery/step/2/:token" exact component={ RecoveryStep2 } />
                        <Route path="/auth/recovery/step/3" exact component={ RecoveryStep3 } />
                        <Route path="/auth/register/step/1/fill" exact component={ RegisterStep1Fill } />
                        <Route path="/auth/verifyEmail/:token" exact component={ VerifyEmail } />
                        <Route path="/legal/tos" exact component={ Tos } />
                        <PrivateRoute path="/resume" exact component={ MyResume } />
                        <PrivateRoute path="/profile" exact component={ Profile } />
                        <PrivateRoute path="/queries" exact component={ MyQueries } />
                        <PrivateRoute path="/queries/query/:queryId" exact component={ Query } />
                        <PrivateRoute path="/users" exact component={ MyUsers } />
                        <PrivateRoute path="/user/:userId" component={ User } />
                        <PrivateRoute path="/myTeam" exact component={ MyTeam } />
                        <Route path="/" exact component={ Login } />
                        <Route path="/404" component={ NotFoundPage } />
                        <Route path="*" component={ NotFoundPage } />
                    </Switch>
                </HashRouter>
                <Modals />
                <ToastContainer autoClose={ 2000 } />
            </I18nextProvider>
        );

    }

}

export default connect(null, { closeLoader, getProfile, getUser, openLoader })(App);