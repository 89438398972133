import styled from 'styled-components/macro';
import Checkbox from '../../../components/form/checkbox';

const FormContent = styled.div`

    max-width:700px;

`;


const BlockPicture = styled.div`
    margin-bottom:30px;
`;

const BlockEmail = styled.div`
    margin-bottom:30px;
`;


const PictureContent = styled.div`
    margin-top:20px;
    display:flex;
    align-items:center;
`;

const Picture = styled.div`
    width:55px;
    height:55px;
    display:inline-block;
    border-radius:10px;
    overflow:hidden;
    background:#fff;
    margin-right:10px;
    img {
        width:100%;
        height:100%;
        object-fit:cover;
    }
`;

const TimeRow = styled.div`
    padding:10px;
    border-bottom: solid 1px var(--border-color);
    display:flex;
    justify-content:space-between;
    i {
        cursor:pointer;
        &:before {
            color:var(--dark-grey-2);
        }
    }
   
`;

const TimeContainer = styled.div`

    margin-bottom:30px;

`;

const StyledToggleCheckbox = styled(Checkbox)`

    &&& {
        input:checked~label, input:focus:checked~label{
            &:before {
                background-color: var(--brand-secondary)!important;
            }
        }
        
    }

`;

export {

    FormContent,
    BlockPicture,
    PictureContent,
    Picture,
    BlockEmail,
    TimeRow,
    TimeContainer,
    StyledToggleCheckbox

}