import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { Nav, ContentNav, NavItem, Dimmer, SideBarMobile, SideBarItems, SideBarItem, CloseMenu} from "../../styles/styled";
import YogaIcon from '../../../../assets/img/YOGABOT-ADVISOR.png';
import I18n from '../../../../../i18n';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ROLES } from '../../../../../config/constants/roles';

function MainNav({advisorVerified, history, role }) {

    const [expanded, onToggle ] = useState(false);

    useEffect(() => {

        expanded ? (document.body.style.overflowY = 'hidden') : (document.body.style.overflowY = 'visible')

        return () => document.body.style.overflowY = 'visible'
 
     }, [expanded]);
    
    return (
        <React.Fragment>
            <Nav>
                <ContentNav>
                    {advisorVerified && <NavItem toggleIcon onClick={()=> onToggle(true)}>
                        <Icon name="bars" size="large" />
                    </NavItem>}
                    <NavItem branded>
                        <img src={YogaIcon} alt="" />
                    </NavItem>
                    <NavItem onClick={() => history.push('/profile')}>
                        <Icon name="user" size="large" />
                    </NavItem>
                </ContentNav>
            </Nav>
            <SideBarMobile expanded={expanded}>
                <CloseMenu className="sp-close" onClick={() => onToggle((prevExpanded) => !prevExpanded)}><i className="f-icon-cancel"></i></CloseMenu>
                <SideBarItems className="sp-menu-items">
                   <SideBarItem onClick={() => history.push('/resume')}>
                       <span className="labels upper">{I18n.t('navigation.resume')}</span>
                   </SideBarItem>
                   <SideBarItem onClick={() => history.push('/queries')}>
                       <span className="labels upper">{I18n.t('navigation.myQueries')}</span>
                   </SideBarItem>
                   <SideBarItem onClick={() => history.push('/users')}>
                       <span className="labels upper">{I18n.t('navigation.myUsers')}</span>
                   </SideBarItem>
                   {role.indexOf(ROLES.PRETORIAN) > -1 && <SideBarItem onClick={() => history.push('/myTeam')}>
                       <span className="labels upper">{I18n.t('navigation.myTeam')}</span>
                   </SideBarItem>}
                </SideBarItems>
            </SideBarMobile>
            {expanded && <Dimmer onClick={() => onToggle((prevExpanded) => !prevExpanded)}></Dimmer>}
        </React.Fragment>
    )

}

const mapStateToProps = state => {

    return {
        advisorVerified: _.get(state, 'role.advisorVerified', {}),
        role: _.get(state, 'role.role', {}),
    };
    
};

export default connect(mapStateToProps, {})(MainNav);
