import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import AsideMenu from '../../components/asideMenu';
import HeaderElement from '../../components/headerElement';
import TopNav from '../../components/topNav';

import OButton from '../../styled/components/button';
import { Picture, PictureBlock, StatusItem, YRow, YTable } from '../../styled/components/tables';
import { Container, Content, Inner, InnerContent } from '../../styled/components/wrappers';

import { InvitationBlock } from './styles/styled';

import { openLoader, closeLoader } from '../../../redux-store/loader';
import { openModal, closeModal } from '../../../redux-store/modals';

import QuestionModel from '../../../data/models/questions/questions';
import SecurityModel from '../../../data/models/security/security';

import { indexedCountries } from '../../../config/constants/countries';

import I18n from '../../../i18n';

import placeHolderImage from '../../assets/img/yogabot_icon.svg';

class MyUsers extends Component {

    constructor(props) {

        super(props);

        this.state = {
            searchValue: '',
            originalList: [],
            list: []
        };

    }

    componentDidMount() {

        this.getData();

    }

    componentWillUnmount() {

        this.props.closeModal();
        
    }

    getData = async () => {

        try {

            this.props.openLoader()
            const { data } = await QuestionModel.getAssignedAdvisorUsers();
            this.setState({ list: _.orderBy(data, ['name'], ['asc']), originalList: _.orderBy(data, ['name'], ['asc']) });

        } catch (error) {

            console.error('error', error)
            
        } finally {

            this.props.closeLoader();

        }

    }

    onSearchValue = value => this.setState({ searchValue: value, list: this.getTableResult(this.state.originalList, value) });

    getTableResult = (data, searchValue) => _.filter(data, ({ name }) => searchValue === '' || (!!name && name.toLowerCase().includes(searchValue.toLowerCase())));

    openInvitationModal = () => {

        const { openModal, closeModal, userSession: { nickname} } = this.props;
        
        openModal({
            type: 'invitation',
            title:{
                text: I18n.t('actions.sendInvitation'),
                classes: ['heading-2', 'upper']
            },
            nickname: nickname,
            button:{
                text: I18n.t('actions.sendInvitation'),
                callback: emails => {

                    this.sendEmails(emails);
                    closeModal();

                },
                options: {
                    primary: true,
                    color: '#fff',
                    fluid: false,
                    upper: true,
                    regular: true,
                    size: 'small'
                }
            }
        }, true, 'small');

    }

    sendEmails = async emails => {

        try {
            
            this.props.openLoader();
            await SecurityModel.sendInvitationFromAdvisor(emails);

        } catch (error) {
            
            console.error('error');

        } finally {

            this.props.closeLoader();

        }

    }

    render() {

        const { searchValue, list } = this.state;
        const { history } = this.props;

        return (
            <Container>
                <TopNav {...this.props } />
                <Inner>
                    <AsideMenu active="users" {...this.props }/>
                    <Content>
                        <HeaderElement type="search" title={I18n.t('users.myUsers')} searchValue={ searchValue } onSearchValue={ this.onSearchValue } />
                        <InnerContent>
                            <InvitationBlock>
                                <OButton primary type="button" color="#fff" regular size="small" onClick={this.openInvitationModal}>
                                    <span>{I18n.t('actions.sendInvitation')}</span>
                                </OButton>
                            </InvitationBlock>
                        </InnerContent>
                        <InnerContent>
                            { !_.isEmpty(list) ? <YTable stackable padded='very' selectable style={{marginTop:'20px'}}>
                                <YTable.Header>
                                    <YRow>
                                        <YTable.HeaderCell>{I18n.t('users.user')}</YTable.HeaderCell>
                                        <YTable.HeaderCell>{I18n.t('users.account')}</YTable.HeaderCell>
                                        <YTable.HeaderCell>{I18n.t('users.country')}</YTable.HeaderCell>
                                        <YTable.HeaderCell>{I18n.t('users.queryStatus')}</YTable.HeaderCell>
                                    </YRow>
                                </YTable.Header>
                                <YTable.Body>
                                    { _.map(list, item => (
                                        <YRow onClick={ () => history.push(`/user/${ item._id }/profile`) } key={ item._id }>
                                            <YTable.Cell>
                                                <PictureBlock>
                                                    <Picture>
                                                        <img src={ item.pictureUrl || placeHolderImage } onError={ e => e.target.src = placeHolderImage } alt="" />
                                                    </Picture>
                                                    <span>{ item.name }</span>
                                                </PictureBlock>
                                            </YTable.Cell>
                                            <YTable.Cell>
                                                <span>{ item.role.indexOf('premium') > -1 ? I18n.t('users.premium') : I18n.t('users.lite') }</span>
                                            </YTable.Cell>
                                            <YTable.Cell>
                                                { indexedCountries[item.country] || I18n.t('users.notCountry') }
                                            </YTable.Cell>
                                            <YTable.Cell >
                                                { item.lastQueryStatus && <StatusItem status={ item.lastQueryStatus }>
                                                    <span className="upper">{ I18n.t(`status.btn.${ item.lastQueryStatus }`) }</span>
                                                </StatusItem> }
                                            </YTable.Cell>
                                        </YRow>
                                    )) }
                                </YTable.Body>
                            </YTable> : <p className="caption regular">{I18n.t('messages.emptyData')}</p> }
                        </InnerContent>
                    </Content>
                </Inner>
            </Container>
        );

    }

}

const mapStateToProps = state => {

    return {
        userSession: _.cloneDeep(_.get(state, 'auth.userSession', {}))
    };

}

export default connect(mapStateToProps, { openModal, closeModal, openLoader, closeLoader })(MyUsers);