import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import loader from './loader';
import registerEmail from './registerEmail';
import auth from './auth';
import ymodal from './ymodal';
import role from './role';
import modals from './modals';
import alternativePractices from './practices';
import programs from './programs';
import asanas from './asanas';
import studios from './studios';

export default combineReducers({
    form,
    auth,
    loader,
    role,
    registerEmail,
    ymodal,
    modals,
    alternativePractices,
    programs,
    asanas,
    studios
});