import React, { Component } from 'react'
import _ from 'lodash'
import Indicators from './components/indicators'
import Matrix from './components/matrixBodies';
import { FrameContainer, FrameIndicator, MatrixBodies } from './styles';

class BiometricMatrix extends Component {

    render() {

        const { matrix, gender } = this.props;

        return (
            <FrameContainer>
                <FrameIndicator>
                    {!_.isEmpty(matrix) && <Indicators {...this.props} />}
                </FrameIndicator>
                <MatrixBodies>
                    <Matrix gender={gender} matrix={matrix} />
                </MatrixBodies>
            </FrameContainer>
        )
    }
}

export default BiometricMatrix;

