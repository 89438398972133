//libraries and component libraries
import _ from 'lodash';
import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

//config
import { GRAPH_COLORS } from '../../../../../../config/constants/graphColors';

am4core.useTheme(am4themes_kelly);
am4core.useTheme(am4themes_animated);

class RtdGraphView extends Component {

    constructor(props) {

        super(props);

        this.state = {
            id: `rtdchart#${Math.floor(Math.random() * 100)}`
        };

    }

    componentWillUnmount() {

        if (this.chart) {

            this.chart.dispose();

        }

    }

    componentDidUpdate(oldProps) {

        if (!_.isEqual(oldProps.data,this.props.data)) {

            this.chart.data = this.props.data;

        }

    }

    componentDidMount() {

        const { id } = this.state;
        const { data = [] } = this.props;

        let chart = am4core.create(id , am4charts.XYChart);
        chart.responsive.enabled = true;
        chart.responsive.rules.push({
            relevant: target => {

                if (target.pixelWidth <= 420) {

                    return true;

                }

                return false;

            },
            state: (target, stateId) => {

                if (target instanceof am4charts.AxisRendererX) {

                    let state = target.labels.template.states.create(stateId);
                    state.properties.maxWidth = 50;
                    state.properties.wrap = true;
                    state.properties.fontSize = 11;

                    return state;

                }

                if (target instanceof am4charts.AxisRendererY) {

                    let state = target.states.create(stateId);
                    state.properties.paddingTop = 3;
                    state.properties.paddingRight = 0;
                    state.properties.paddingBottom = 3;
                    state.properties.paddingLeft = 0;
                    state.properties.dx = 0;

                    return state;

                }

                if (target instanceof am4charts.LabelBullet) {

                    let state = target.label.states.create(stateId);
                    state.properties.fontSize = 11;

                    return state;

                }

                if (target instanceof am4charts.ValueAxis) {

                    let state = target.states.create(stateId);
                    state.properties.fontSize = 11;

                    return state;

                }

                return null;

            }
        });

        chart.data = data;

        chart.margin(0, 0, 0, 0);
        chart.padding(40,0,40,0);

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;

        let label = categoryAxis.renderer.labels.template;
        label.maxWidth = 130;
        label.wrap = true;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.extraMax = 0.1;

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "category";
        series.dataFields.valueY = "value";
        series.tooltipText = "{valueY.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusTopRight = 10;
        series.columns.template.column.cornerRadiusTopLeft = 10;

        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.verticalCenter = "bottom";
        labelBullet.label.dy = -10;
        labelBullet.label.text = "{valueY.formatNumber('#.00')}";
        chart.zoomOutButton.disabled = true;

        series.columns.template.adapter.add("fill", function (fill, target) {

            return GRAPH_COLORS[target.dataItem.index]

        });

        categoryAxis.sortBySeries = series;

        this.chart = chart;

    }

    render() {

        const { id } = this.state;

        return <div id={ id } style={{ width:'100%', height: '500px' }} />;

    }

}

export default RtdGraphView;