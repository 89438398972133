import styled from 'styled-components/macro';

const InvitationBlock = styled.div`

    display:flex;
    justify-content:flex-end;
    padding:20px;
`;

const InfoContent = styled.div`
    display:flex;
    flex-wrap:wrap;
`;

const InfoCol = styled.div`

    flex-grow:1;
    flex-shrink:1;
    flex-basis:auto;
    min-width:0;
    .specific {
        margin-bottom: 100px;
        display: inline-block;
    }

`;
export {
    InvitationBlock,
    InfoContent,
    InfoCol
}