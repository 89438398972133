import _ from 'lodash';
import Moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import { InfoBlocks, YTooltip } from '../../../../../../styled/components/commons';
import { Picture, PictureBlock, StatusItem, YTable, YRow } from '../../../../../../styled/components/tables';
import { InnerContent } from '../../../../../../styled/components/wrappers';

import InnerNavigation from '../../../navigation';

import { openLoader, closeLoader } from '../../../../../../../redux-store/loader';

import QuestionModel from '../../../../../../../data/models/questions/questions';

import I18n from '../../../../../../../i18n';

import placeHolderImage from '../../../../../../assets/img/yogabot_icon.svg';
import starNexoImage from '../../../../../../assets/img/star_nexo.png';
import { toHHMMSS } from '../../../../../../../utils/formatTime';

class UserQueries extends Component {

    constructor(props) {

        super(props);

        this.interval = null;
        this.state = {
            list: [],
            elapsedTime: 0,
            indexCountDownTooltip:-1
        };

    }

    componentDidMount() {

        this.getUserInfos();

    }

    componentWillUnmount() {

        clearInterval(this.interval);

    }

    getUserInfos = async () => {

        const { match: { params: { userId }}} = this.props;

        try {

            this.props.openLoader();
            const userQueriesInfo = await QuestionModel.getQueriesAssignedAdvisorUser(userId).catch(err => console.error(err));

            const dataDownloadedTime = Moment();
            this.interval = !this.interval && setInterval(()=>{

                this.setState({elapsedTime: Moment().diff(Moment(dataDownloadedTime), 'seconds') })

            },500);
            
            this.setState({ list: userQueriesInfo.data });

        } finally {

            this.props.closeLoader();

        }

    }

    getStatusColour = query => {

        const { _id } = this.props.user;
        const role = this.props.role;
        const { status, provisionalAdvisorUserId, advisorUserId, tutorUserId, checkerUserId } = query;

        if (role.indexOf('pretor') > -1) {

            return status;

        }

        switch (status) {

            case 'preassigned':

                return _id === provisionalAdvisorUserId ? status : 'ended';

            case 'assigned':

                return _id === advisorUserId ? status : 'ended';

            case 'answered':

                return _id === tutorUserId ? status : 'ended';

            case 'correct-reviewed':
            case 'incorrect-reviewed':

                return _id === checkerUserId ? status : 'ended';
                
            case 'incorrect-checked':
            case 'correct-checked':
            case 'ended':
            default:

                return 'ended';

        }

    }

    renderAssignment = query => {

        const { _id, pictureUrl } = this.props.user;
        const { status, provisionalAdvisorUserId, advisorUserId, tutorUserId, checkerUserId } = query;

        if (status === 'incorrect-checked') {

            return (
                <PictureBlock>
                    <Picture initials square>
                        <img src={ starNexoImage } alt="" />
                    </Picture>
                </PictureBlock>
            )

        } else if (['correct-reviewed', 'incorrect-reviewed'].indexOf(status) > -1 && _id !== checkerUserId) {

            return (
                <PictureBlock>
                    <Picture initials square>
                        <span className="heading-2 white upper">S</span>
                    </Picture>
                </PictureBlock>
            )

        } else if (status === 'answered' && _id !== tutorUserId) {

            return (
                <PictureBlock>
                    <Picture initials square>
                        <span className="heading-2 white upper">T</span>
                    </Picture>
                </PictureBlock>
            )

        } else if ((status === 'preassigned' && _id !== provisionalAdvisorUserId) 
            || (status === 'assigned' && _id !== advisorUserId)
            || (['correct-checked', 'ended'].indexOf(status)) > -1) {

            return '';

        } else {

            return (
                <PictureBlock>
                    <Picture square>
                        <img src={ pictureUrl || placeHolderImage } onError={ e => e.target.src = placeHolderImage } alt="" />
                    </Picture>
                </PictureBlock>
            );

        }

    }

    onToggleCountDownTooltip = (index, event) => {

        event && event.stopPropagation();
        this.setState({indexCountDownTooltip: index});

    }

    render() {

        const { list, indexCountDownTooltip, elapsedTime } = this.state;
        const  { history } = this.props;

        return (
            <InnerContent>
                <InnerNavigation active="queries" { ...this.props }/>
                <div style={{ marginTop: '32px' }}>
                    <InfoBlocks>
                        <span className="labels">{ I18n.t('queries.queries') }</span>
                        { !_.isEmpty(list) ? <YTable stackable padded='very' selectable style={{ marginTop: '20px' }}>
                            <YTable.Header>
                                <YRow>
                                    <YTable.HeaderCell textAlign='center'>{ I18n.t('queries.advisor.assignment') }</YTable.HeaderCell>
                                    <YTable.HeaderCell>{ I18n.t('queries.date') }</YTable.HeaderCell>
                                    <YTable.HeaderCell>{ I18n.t('queries.queryType') }</YTable.HeaderCell>
                                    <YTable.HeaderCell>{ I18n.t('users.queryStatus') }</YTable.HeaderCell>
                                </YRow>
                            </YTable.Header>
                            <YTable.Body>
                                { _.map(list, (item, index) => (
                                    <YRow onClick={ ()=> history.push(`/queries/query/${item._id}`) } key={ item._id } toRed={ item.minutesToExpire < 60 } toYellow={ item.minutesToExpire >= 60 && item.minutesToExpire < 180 }>
                                        <YTable.Cell textAlign='center'>
                                            { this.renderAssignment(item) }
                                        </YTable.Cell>
                                        <YTable.Cell>
                                            <span>{ Moment(item.created).format('DD/MM/YYYY - HH:mm') }</span>
                                        </YTable.Cell>
                                        <YTable.Cell>{ item.price ? I18n.t('queries.notFree') : I18n.t('queries.free') }</YTable.Cell>
                                        <YTable.Cell>
                                            <StatusItem status={ this.getStatusColour(item) }>
                                                <span className="upper">{ I18n.t(`status.btn.${item.status}`) }</span>
                                            </StatusItem>
                                            {(item.minutesToExpire < 180) && 
                                                <YTooltip
                                                    bgColor="#e20d0d"
                                                    open={indexCountDownTooltip === index}
                                                    content={<span className="caption regular white">{ toHHMMSS(Math.max(item.minutesToExpire * 60 - elapsedTime, 0)) }</span>}
                                                    trigger={
                                                    <Icon 
                                                        onClick={(e) => this.onToggleCountDownTooltip(index, e)}
                                                        onMouseEnter={() => this.onToggleCountDownTooltip(index)}
                                                        onMouseLeave={() =>  this.onToggleCountDownTooltip(-1)}
                                                        style={{marginLeft: '10px'}} 
                                                        name="exclamation triangle" 
                                                        className="brand-error c-pointer" 
                                                        size="large" 
                                                    />}

                                                />
                                            }
                                        </YTable.Cell>
                                    </YRow>
                                )) }
                            </YTable.Body>
                        </YTable> : <p className="caption regular">{ I18n.t('messages.emptyData') }</p> }
                    </InfoBlocks>
                </div>
            </InnerContent>
        );

    }

}

const mapStateToProps = state => {

    return {
        user: _.cloneDeep(_.get(state, 'auth.userSession', {})),
        role: _.cloneDeep(_.get(state, 'role.role', []))
    };

};

export default connect(mapStateToProps, { openLoader, closeLoader })(UserQueries);