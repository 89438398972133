import _ from 'lodash';
import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { GRAPH_COLORS } from '../../../../../../config/constants/graphColors';

am4core.useTheme(am4themes_animated);


class DonutChartView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: `donutchart#${Math.floor(Math.random() * 100)}`
        }

    }

    componentWillUnmount() {

        if (this.chart) {
            
            this.chart.dispose();

        }

    }

    componentDidUpdate(oldProps) {

        if (!_.isEqual(oldProps.data,this.props.data)) {

            if(this.chart._super) {

                this.chart.dispose();

            }
            
            this.initChart();

        }

    }

    componentDidMount() {

        this.initChart();

    }

    initChart = () => {

        const { id } = this.state;

        const { data, chartText } = this.props;

        let chart = am4core.create(id, am4charts.PieChart);
        chart.startAngle = 160;
        chart.endAngle = 380;
        chart.padding(0,0,40,0);
        // Let's cut a hole in our Pie chart the size of 40% the radius

        // Add data
        chart.data = data;

        // Add second series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.stroke = new am4core.InterfaceColorSet().getFor("background");
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0.05;
        pieSeries.slices.template.states.getKey("hover").properties.scale = 1;

        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
        pieSeries.radius = am4core.percent(100);
        pieSeries.innerRadius = am4core.percent(75);

        pieSeries.slices.template.adapter.add("fill", (fill, target) => GRAPH_COLORS[target.dataItem.index]);
        
        let label = chart.seriesContainer.createChild(am4core.Label);
        label.textAlign = "middle";
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.adapter.add("text", (text, target) => `[font-size:14px]${chartText}[/]:\n[bold font-size:25px]` + pieSeries.dataItem.values.value.sum + "[/]");
        

        chart.responsive.enabled = true;
        chart.responsive.rules.push({
            relevant: function(target) {

              if (target.pixelWidth <= 420) {
                  
                    return true;
              }
              
              return false;
            },
            state: function(target, stateId) {
                
              if (target instanceof am4charts.Chart) {

                let state = target.states.create(stateId);
                state.properties.paddingBottom = 0;
                state.properties.marginBottom = 0;
                state.properties.marginTop = 0;
                state.properties.marginLeft = 0;
                return state;
              }

              if (target instanceof am4core.Label) {

                let state = target.states.create(stateId);
                state.properties.scale = 0.76;
                return state;
              }

              return null;

            }
          });

        this.chart = chart;

    }

    render() {

        const { id } = this.state;

        return (
            <div style={{width: '100%', paddingTop: '70%', overflow: 'hidden', position: 'relative'}}>
                <div style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0'}}>
                    <div id={id} style={{width:'100%', height: '100%'}}></div>
                </div>
            </div>
            
        );

    }
}

export default DonutChartView;