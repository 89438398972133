import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import AsideMenu from '../../components/asideMenu';
import OButton from '../../styled/components/button';
import TopNav from '../../components/topNav';

import { Container, Content, Inner, InnerContent } from '../../styled/components/wrappers';

import { closeSession } from '../../../redux-store/auth';

import FormProfile from './form';

import I18n from '../../../i18n';

class Profile extends Component {
    
    render() {

        const initialValues =  _.merge({ answerDurationDisabled: false, paypalAccount: '', offline: false, country: 'es', gender: 2, pictureUrl: '', nickname: '', timetable: [], ssmm: { instagram: '', facebook: '', web: '' }}, this.props.userSession);
        const { closeSession } = this.props;

        return (
            <Container>
                <TopNav { ...this.props }/>
                <Inner>
                    <AsideMenu { ...this.props } />
                    <Content>
                        <InnerContent>
                            <p className="heading-1 upper">{ I18n.t('profile.yourData') }</p>
                            <OButton type="button" secondary color="#fff" onClick={ closeSession } size="mini" style={{ marginBottom: '20px' }}>
                                <span>{ I18n.t('menu.closeSession') }</span>
                            </OButton>
                            <FormProfile initialValues={ initialValues } { ...this.props } />
                        </InnerContent>
                    </Content>
                </Inner>
            </Container>
        );

    }

}

const mapStateToProps = state => {

    return {
        userSession: _.cloneDeep(_.get(state, 'auth.userSession', {}))
    };

}

export default connect(mapStateToProps, { closeSession })(Profile);
