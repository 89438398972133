// Third party libraries
import _ from 'lodash';
import React from 'react';
import queryString from 'query-string';
import moment from 'moment';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Responsive } from 'semantic-ui-react';

// Components
import CustomInput from '../../../components/form/input';
import CustomCheckBox from '../../../components/form/checkbox';
import ErrorMessage from '../../../components/errorMessage';

// Styled components
import OButton from '../../../styled/components/button';

// Redux
import { loginUser, thirdPartyloginUser } from '../../../../redux-store/auth';
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { getUser } from '../../../../redux-store/role';
import { openModal, closeModal } from '../../../../redux-store/modals';

// Utils
import { askForNotificationsPermission } from '../../../../utils/firebase';

// Locales
import I18n from '../../../../i18n';

// Assets
import Logo from '../../../assets/img/YOGABOT-ADVISOR.png';

class Login extends React.Component {

    constructor(props) {

        super(props);



        this.state = {
            hasError: false,
            codeError: -1
        };

    }

    componentDidMount() {

        if (_.get(this.props,'location.search', false)) {

            const UrlQueryStrings = this.props.location.search;
            const queryValues = queryString.parse(UrlQueryStrings);
            const { openModal, closeModal } = this.props;

            if (queryValues.expired) {

                openModal({
                    type: 'simple',
                    title:{
                        text: I18n.t('messages.toast'),
                        classes: ['heading-2', 'upper']
                    },
                    description:{
                        text: I18n.t('messages.sessionExpired'),
                        classes: ['labels', 'regular']
                    },
                    buttons:[
                        {
                            text: I18n.t('actions.understood'),
                            callback: ()=> {

                                closeModal();

                            },
                            options: {
                                primary: true,
                                color: '#fff',
                                fluid: false,
                                upper: true

                            }
                        }
                    ]
                }, true, 'mini');

            }

        }

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps, this.props) && window.localStorage.getItem('jwt') && moment(JSON.parse(atob(window.localStorage.getItem('jwt').split('.')[1])).exp * 1000).diff(moment(), 'hour')) {

            this.onSuccessLogin();

        }

    }

    componentWillUnmount() {

        this.props.closeModal();

    }

    onForgotPassword = () => this.props.history.push(`/auth/recovery/step/1`);

    onRegister = () => this.props.history.push('/auth/register/step/1/fill');

    onLogin = async credentials => {

        try {

            this.props.openLoader();
            await this.props.loginUser({ email: _.trim(credentials.email), password: credentials.password, remember: window.cordova ? true : credentials.remember });
            this.onSuccessLogin();

        } catch (codeError) {

            this.setState({ hasError: true, codeError: 'error-login' });
            this.props.closeLoader();

        }

    };

    onSuccessLogin = async () => {

        const { advisorVerified, history : { push }, openLoader, closeLoader, getUser } = this.props;

        try {

            openLoader();
            await getUser();
            !advisorVerified ? push('/profile') : push('/queries');
            askForNotificationsPermission(_.get(this.props.userSession, 'advisorNotificationTokens', []));

        } catch (codeError) {

            this.setState({ hasError: true, codeError: 'error-login' });

        } finally {

            closeLoader();

        }

    };

    render() {

        const { codeError, hasError } = this.state;

        return (
            <div className="wrapper-login">
                <div className="box">
                    <div className="logo" style={{ paddingBottom: '40px' }}>
                        <img src={Logo} alt="logo" />
                    </div>
                    <div className="logo-mobile" style={{ paddingTop: 0 }}>
                        <img src={ Logo } alt="logo" />
                    </div>
                    <Form name="loginForm" onSubmit={this.props.handleSubmit(this.onLogin)} noValidate>
                        <ErrorMessage code={codeError} active={hasError} />
                        <div className="verticalPad">
                            <Field
                                component={ CustomInput }
                                placeholder={ "Dirección Email" }
                                name="email"
                                fieldClasses="y-input default"
                                label={ I18n.t('auth.email') }
                                restrictions={ [{ trim: true }] } />
                        </div>
                        <div className="topPad forgot-password">
                            <span className="forgot-password-text" onClick={ this.onForgotPassword }>{I18n.t('auth.changePassword')}</span>
                            <Field
                                component={ CustomInput }
                                placeholder={ "" }
                                name="password"
                                type="password"
                                fieldClasses="y-input default"
                                label={ I18n.t('auth.password') } />
                        </div>
                        <div className="descriptions first">
                            <Field
                                component={ CustomCheckBox }
                                name='remember'
                                fieldClasses="y-checkbox"
                                label={ I18n.t("auth.remember") } />
                        </div>
                        <div className="verticalPad descriptions second flex-center-column">
                            <Responsive minWidth={421}>
                                <OButton type="submit" primary upper size="huge"><span>{I18n.t('auth.login')}</span></OButton>
                            </Responsive>
                            <Responsive maxWidth={420}>
                                <OButton type="submit" primary upper size="medium"><span>{I18n.t('auth.login')}</span></OButton>
                            </Responsive>
                            <span onClick={ this.onRegister }><b>{I18n.t('auth.withoutAccount')}</b> {I18n.t('auth.suscribeNow')}</span>
                        </div>
                    </Form>
                </div>
            </div>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.password)) {

        errors.password = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');

    }

    return errors;

};

const mapStateToProps = state => {

    return {
        advisorVerified: _.get(state, 'role.advisorVerified', {}),
        role: _.get(state, 'role.role', {}),
        userSession: _.get(state, 'auth.userSession', {})
    };
};

export default reduxForm({
    form: 'loginForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        email: '',
        password: '',
        remember: false
    }
})(connect(mapStateToProps, { closeLoader, openModal, closeModal, getUser, loginUser, openLoader, thirdPartyloginUser })(Login));