import { Modal } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import React from 'react';

const ModalContent = styled.div`
    padding:0 20px 20px 20px;
`;
const FrameModal = styled.div``;
const FrameClose = styled.div`
    display:flex;
    justify-content:flex-end;
    padding: 10px 10px;
    position: relative;
    i {
        cursor:pointer;
        &:before{
            color:var(--brand-primary);
        }
    }
`;

const Actions = styled.div`
    padding: 20px 20px 20px 20px;
    display:flex;
    justify-content:center;
`;

const ModalHeader = styled((props) => <Modal.Header {...props}/>)`
    &&& {
        display:flex;
        justify-content:space-between;
        padding: 1.25rem 1.5rem;
        background-color:var(--brand-primary);
    }
    
`;

export {
    Actions,
    ModalContent,
    FrameClose,
    FrameModal,
    ModalHeader
}