import React, { Component } from 'react'
import { InfoBlocks } from '../../../../../../styled/components/commons'
import { Block } from '../../../../../../styled/components/wrappers'
import moment from 'moment'
import { indexedCountries } from '../../../../../../../config/constants/countries'
import _ from 'lodash'
import { Picture } from '../../../../../../styled/components/tables'
import { CardChild, ImcBlock, ImcItem, ImcLegend, InfoCard, InfoCardContent, LegendColor, LegendValue, PersonalDataBlock, PictureCard, TextBlock } from '../../styles'
import { Icon } from 'semantic-ui-react'
import ImcProgress from '../../../../../../components/ImcProgress'
import placeHolderImage from '../../../../../../assets/img/yogabot_icon.svg';
import I18n from '../../../../../../../i18n'

class UserPersonalData extends Component {


    renderSpecifications = () => {

        const { resultStructure } = this.props;

        return (

            _.map(_.omit(resultStructure, ['level1']), (value, k) => _.isArray(value) ? (
                                    
                _.map(value, (item, index) => (
                    <React.Fragment key={index}>
                        {_.isEmpty(item.image) && <p className="labels regular" style={{marginLeft: '10px'}}>{item.value}</p>}
                        {!_.isEmpty(item.image) && 
                            <Block flex aicenter style={{marginBottom: '20px', marginLeft: '10px'}}>
                                <Picture square>
                                    <img src={item.image} alt="" />
                                </Picture>
                                <p className="labels regular" >{item.value}</p>
                            </Block>
                        }
                        { !_.isEmpty(item.text) && <p className="labels regular" style={{marginLeft: '10px'}}>{item.text}</p>}
                    </React.Fragment>
                ))

            ) : (
                    <p className="labels" key={k}>{value}</p>

            ))

        )

    }

    render() {

        const { query : { observations, pictureUrl, name, country, birthdate, height, weight, weightType, imc }, resultStructure } = this.props;
        
        return (
            <>
                <TextBlock>
                    <span className="heading-3">{I18n.t('queries.queryType')}: </span>
                    <span className="labels regular">{_.get(resultStructure,'level1', '')}</span>
                </TextBlock>
                <TextBlock>
                    <span className="heading-3">{I18n.t('users.specification')}: </span>
                </TextBlock>
                <InfoBlocks>
                {
                    this.renderSpecifications()
                }
                </InfoBlocks>
                <TextBlock>
                    <span className="heading-3">{I18n.t('users.comments')}: </span>
                </TextBlock>
                <InfoBlocks>
                    <span className="caption regular">
                        {observations}
                    </span>
                </InfoBlocks>
                <TextBlock>
                    <span className="heading-3">{I18n.t('menu.personalData')}: </span>
                </TextBlock>

                <PersonalDataBlock>
                    <InfoCard grey>
                        <PictureCard>
                            <img src={pictureUrl || placeHolderImage} onError={(e)=> e.target.src = placeHolderImage } alt="" />
                        </PictureCard>
                        <InfoCardContent>
                            <div style={{marginBottom: '20px'}}>
                                <p className="heading-3 nomargin">{I18n.t('users.name')}</p>
                                <p className="heading-3 regular">{name}</p>
                            </div>
                            <div>
                                <p className="heading-3 nomargin">{I18n.t('users.country')}</p>
                                <p className="heading-3 regular">{indexedCountries[country]}</p>
                            </div>
                        </InfoCardContent>
                    </InfoCard>
                    <InfoCard noPadded flexWrapped>
                        <CardChild grey>
                            <div>
                                <p className="heading-3 nomargin">{I18n.t('users.birthdate')}</p>
                                <p className="heading-3 regular">{moment(birthdate).format('DD/MM/YY')}</p>
                            </div>
                            <span>
                                <Icon name="birthday" className="brand-secondary" size="large"/>
                            </span>
                        </CardChild>
                        <CardChild grey>
                            <div>
                                <p className="heading-3 nomargin">{I18n.t('users.height')}</p>
                                <p className="heading-3 regular">{height} cm</p>
                            </div>
                            <span>
                                <Icon name="male" className="brand-secondary" size="large"/>
                            </span>
                        </CardChild>
                        <CardChild grey>
                            <div>
                                <p className="heading-3 nomargin">{I18n.t('users.weight')}</p>
                                <p className="heading-3 regular">{`${weight} ${weightType}`}</p>
                            </div>
                            <span>
                                <Icon name="weight" className="brand-secondary" size="large"/>
                            </span>
                        </CardChild>
                    </InfoCard>
                    <InfoCard grey>
                        <CardChild noPadded>
                            <div>
                                <p className="heading-3 nomargin">IMC</p>
                            </div>
                            <span>
                                <Icon name="fire" className="brand-secondary" size="large"/>
                            </span>
                        </CardChild>
                        <ImcBlock>
                            <ImcProgress imc={imc} divider={50} />
                        </ImcBlock>
                        <ImcLegend>
                            <ImcItem>
                                <p className="caption nomargin regular">{I18n.t('users.lowWeight')}</p>
                                <LegendColor clow></LegendColor>
                                <LegendValue className="caption regular">16.0</LegendValue>
                            </ImcItem>
                            <ImcItem>
                                <p className="caption nomargin regular">{I18n.t('users.midWeight')}</p>
                                <LegendColor cmid></LegendColor>
                                <LegendValue className="caption regular">18.5</LegendValue>
                            </ImcItem>
                            <ImcItem>
                                <p className="caption nomargin regular">{I18n.t('users.highWeight')}</p>
                                <LegendColor chigh></LegendColor>
                                <LegendValue className="caption regular">25.0</LegendValue>
                            </ImcItem>
                            
                        </ImcLegend>
                    </InfoCard>
                </PersonalDataBlock>
            </>
        )
    }
}

export default UserPersonalData