import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Dropdown, Icon, Input } from 'semantic-ui-react';

import { InnerContent } from '../../../../styled/components/wrappers';

import { FilterBlock, FilterContent, SearchBlock } from '../../styles/styled';

export default ({ infoCallback = false, title = '', searchValue = '', onSearchValue = null, filter1Value = '', onFilter1Value = null, filter1Options = [], filter2Value = '', onFilter2Value = null, filter2Options = [] }) => {

    const [search, onSearch] = useState(searchValue);
    const [filter1, onFilter1] = useState(filter1Value);
    const [filter2, onFilter2] = useState(filter2Value);

    useEffect(() => {

        if (typeof onSearchValue === 'function') {

            onSearchValue(search);

        }

    }, [search, onSearchValue]);

    useEffect(() => {

        if (typeof onFilter1Value === 'function') {

            onFilter1Value(filter1);

        }

    }, [filter1, onFilter1Value]);

    useEffect(() => {

        if (typeof onFilter2Value === 'function') {

            onFilter2Value(filter2);

        }

    }, [filter2, onFilter2Value]);

    return (
        <FilterBlock>
            <InnerContent>
                <FilterContent>
                    <div>
                        <p className="caption regular" style={{ marginBottom: '0' }}>{ title }</p>
                        <p className="heading-1 upper">{ title }</p>
                    </div>
                    <SearchBlock>
                        { !_.isEmpty(filter1Options) && <Dropdown style={{ marginBottom: '10px', marginTop: '10px', marginRight: '10px' }} placeholder='Filtrar' selection value={ filter1 } options={ filter1Options } onChange={ (e, { value }) => onFilter1(value) }/> }
                        { !_.isEmpty(filter2Options) && <Dropdown style={{ marginBottom: '10px', marginTop: '10px', marginRight: '10px' }} placeholder='Filtrar' selection value={ filter2 } options={ filter2Options } onChange={ (e, { value }) => onFilter2(value) }/> }
                        <Input style={{ marginBottom: '10px', marginTop: '10px', maxWidth: '157px', marginRight: '47px' }} value={ search } onChange={ e => onSearch(e.target.value) } action={{ icon: 'search', onClick: () => onSearch() }} placeholder='Buscar usuario' />
                        { typeof infoCallback === 'function' && <Icon onClick={infoCallback} className="dark-grey-2 c-pointer" style={{ marginBottom: '10px', marginTop: '10px', marginRight: '10px' }} name="question circle" size="large" /> }
                    </SearchBlock>
                </FilterContent>
            </InnerContent>
        </FilterBlock>
    );

}