import styled, { css } from 'styled-components/macro';

const NavigationContainer = styled.div`

    padding-top:10px;
    border-bottom:solid 1px var(--border-color);
    display:flex;
    justify-content:flex-start;
    
`;

const NavigationItem = styled.div`

    padding:5px 32px 10px 32px;
    position:relative;
    cursor:pointer;
    &:after{
        content:"";
        position:absolute;
        bottom:-1px;
        width:100%;
        left:0;
        height:4px;
        background-color:var(--brand-secondary);
        opacity:0;
        transition: opacity 1s;
    }

    ${({active}) => active &&
        css`
            &:after{
                opacity:1;
            }
        
        `}
    @media (max-width:420px) {
        padding:5px 15px 10px 15px;
    }  
`;

export {
    NavigationContainer,
    NavigationItem
}