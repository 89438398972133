import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { toHHMMSS } from '../../../utils/formatTime';

const TimerCount = forwardRef(({isActive = true}, ref) => {

    const [elapsedTime, setTimeElapsedTime] = useState(0);

    useEffect(() => {
        
        let interval = null;

        const startDate = Date.now();

        if (!isActive) return;

        interval = setInterval(()=> {

            setTimeElapsedTime(() => Math.round((Date.now() - startDate) / 1000));

        }, 500);

        return () => clearInterval(interval);

    }, [isActive])

    useImperativeHandle(ref, () => ({
  
      getTime() {

        return elapsedTime;

      }
  
    }));
  
    return <span className="heading-2">{toHHMMSS(elapsedTime)}</span>;

  });

export default TimerCount;