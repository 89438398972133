import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Icon } from 'semantic-ui-react'
import OButton from '../../../../styled/components/button'
import { Actions, FrameClose, FrameModal, ModalContent } from '../../sharedStyles'
import {isEmpty, map} from 'lodash';
import { Field, reduxForm } from 'redux-form'
import CustomTextArea from '../../../form/textarea'
import I18n from '../../../../../i18n'
import { maxLength, required } from '../../../../../config/validations'

const maxLength300 = maxLength(300);

class PretorianCheckerModal extends Component {
    
    onSave = data => {
        
        const { buttons } = this.props;
        buttons[0].callback(data);

    }

    render() {

        const { title = "", buttons = [], onClose, invalid } = this.props;

        return (
            <FrameModal>
                <FrameClose>
                    <Icon name="close" size="large" onClick={ onClose }/>
                </FrameClose>
                <Form name="pretorianCheckerModalForm" noValidate onSubmit={this.props.handleSubmit(this.onSave)}>
                    <ModalContent>
                        {!isEmpty(title) && <p className={title.classes.join(" ")} >{title.text}</p>}
                        <Field
                            component={ CustomTextArea }
                            name='checkerMessageToNexoyoga'
                            label={ '' }
                            placeholder={ I18n.t('actions.writeHere') }
                            validate={[required, maxLength300]}
                            />
                    </ModalContent>
                    <Actions>
                        {
                            map(buttons, ({text, options }, index) => (
                                <OButton key={index} disabled={invalid} type={options.type  || 'button'} {...options} ><span>{text}</span></OButton>
                            ))
                        }
                    </Actions>
                </Form>
        </FrameModal>
        )
    }
}

export default reduxForm({
    form: 'pretorianCheckerModalForm',
    touchOnBlur: true,
    touchOnChange: true,
    enableReinitialize:true,
    initialValues: {
        checkerMessageToNexoyoga: ''
    }
})(connect(null, {})(PretorianCheckerModal));
