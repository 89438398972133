
import React from 'react';
import { ModalContent, ModalHeader } from '../../sharedStyles';
import { Icon } from 'semantic-ui-react';
import { CounterItem, GridStatus, GridStatusItem, InfoContent, InfoItem, InfoStatusItems, InfoStatusText} from './styles';
import { Picture, StatusItem } from '../../../../styled/components/tables';
import starNexoImage from '../../../..//assets/img/star_nexo.png';
import I18n from '../../../../../i18n';

/***
 * * SHARED DATA ACCEPT MODAL
 * 
 * @param buttons Array Objects
 * @param onClose Function
 * @param title String
 * @param subtitle String
 * @param description String 
 * @params @buttons { text, callback, options = { any button props } }
 * @description Modal to ask to the user to accept the biometric matrix data required for the adivsor
 */
export default function LegendStatusModal({ headerTitle = "", onClose, ...restProps }) {

    return (
        <>
            <ModalHeader>
                <span className="heading-2 upper white">{headerTitle}</span>
                <Icon className="brand-white c-pointer" name="close"  onClick={ onClose }/>
            </ModalHeader>
            <ModalContent>
               <InfoContent>
                   <InfoItem style={{marginRight: '20px'}} wider>
                       <p className="labels upper">{I18n.t(`modals.legendStatusModal.text1`)}</p>
                       <p className="labels regular">{I18n.t(`modals.legendStatusModal.text2`)}</p>
                       <div style={{paddingTop: '20px'}}>
                            <InfoStatusItems>
                                <Picture square initials withIcon>
                                    <Icon style={{margin: '0'}} name="user" className="brand-white" size="large" />
                                </Picture>
                                <InfoStatusText>
                                    <p className="labels regular">{I18n.t(`modals.legendStatusModal.text3`)}</p>
                                </InfoStatusText>
                            </InfoStatusItems>
                            <InfoStatusItems>
                                <Picture square initials>
                                    <span className="heading-2 upper white">T</span>
                                </Picture>
                                <InfoStatusText>
                                    <p className="labels regular">{I18n.t(`modals.legendStatusModal.text4`)}</p>
                                </InfoStatusText>
                            </InfoStatusItems>
                            <InfoStatusItems>
                                <Picture square initials>
                                    <span className="heading-2 upper white">S</span>
                                </Picture>
                                <InfoStatusText>
                                    <p className="labels regular">{I18n.t(`modals.legendStatusModal.text5`)}</p>
                                </InfoStatusText>
                            </InfoStatusItems>
                            <InfoStatusItems>
                                <Picture square initials>
                                    <img src={ starNexoImage } alt="" />
                                </Picture>
                                <InfoStatusText>
                                    <p className="labels regular">{I18n.t(`modals.legendStatusModal.text6`)}</p>
                                </InfoStatusText>
                            </InfoStatusItems>
                        </div>
                   </InfoItem>
                   <InfoItem bordered padded>
                        <InfoStatusItems>
                            <span style={{marginRight: '10px'}}><Icon name="exclamation triangle" className="brand-error" size="large" /></span>
                            <p className="labels">{I18n.t(`modals.legendStatusModal.text7`)}</p>
                        </InfoStatusItems>
                        <p className="labels regular">{I18n.t(`modals.legendStatusModal.text8`)}</p>
                        <p className="labels regular" style={{marginBottom: '0'}}>{I18n.t(`modals.legendStatusModal.text9`)}</p>
                        <p className="labels regular" style={{marginBottom: '0'}}>{I18n.t(`modals.legendStatusModal.text10`)}</p>
                        <p className="labels regular" >{I18n.t(`modals.legendStatusModal.text11`)}</p>
                        <p className="labels regular">{I18n.t(`modals.legendStatusModal.text12`)}</p>
                        <InfoStatusItems>
                            <span style={{marginRight: '10px'}}><Icon name="exclamation triangle" className="brand-error" size="large" /></span>
                            <CounterItem><span className="regular labels white">00:30:23</span></CounterItem>
                        </InfoStatusItems>
                        <p className="labels regular">{I18n.t(`modals.legendStatusModal.text13`)}</p>
                   </InfoItem>
               </InfoContent>
                <p className="labels upper">{I18n.t(`modals.legendStatusModal.text14`)}</p>
                <InfoContent>
                    <InfoItem style={{marginRight: '20px'}}>
                        <p className="labels regular">{I18n.t(`modals.legendStatusModal.text15`)}</p>
                    </InfoItem>
                    <InfoItem>
                        <GridStatus>
                            <GridStatusItem>
                                <StatusItem status={'preassigned'}>
                                    <span className="upper">{ I18n.t(`status.btn.preassigned`) }</span>
                                </StatusItem>
                            </GridStatusItem>
                            <GridStatusItem>
                                <StatusItem status={'correct-reviewed'}>
                                    <span className="upper">{ I18n.t(`status.btn.correct-reviewed`) }</span>
                                </StatusItem>
                            </GridStatusItem>
                           
                            <GridStatusItem>
                                <StatusItem status={'assigned'}>
                                    <span className="upper">{ I18n.t(`status.btn.assigned`) }</span>
                                </StatusItem>
                            </GridStatusItem>
                            <GridStatusItem>
                                <StatusItem status={'incorrect-checked'}>
                                    <span className="upper">{ I18n.t(`status.btn.incorrect-checked`) }</span>
                                </StatusItem>
                            </GridStatusItem>
                            <GridStatusItem>
                                <StatusItem status={'answered'}>
                                    <span className="upper">{ I18n.t(`status.btn.answered`) }</span>
                                </StatusItem>
                            </GridStatusItem>
                            <GridStatusItem>
                                <StatusItem status={'ended'}>
                                    <span className="upper">{ I18n.t(`status.btn.ended`) }</span>
                                </StatusItem>
                            </GridStatusItem>
                        </GridStatus>
                    </InfoItem>
                </InfoContent>
            </ModalContent>
        </>

    )

}
