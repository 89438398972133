import styled, { css } from 'styled-components/macro';

const Aside = styled.aside`
    flex:0 0 226px;
    background:var(--brand-primary);
    padding-top:60px;
    @media (max-width:920px) {
        display:none;
    }
`;

const Text = styled.span`
    font-family: PoppinsRegular;
    font-size: 16px;
    color: rgba(var(--text-nav-color), 0.5);
`;

const AsideItem = styled.div`

    display:flex;
    justify-content:flex-start;
    align-items:center;
    padding:15px 10px 15px 30px;
    cursor:pointer;
    ${({active}) => active &&
        css`
            background:var(--brand-primary-dark);
            ${Text}  {
                color: var(--brand-white);
            }
    `};
    &:hover {
        background:var(--brand-primary-dark);
        ${Text}  {
             color: var(--brand-white);
          }
    }
`;

export {
    Aside,
    Text,
    AsideItem
}