// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from  'react-router-dom';
import { Field, reduxForm, formValueSelector, initialize } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { Trans } from 'react-i18next';

// Redux
import { registerUser, thirdPartyloginUser } from '../../../../../../redux-store/auth';
import { closeLoader, openLoader } from '../../../../../../redux-store/loader';
import { getUser } from '../../../../../../redux-store/role';

// Components
import CustomCheckBox from '../../../../../../ui/components/form/checkbox';
import ErrorMessage from '../../../../../components/errorMessage';
import CustomInput from '../../../../../../ui/components/form/input';

// Styled components
import OButton from '../../../../../styled/components/button';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import '../../register.scss';

// Assets
import yLogo1 from '../../../../../assets/img/YOGABOT-ADVISOR.png'
import yLogo from '../../../../../assets/img/yogabot_icon.svg'

class RegisterStep1Fill extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            hasError: false,
            codeError: -1,
            correct: false
        };

    }

    componentDidMount() {

        this.props.dispatch(initialize('registerFillForm', {
            email: this.props.registerEmail || '',
            name: '',
            surname: '',
            password: '',
            tos: false,
            comercialNotifications: false
        }));

    }

    gotoLogin = () => this.props.history.push(`/auth/login`);

    register = async credentials => {

        try {

            this.props.openLoader();
            await this.props.registerUser({ email: _.trim(credentials.email), password: credentials.password, name: credentials.name, surname: credentials.surname });
            this.onSuccessRegister();

        } catch (error) {

            this.setState({
                hasError: true,
                codeError: 'register-repeat-email'
            });

            this.props.closeLoader();

        }

    }

    onSuccessRegister = async () => {

        try {

            this.props.openLoader();
            await this.props.getUser();
            this.props.history.push('/profile');

        } catch (codeError) {

            this.setState({
                hasError: true,
                codeError: 'register-repeat-email'
            });

        } finally {

            this.props.closeLoader();

        }

    };

    render() {

        const { hasError, codeError } = this.state;

        return (
            <React.Fragment>
                <div className="r-wrapper">
                    <div className="r-header">
                        <img src={yLogo} alt="yogabot" />
                        <div className="r-links">
                            <span>{I18n.t('actions.hasAccount')}</span>
                            <span className="cbrand c-pointer" onClick={this.gotoLogin}>{I18n.t('actions.loginNow')}</span>
                        </div>
                    </div>
                    <div className="l-wrapper">
                        <div className="r-box">
                            <ErrorMessage active={hasError} code={codeError}/>
                            <Form name="registerFillForm" noValidate onSubmit={this.props.handleSubmit(this.register)}>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomInput }
                                        placeholder={ I18n.t('auth.email') }
                                        name="email"
                                        fieldClasses="y-input default small"
                                        label={ I18n.t('auth.email') }
                                        restrictions={ [{ trim: true }] } />
                                </div>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomInput }
                                        placeholder={ I18n.t('auth.name') }
                                        name="name"
                                        fieldClasses="y-input default small"
                                        label={ I18n.t('auth.name') }
                                        restrictions={ [{ trim: true }] } />
                                </div>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomInput }
                                        placeholder={ I18n.t('auth.surname') }
                                        name="surname"
                                        fieldClasses="y-input default small"
                                        label={ I18n.t('auth.surname') }
                                        restrictions={ [{ trim: true }] } />
                                </div>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomInput }
                                        placeholder={ "" }
                                        name="password"
                                        type="password"
                                        fieldClasses="y-input default small"
                                        label={ I18n.t("auth.password") } />
                                </div>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomCheckBox }
                                        name='tos'
                                        fieldClasses="y-checkbox small inline-block"
                                        label={ '' } />
                                        <label>Acepto los <Link to='/legal/tos' >{ I18n.t("auth.tos") }</Link></label>
                                    <Field
                                        component={ CustomCheckBox }
                                        name='comercialNotifications'
                                        fieldClasses="y-checkbox small"
                                        label={ I18n.t("auth.acceptNotifications") } />
                                </div>
                                <div className="verticalPad t-center">
                                    <OButton upper type="submit" disabled={!this.props.tos} fluid color="#FFF" primary>
                                        <span>{I18n.t('actions.createAccount')}</span>
                                    </OButton>
                                </div>
                                {this.props.tos &&
                                <p className="tos-message">
                                    <Trans i18nKey="messages.tos">
                                        <Link to='/legal/tos' ></Link>
                                    </Trans>
                                    </p>
                                }
                            </Form>
                        </div>
                    </div>
                    <div className="i-wrapper">
                        <div className="r-info">
                            <div className="r-logo">
                                <img src={yLogo1} alt="yogabot" />
                            </div>
                            <div className="r-title">
                                <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.title1') }}></p>
                            </div>
                            <div className="r-description">
                                <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.description1') }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.name)) {

        errors.name = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.surname)) {

        errors.surname = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.password)) {

        errors.password = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');

    }

    return errors;

};

const mapStateToProps = state => _.cloneDeep(state.registerEmail);

const selector = formValueSelector('registerFillForm');

RegisterStep1Fill = connect(state => {

    const tos = selector(state, 'tos');

    return {
        tos
    };

})(RegisterStep1Fill);

export default reduxForm({
    form: 'registerFillForm',
    touchOnBlur: true,
    touchOnChange: true,
    validate
})(connect(mapStateToProps, { registerUser, getUser, thirdPartyloginUser, openLoader, closeLoader })(RegisterStep1Fill));