import { Button } from 'semantic-ui-react';
import styled, { css } from 'styled-components/macro';
import React from 'react';

const OButton = styled(({color, primary, secondary, terciary, selected, upper, regular,  ...rest})=> <Button {...rest} />)`

    &&& {
        font-family: ${ ({regular}) => regular ? "PoppinsRegular" : "PoppinsBold"};
        color: ${({color}) => color || '#30425a'};
        position:relative;
        font-weight: ${ ({regular}) => regular ? "400" : "700"};
        ${({primary}) => primary &&
            css`
                background-color: #1fd5b9;
                &:hover {
                    background-color:#1bb89f;
                }
                &:active, &:focus {
                    color:#fff;
                    background-color:#1bb89f;
                    i {
                        &:before {
                            color:${({color}) => color || '#fff'};
                        }
                    }
                } 
               
        `};
        ${({secondary}) => secondary &&
            css`
                background-color: rgba(166, 166, 166, 0.4);
                &:hover {
                    background-color:#a6a6a6;
                }
                &:active, &:focus {
                    color:#fff;
                    background-color:#a6a6a6;
                    i {
                        &:before {
                            color:${({color}) => color || '#fff'};
                        }
                    }
                }
          `};
          ${({terciary}) => terciary && 
            css`
                background-color: #fff;
                &:hover {
                    background-color:#d8d8d8;
                }
                &:active, &:focus {
                    background-color:#d8d8d8;
                    i {
                        &:before {
                            color:${({color}) => color || '#fff'};
                        }
                    }
                    &:before {
                        content: "";
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: solid 3px ${({color}) => color || '#30425a'};
                        border-radius: inherit;
                        background: transparent;
                        pointer-events: none;
                        box-sizing: border-box;
                        position:absolute;
                    }
                }
                &:before {
                    content: "";
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: solid 2px ${({color}) => color || '#30425a'};
                    border-radius: inherit;
                    background: transparent;
                    pointer-events: none;
                    box-sizing: border-box;
                    position:absolute;
                }
          `};

          ${({selected}) => selected &&
            css`
                background-color: #fff;
                i {
                    background-color: transparent!important;
                }
                &:before {
                    content: "";
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: solid 2px ${({color}) => color || '#30425a'};
                    border-radius: inherit;
                    background: transparent;
                    pointer-events: none;
                    box-sizing: border-box;
                    position:absolute;
                }
          `};

          ${({upper}) => upper &&
            css`
                text-transform:upperCase;
          `};
    } 
    `;

export default OButton;