//libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { autofill, Field, reduxForm } from 'redux-form';
import I18n from '../../../../../../../../../i18n';

//styled components
import { FlexWrap, YTab } from '../../../../../../../../styled/components/commons';
import OButton from '../../../../../../../../styled/components/button';
import { Col, InnerContent, Row } from '../../../../../../../../styled/components/wrappers';
import { CheckboxBlock, Sections, SelectBlock, TextBlock, ScrollBlock, FlexibleBlock, StudioBlock, PracticeBlock, FlexibleRow, FieldAsInput, styledCheckboxList, Separator, SeparatorTextWrapper } from '../../../../styles';

//models
import QuestionModel from '../../../../../../../../../data/models/questions/questions';

//utils
import { maxLength } from '../../../../../../../../../config/validations';
import Utils from  '../../../../../../../../../utils';

//components libraries
import { Form, Message} from 'semantic-ui-react';

//components
import SequenceBuilder from '../sequenceBuilder';
import CustomTextArea from '../../../../../../../../components/form/textarea';
import TimerCount from '../../../../../../../../components/timerCount';

const maxLength300 = maxLength(300);

class AdvisorView extends Component {

    constructor(props) {

        super(props);

        this.state = {
            activePaneIndex: 0,
            routineKey: "advisorRoutine"
        }

        this.timerRef = React.createRef();

    }

    componentWillUnmount() {

        this.props.closeModal();
        
    }

    onSave = async dataForm => {

        const { openModal, closeModal, match: { params: { queryId } }, openLoader, closeLoader, history: { push }} = this.props;

        let hasError = false;

        if (!_.isEmpty(this.timerRef.current) && this.timerRef.current.getTime instanceof Function) {

            let time = this.timerRef.current.getTime();
            dataForm.answerDuration = time;

        }        

        try {

            openLoader();
            await QuestionModel.advisorAnswer(queryId, dataForm);

        } catch (error) {

            console.error(error);
            hasError = true;

        } finally {

            closeLoader();

            if (hasError) {

                openModal({
                    type: 'simple',
                    title:{
                        text: I18n.t('messages.alert'),
                        classes: ['heading-2', 'upper']
                    },
                    description:{
                        text: I18n.t('messages.commonError'),
                        classes: ['labels', 'regular']
                    },
                    buttons:[
                        {
                            text: I18n.t('actions.understood'),
                            callback: ()=> {

                                closeModal();

                            },
                            options: {
                                primary: true,
                                color: '#fff',
                                fluid: false,
                                upper: true
                                
                            }
                        }
                    ]
                }, true, 'mini');

            } else {

                push('/queries');

            }
            
        }

    }

    getStudios = target => {

        const { initialValues, mappedStudios } = this.props;

        let studios = [];

        if (initialValues[target]) {

            _.each(initialValues[target], studioId => {

                if (mappedStudios[studioId]) {

                    studios.push(mappedStudios[studioId]);

                }

            });

        }

        return studios.join(', ');

    }

    getSequences = target => {

        const { initialValues, mappedAlternativePractices } = this.props;

        let sequences = [];

        if (initialValues[target]) {

            _.each(initialValues[target], secId => {

                if (mappedAlternativePractices[secId]) {

                    sequences.push(mappedAlternativePractices[secId]);

                }

            });

        }

        return sequences.join(', ');

    }

    onAddAsana = () => {

        const { initialValues, reduxFormState } = this.props; 

        let tempRoutine = _.cloneDeep(reduxFormState.routine);

        let asanaToAdd = {
            duration: 30,
            originalDuration: 30,
            _id: initialValues.routine[0]._id,
            compatibleAsanas: [],
            transitionVideos: [],
            added: true,
            deleted: false,
            deconstructed: false,
            code: initialValues.routine[0].code
 
        }

        tempRoutine.push(asanaToAdd);

        this.props.dispatch(autofill('advisorForm', 'routine', tempRoutine));
        
        let that = this;
        setTimeout(() => {
            
            that.routineScrollContainer.current.scrollTop = that.routineScrollContainer.current.scrollHeight;

        }, 1000);
       
    }

    handleTabChange = (e, { activeIndex }) => {
        
        let routineKey = "advisorRoutine";

        if (activeIndex === 1 ) {

            routineKey = "routine";

        }
        
        this.setState({ activePaneIndex: activeIndex, routineKey });
    
    }

    render() {

        const { userSession, studios, alternativePractices, canEdit, initialValues, queryStatus, reduxFormState, asanas, advancedAsanasIds, invalid, openModal, closeModal } = this.props;

        const { activePaneIndex, routineKey } = this.state;

        const isCorrectCheckedOrEnded = (queryStatus === 'correct-checked' || queryStatus === 'ended');

        const panes = [
            {
              menuItem: I18n.t('queries.advisor.myRoutine')
            },
            {
              menuItem: I18n.t('queries.advisor.finalRoutine')
            }
          ]

        return (
            <InnerContent>
                <Form name="advisorForm" noValidate onSubmit={this.props.handleSubmit(this.onSave)}>
                    { (queryStatus === 'assigned' && !_.get(userSession,'answerDurationDisabled', false)) && 
                        <Row flexEnd>
                            <Col >
                                <TimerCount ref={this.timerRef} />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.summary')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorSummary'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                            />
                            { isCorrectCheckedOrEnded && !_.isEqual(initialValues.advisorSummary, initialValues.summary) && 
                                <Message icon='info' header={I18n.t('queries.advisor.summaryCorrected')} content={initialValues.summary} />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col two>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.restrictions')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorRestrictions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                            />
                            { isCorrectCheckedOrEnded && !_.isEqual(initialValues.advisorRestrictions, initialValues.restrictions) && 
                                <Message icon='info' header={I18n.t('queries.advisor.restrictionsCorrected')} content={initialValues.restrictions}/>
                            }
                        </Col>
                        <Col two>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.toPowered')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorToPowered'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                            />
                            { isCorrectCheckedOrEnded && !_.isEqual(initialValues.advisorToPowered, initialValues.toPowered) && 
                                <Message icon='info' header={I18n.t('queries.advisor.toPoweredCorrected')} content={initialValues.toPowered}/>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.suggestions')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorSuggestions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                            />
                            { isCorrectCheckedOrEnded && !_.isEqual(initialValues.advisorSuggestions, initialValues.suggestions) && 
                                <Message icon='info' header={I18n.t('queries.advisor.suggestionsCorrected')} content={initialValues.suggestions}/>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.finalRecomendations')} </span>
                            </TextBlock>
                        </Col>
                        { !canEdit && 
                        <Message>
                            <Message.Header>{I18n.t('queries.advisor.text6')}</Message.Header>
                            <p>
                                {this.getStudios('advisorSchoolSuggestions')}
                            </p>
                            <Message.Header>{I18n.t('queries.advisor.text7')}</Message.Header>
                            <p>
                                {this.getSequences('advisorSequenceSuggestions')}
                            </p>
                        </Message> }
                    </Row>
                    { isCorrectCheckedOrEnded && 
                        (!_.isEqual(initialValues.advisorSchoolSuggestions, initialValues.schoolSuggestions) || 
                        !_.isEqual(initialValues.advisorSequenceSuggestions, initialValues.sequenceSuggestions)) &&
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.finalRecomendationsCorrected')}</span>
                            </TextBlock>
                        </Col>
                        <Message>
                            {!_.isEqual(initialValues.advisorSchoolSuggestions, initialValues.schoolSuggestions) && 
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text6')}</Message.Header>
                                <p>
                                    {this.getStudios('schoolSuggestions')}
                                </p>
                            </React.Fragment>
                            }
                            {!_.isEqual(initialValues.advisorSequenceSuggestions, initialValues.sequenceSuggestions) && 
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text7')}</Message.Header>
                                <p>
                                    {this.getSequences('sequenceSuggestions')}
                                </p>
                            </React.Fragment>
                            }
                        </Message>
                    </Row>}
                    {canEdit && <ScrollBlock>
                        <FlexibleBlock>
                            <StudioBlock>
                                <Sections>
                                    <span className="label regular">{I18n.t('queries.advisor.text6')}</span>
                                </Sections>
                                { _.map(studios, studio => (
                                    <FlexibleRow key={studio.key}>
                                        <CheckboxBlock>
                                            <Field
                                                component={ styledCheckboxList }
                                                name='advisorSchoolSuggestions'
                                                id={studio.value}
                                                label={ '' }
                                                disabled={!canEdit}
                                            />
                                        </CheckboxBlock>
                                        <SelectBlock>
                                            <FieldAsInput>
                                                <span>{studio.text}</span>
                                            </FieldAsInput>
                                        </SelectBlock>
                                    </FlexibleRow>
                                )) }
                            </StudioBlock>
                            <PracticeBlock>
                                <Sections>
                                    <span className="label regular">{I18n.t('queries.advisor.text7')}</span>
                                </Sections>
                                { _.map(alternativePractices, practice => (
                                    <FlexibleRow key={practice._id}>
                                        <CheckboxBlock>
                                            <Field
                                                component={ styledCheckboxList }
                                                name='advisorSequenceSuggestions'
                                                id={practice._id}
                                                label={ '' }
                                                disabled={!canEdit}
                                                />
                                        </CheckboxBlock>
                                        <SelectBlock>
                                                <FieldAsInput>
                                                    <span>{practice.title['ES']}</span>
                                                </FieldAsInput>
                                        </SelectBlock>
                                    </FlexibleRow>
                                )) }
                            </PracticeBlock>
                        </FlexibleBlock>
                    </ScrollBlock>}
                    { isCorrectCheckedOrEnded && Utils.checkIfNotEqualAndNotEmpty(initialValues.advisorRoutine, initialValues.routine) && 
                        <YTab menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={activePaneIndex} onTabChange={this.handleTabChange} /> 
                    }
                    <SequenceBuilder
                            reduxFormState={reduxFormState}
                            initialValues={initialValues}
                            asanas={asanas}
                            advancedAsanasIds={advancedAsanasIds}
                            canEdit={canEdit}
                            dispatch={this.props.dispatch}
                            formName="advisorForm"
                            routineKey={routineKey}
                            openModal={openModal}
                            closeModal={closeModal}
                        />
                    {isCorrectCheckedOrEnded && 
                    ( !_.isEmpty(initialValues.messageToAdvisor) || !_.isEmpty(initialValues.messageToStudent)) && 
                        <Separator>
                            <SeparatorTextWrapper>
                                <span className="heading-2 brand-secondary">{I18n.t('queries.mySpaceComplementaryMessage')}</span>
                            </SeparatorTextWrapper>
                        </Separator>
                    }
                    {isCorrectCheckedOrEnded && initialValues.messageToAdvisor && 
                        <Message icon='info' header={ I18n.t('queries.advisor.messageToAdvisor')} content={initialValues.messageToAdvisor}/>
                    }
                    {isCorrectCheckedOrEnded && initialValues.messageToStudent && 
                        <Message icon='info' header={I18n.t('queries.advisor.messageToAdvisor')} content={initialValues.messageToStudent}/>
                    }
                    {canEdit && <FlexWrap fend style={{marginTop: '50px', marginBottom: '50px'}}>
                        <OButton type="submit" primary color="#fff" upper size="big" disabled={invalid}>
                            <span>{I18n.t('actions.send')}</span>
                        </OButton>
                    </FlexWrap>}
                </Form>
            </InnerContent>
        );

    }

}

const mapStateToProps = state => {

    return {
        reduxFormState: _.cloneDeep(_.get(state, 'form.advisorForm.values', {})),
        userSession: _.cloneDeep(_.get(state, 'auth.userSession', {}))
    };

};

export default reduxForm({
    form: 'advisorForm',
    touchOnBlur: true,
    touchOnChange: true,
    enableReinitialize: true
})(connect(mapStateToProps, {  })(AdvisorView));
