import styled, { css } from 'styled-components/macro';
import Checkbox from '../../../../../components/form/checkbox';
import CheckboxList from '../../../../../components/form/checkBoxList';


const TextBlock = styled.div`

    margin-bottom:20px;

`;

const BlockAction = styled.div`

    &+& {
        margin-left:20px;
    }

`;

const Separator = styled.div`

    width:100%;
    position:relative;
    &:before {
        content:"";
        position:absolute;
        width:100%;
        height:1px;
        background-color:var(--brand-secondary);
        top:50%;
        transform:translateY(-50%);
        z-index:1;
    }

`;

const SeparatorTextWrapper = styled.span`

    padding:10px 20px 10px 0px;
    background:#fff;
    display:inline-block;
    z-index:2;
    position:relative;
`;

const Sections = styled.div`

    display:flex;
    justify-content:space-between;
    padding-bottom:10px;
    border-bottom:solid 1px var(--border-color);
    margin-bottom:30px;
`;

const CheckboxBlock = styled.div`

    flex-grow:0;
    flex-shrink:0;
    flex-basis: 50px;
    max-width: 50px;
    
`;

const SelectBlock = styled.div`

    flex-grow:1;
    flex-shrink:1;
    flex-basis:0;
    max-width: 310px;
    
`;

const StyledCheckbox = styled(Checkbox)`

    &&&&&&& {
        label {
            &:before {
                background: var(--brand-secondary);
                border:none;
            }
            &:after {
                color: #fff;
            }
        }
    }

`;

const styledCheckboxList = styled(CheckboxList)`

    &&&&&&& {
        label {
            &:before {
                background: var(--brand-secondary);
                border:none;
            }
            &:after {
                color: #fff;
            }
        }
    }`;

const PictureCell = styled.div`

    flex-grow:0;
    flex-shrink:0;
    flex-basis: 70px;
    max-width: 70px;
`;

const PictureBlock = styled.div`

    height:45px;
    width:45px;
    border-radius:10px;
    overflow:hidden;
    background:lightgrey;
    img {
        width:100%;
        height:100%;
        object-fit:cover;
    }

`;

const IconBlock = styled.div`

    margin-left:auto;
`;

const ScrollBlock = styled.div`
    max-height:600px;
    overflow:auto;
    padding-right:10px;
    margin-bottom:30px;
`;

const NormalBlock = styled.div`
    padding-right:10px;
    margin-bottom:30px;
`;

const StudioBlock = styled.div`

    width:50%;
    min-width:0;
    display:flex;
    flex-direction:column;
    @media (max-width:800px) {
        width:100%;
    }
`;

const PracticeBlock = styled.div`

    width:50%;
    min-width:0;
    display:flex;
    flex-direction:column;
    @media (max-width:800px) {
        width:100%;
    }

`;

const FlexibleBlock = styled.div`

    display:flex;
    @media (max-width:800px) {
        flex-direction:column;
    }
`;

const FlexibleRow = styled.div`
    display:flex;
    margin-bottom:30px;
    flex-wrap: wrap;
    align-items: center;
`;

const FieldAsInput = styled.div`

    min-hight:38px;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    padding: .78571429em 2.1em .78571429em 1em;
    color: rgba(0,0,0,.87);
`;

const InfoCard = styled.div`

    width:350px;
    padding:10px 20px;
    background-color:#fff;
    border-radius:10px;
    margin-bottom:20px;
    position:relative;
    ${({grey}) => grey &&
    css`
        background-color:rgba(var(--info-block), 0.1)
    `};
    ${({noPadded}) => noPadded &&
    css`
        padding:0;
    `};
    ${({flexWrapped}) => flexWrapped &&
    css`
        display: flex;
        flex-wrap: wrap;
    `};

    @media (max-width: 420px) {
        width:100%;
    }

`;

const PictureCard = styled.div`

    position:absolute;
    width:150px;
    height:150px;
    border-radius:10px;
    top:-50px;
    left:-15px;
    overflow:hidden;
    background-color:#fff;
    img {
        width:100%;
        height:100%;
        object-fit:cover;
    }
`;

const InfoCardContent = styled.div`

    margin-top:110px;
`;

const CardChild = styled.div`

    background-color:transparent;
    margin-bottom:10px;
    border-radius:10px;
    padding:10px 20px;
    position:relative;
    width: 100%;
    &:last-child {
        margin-bottom:0;
    }
    div {
        padding-right:50px;
    }

    span {
        position:absolute;
        top:0;
        right:0;
        padding:inherit;
    }
    &&&{
        i {
            line-height: 1.4285em;
        }
    }
    
    ${({noPadded}) => noPadded &&
    css`
        padding:0;
    `};

    ${({grey}) => grey &&
    css`
        background-color:rgba(var(--info-block), 0.1)
    `};
`;

const ImcBlock = styled.div`

    margin: 32px 0;

`;

const ImcLegend = styled.div`

    display:flex;
    margin-bottom:20px;
`;

const ImcItem = styled.div`
    flex-grow:0;
    flex-shrink:1;
    flex-basis:100%;
    min-width:0;
`;

const LegendColor = styled.div`

    height:10px;

    ${({clow}) => clow &&
    css`
        background-color:var(--brand-alarm)
    `};
    ${({cmid}) => cmid &&
        css`
            background-color:var(--brand-positive);
        `};
    ${({chigh}) => chigh &&
    css`
        background-color:var(--brand-error);
    `};
`;

const PersonalDataBlock = styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    padding-top:50px;
    padding-left:15px;
`;

const LegendValue = styled.div``;

export {
    TextBlock,
    BlockAction,
    Separator,
    Sections,
    CheckboxBlock,
    styledCheckboxList,
    SelectBlock,
    StyledCheckbox,
    PictureBlock,
    PictureCell,
    IconBlock,
    ScrollBlock,
    StudioBlock,
    PracticeBlock,
    FlexibleBlock,
    FlexibleRow,
    FieldAsInput,
    InfoCard,
    CardChild,
    ImcBlock,
    ImcLegend,
    ImcItem,
    LegendColor,
    LegendValue,
    PictureCard,
    PersonalDataBlock,
    InfoCardContent,
    SeparatorTextWrapper,
    NormalBlock
}