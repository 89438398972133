import React, { Component } from 'react'
import { InfoBlocks } from '../../../../../../styled/components/commons'
import { InnerContent } from '../../../../../../styled/components/wrappers'
import { InfoCol, InfoContent } from '../../../../styles/styled'
import InnerNavigation from '../../../navigation'
import { connect } from 'react-redux'
import { openLoader, closeLoader } from '../../../../../../../redux-store/loader'
import { indexedCountries } from '../../../../../../../config/constants/countries'
import I18n from '../../../../../../../i18n'
import BiometricMatrix from '../../../../../../components/biometricMatrix'
import moment from 'moment';
import { MatrixContainer } from '../../../../../../components/biometricMatrix/styles'
import { InfoCard, InfoCardContent, PictureCard } from '../../../../../queries/components/query/styles'
import placeHolderImage from '../../../../../../assets/img/yogabot_icon.svg';

class UserProfile extends Component {

    render() {

        const { userInfo: { pictureUrl, name, country, gender }, biometricData } = this.props;

        return (
            <InnerContent>
                <InnerNavigation active="profile" { ...this.props }/>
                <div style={{marginTop: '32px'}}>
                    <InfoBlocks transparent>
                        <InfoContent>
                            <InfoCol>
                                <span className="labels specific">{I18n.t('menu.personalData')}</span>
                                <InfoCard grey>
                                    <PictureCard>
                                        <img src={pictureUrl || placeHolderImage} onError={(e)=> e.target.src = placeHolderImage } alt="" />
                                    </PictureCard>
                                    <InfoCardContent>
                                        <div style={{marginBottom: '20px'}}>
                                            <p className="heading-3 nomargin">{I18n.t('users.name')}</p>
                                            <p className="heading-3 regular">{name}</p>
                                        </div>
                                        <div>
                                            <p className="heading-3 nomargin">{I18n.t('users.country')}</p>
                                            <p className="heading-3 regular">{indexedCountries[country] || I18n.t('user.notCountry')}</p>
                                        </div>
                                    </InfoCardContent>
                                </InfoCard>
                            </InfoCol>
                            <InfoCol>
                                <span className="labels">{I18n.t('users.profitabilityUser')}</span>
                            </InfoCol>
                        </InfoContent>
                    </InfoBlocks>
                    <InfoBlocks transparent>
                        <span className="labels">{I18n.t('users.biometricMatrix')}</span>
                        <p className="labels regular">{I18n.t('users.lastUpdated')}: {moment(biometricData.updated).format('DD/MM/YYYY')}</p>
                        <MatrixContainer>
                            <BiometricMatrix matrix={biometricData} gender={gender || 2} />
                        </MatrixContainer>
                    </InfoBlocks>
                   
                </div>
            </InnerContent>
        )
    }
}

export default connect(null , { openLoader, closeLoader })(UserProfile)