import React from 'react';
import { Form, Responsive } from 'semantic-ui-react';

import LogoDesktop from '../../../../assets/img/logo_mobile.svg';
import LogoMobile from '../../../../assets/img/logo_secondary.svg';

import OButton from '../../../../styled/components/button';

import I18n from '../../../../../i18n';

class RecoveryStep3 extends React.Component {

    onAccept = () => { 

        this.props.history.push(`/auth/login`);

    };

    render() {

        return (

            <div className="full-wrapper">
                <div className="floating"><OButton type="button" primary upper onClick={() => this.props.history.push('/auth/login')}><p>{I18n.t('auth.login')}</p></OButton></div>
                <div className="wrapper-login">
                    <div className="box">
                    <div className="correct-logo">
                        <img src={LogoDesktop} alt="" />
                    </div>
                    <div className="correct-logo-mobile" style={{ paddingTop: '40px' }}>
                        <img src={LogoMobile} alt="" />
                    </div>
                    <div className="correct-message-title">
                        <p>{ I18n.t('auth.restoreCorrectMessageFirst')}</p>
                    </div>
                    <div className="correct-message-subtitle">
                        <p>{ I18n.t('auth.restoreCorrectMessageSecond')}</p>
                    </div>
                    <Form name="recoveryStep3Form">
                        <div className="verticalPad-long t-center">
                                <Responsive minWidth={421}>
                                    <OButton type="button" primary upper size="huge" onClick={ this.onAccept }><p>{I18n.t('auth.finalize')}</p></OButton>
                                </Responsive>
                                <Responsive maxWidth={420}>
                                    <OButton type="button" primary upper onClick={ this.onAccept }><p>{I18n.t('auth.finalize')}</p></OButton>
                                </Responsive>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

        );

    }

}

export default RecoveryStep3;