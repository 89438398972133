//libraries
import _ from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import I18n from '../../../../../../../../../i18n';
import { Field, reduxForm } from 'redux-form'

//libraries components
import { Form, Message } from 'semantic-ui-react';

//components
import CustomTextArea from '../../../../../../../../components/form/textarea';
import SequenceBuilder from '../sequenceBuilder';

//styled components
import { Col, InnerContent, Row } from '../../../../../../../../styled/components/wrappers';
import { TextBlock} from '../../../../styles';
import { FlexWrap, YTab } from '../../../../../../../../styled/components/commons';
import OButton from '../../../../../../../../styled/components/button';

//redux actions
import { openModal, closeModal } from '../../../../../../../../../redux-store/modals';

//models
import QuestionModel from '../../../../../../../../../data/models/questions/questions';

//utils
import Utils from  '../../../../../../../../../utils';


class PretorianCheckerView extends Component {


    constructor(props) {

        super(props);

        this.state = {
            activePaneIndex: 0,
            routineKey: "advisorRoutine"
        }

    }

    componentWillUnmount() {

        this.props.closeModal();
        
    }

    onSaveIncorrectFeedback = async data => {

        const { openModal, closeModal, openLoader, closeLoader, history: { push }, match: { params: { queryId } } } = this.props;

        let hasError = false;

        try {
            
            await QuestionModel.pretorianCheckerActions(queryId, _.assign(data, { correct: false }))
            openLoader()

        } catch (error) {

            console.error('error', error);
            hasError = true;

        } finally {

            closeLoader();

            if (hasError) {

                openModal({
                    type: 'simple',
                    title:{
                        text: I18n.t('messages.alert'),
                        classes: ['heading-2', 'upper']
                    },
                    description:{
                        text: I18n.t('messages.commonError'),
                        classes: ['labels', 'regular']
                    },
                    buttons:[
                        {
                            text: I18n.t('actions.understood'),
                            callback: ()=> {

                                closeModal();

                            },
                            options: {
                                primary: true,
                                color: '#fff',
                                fluid: false,
                                upper: true
                                
                            }
                        }
                    ]
                }, true, 'mini');

            } else {

                push('/queries');

            }

        }

    }

    onIncorrectFeedback = () => {

        const { openModal, closeModal } = this.props;

        openModal({
            type: 'pretorianChecker',
            title:{
                text: I18n.t('modals.pretorianCheckerModal.text1'),
                classes: ['heading-3', 'upper']
            },
            buttons:[
                {
                    text: I18n.t('actions.send'),
                    callback: response => {

                        this.onSaveIncorrectFeedback(response)
                        closeModal();

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: false,
                        upper: true,
                        regular: true,
                        size: 'small',
                        type: 'submit'
                        
                    }
                }
            ]
        }, true, 'small');

    }

    onSaveCorrectFeedback = async () => {

        const { openLoader, closeLoader, closeModal, openModal, history: { push }, match: { params: { queryId } } } = this.props;
        let hasError = false;

        try {
            
            await QuestionModel.pretorianCheckerActions(queryId, { correct: true } )
            openLoader()

        } catch (error) {

            hasError = true;
            console.error('error', error)

        } finally {

            closeLoader();  
            if (hasError) {

                openModal({
                    type: 'simple',
                    title:{
                        text: I18n.t('messages.alert'),
                        classes: ['heading-2', 'upper']
                    },
                    description:{
                        text: I18n.t('messages.commonError'),
                        classes: ['labels', 'regular']
                    },
                    buttons:[
                        {
                            text: I18n.t('actions.understood'),
                            callback: ()=> {

                                closeModal();

                            },
                            options: {
                                primary: true,
                                color: '#fff',
                                fluid: false,
                                upper: true
                                
                            }
                        }
                    ]
                }, true, 'mini');

            } else {

                push('/queries');

            }

        }

    }

    getStudios = target => {

        const { initialValues, mappedStudios } = this.props;

        let studios = [];

        if (initialValues[target]) {

            _.each(initialValues[target], studioId => {

                if (mappedStudios[studioId]) {

                    studios.push(mappedStudios[studioId])
                }

            })

        }

        return studios.join(', ');

    }


    getSequences = target => {

        const { initialValues, mappedAlternativePractices } = this.props;

        let sequences = [];

        if (initialValues[target]) {

            _.each(initialValues[target], secId => {

                if (mappedAlternativePractices[secId]) {

                    sequences.push(mappedAlternativePractices[secId])
                }

            })

        }

        return sequences.join(', ');

    }

    handleTabChange = (e, { activeIndex, panes }) => {
        
        let routineKey = "";

        switch(panes[activeIndex].menuItem.key) {

            case 'tutor':
                routineKey = "tutorRoutine";
                break;
            case 'nexoyoga':
                routineKey = "routine";
                break;
            default: 
                routineKey = "advisorRoutine";

        }
        
        this.setState({ activePaneIndex: activeIndex, routineKey });
    
    }

    buildPanes = () => {
        
        const { initialValues } = this.props;

        let panes = [{menuItem: { key: 'advisor', content: I18n.t('queries.advisor.routineAdvisor')} }];

        if (Utils.checkIfNotEqualAndNotEmpty(initialValues.advisorRoutine, initialValues.tutorRoutine)) {

            panes.push({menuItem: { key: 'tutor', content: I18n.t('queries.advisor.routineTutor')} });

        }

        if (Utils.checkIfNotEqualAndNotEmpty(initialValues.tutorRoutine, initialValues.routine)) {

            panes.push({menuItem: { key: 'nexoyoga', content: I18n.t('queries.advisor.routinePretor') } });
            
        }

        return panes;

    }

    render() {

        const { canEdit, queryStatus, initialValues, asanas, advancedAsanasIds } = this.props;

        const isCorrectCheckedOrEnded = (queryStatus === 'correct-checked' || queryStatus === 'ended');

        const isInCorrectChecked = queryStatus === 'incorrect-checked';

        const { activePaneIndex, routineKey } = this.state;


        return (
            <InnerContent>
                <Form name="pretorianCheckerForm" noValidate >

                {/*/*************************************************************SUMMARY ADVISOR **************************************************************/}

                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.summaryAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorSummary'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>

                {/*/*************************************************************SUMMARY TUTOR **************************************************************/}

                   { !_.isEqual(initialValues.tutorSummary, initialValues.advisorSummary) &&
                        <Row>
                            <Col equal>
                                <TextBlock>
                                    <span className="heading-3 asDisabled">{I18n.t('queries.advisor.summaryTutor')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='tutorSummary'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={true}
                                    />
                            </Col>
                        </Row>
                    }

                    {/*/*************************************************************SUMMARY PRETOR **************************************************************/}

                    {isCorrectCheckedOrEnded && !_.isEqual(initialValues.tutorSummary, initialValues.summary) &&
                        <Row>
                            <Col equal>
                                <TextBlock>
                                    <span className="heading-3 asDisabled">{I18n.t('queries.advisor.summaryPretor')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='summary'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={true}
                                    />
                            </Col>
                        </Row>
                    }

                    {/*/*************************************************************RESTRICCTIONS **************************************************************/}
                    {/*/*************************************************************RESTRICCTIONS ADVISOR**************************************************************/}

                    <Row>

                        <Col two>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.restrictionsAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorRestrictions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>

                        {/*/*************************************************************RESTRICTIONS TUTOR **************************************************************/}

                        {!_.isEqual(initialValues.tutorRestrictions, initialValues.advisorRestrictions) && 

                            <Col two>
                                <TextBlock>
                                    <span className="heading-3 asDisabled">{I18n.t('queries.advisor.restrictionsTutor')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='tutorRestrictions'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={true}
                                    />
                            </Col>
                        }

                        {/*/*************************************************************RESTRICTIONS PRETOR **************************************************************/}

                        {isCorrectCheckedOrEnded && !_.isEqual(initialValues.tutorRestrictions, initialValues.restrictions) &&

                            <Col two className={(!_.isEqual(initialValues.tutorRestrictions, initialValues.advisorRestrictions) ? 'mTop30' : '')}>
                                <TextBlock>
                                    <span className="heading-3 asDisabled">{I18n.t('queries.advisor.restrictionsPretor')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='restrictions'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={true}
                                    />
                            </Col>
                        }

                        </Row>

                    {/*/*************************************************************TO POWERED**************************************************************/}
                {/*/*************************************************************TO POWERED ADVISOR **************************************************************/}
                    <Row>
                        <Col two>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.toPoweredAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorToPowered'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>

                        {/*/*************************************************************TO POWERED TUTOR **************************************************************/}

                        {!_.isEqual(initialValues.tutorToPowered, initialValues.advisorToPowered) && 

                            <Col two>
                                <TextBlock>
                                    <span className="heading-3 asDisabled">{I18n.t('queries.advisor.toPoweredTutor')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='tutorToPowered'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={true}
                                    />
                            </Col>
                        }

                        {/*/*************************************************************TO POWERED PRETOR **************************************************************/}

                        { isCorrectCheckedOrEnded && !_.isEqual(initialValues.tutorToPowered, initialValues.toPowered) && 
                            <Col two className={(!_.isEqual(initialValues.tutorToPowered, initialValues.advisorToPowered) ? 'mTop30' : '')}>
                                <TextBlock>
                                    <span className="heading-3 asDisabled">{I18n.t('queries.advisor.toPoweredPretor')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='toPowered'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={true}
                                    />
                            </Col>
                        }
                    </Row>

                    {/*/*************************************************************SUGGESTIONS **************************************************************/}

                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.suggestionsAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorSuggestions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>

                    {!_.isEqual(initialValues.tutorSuggestions, initialValues.advisorSuggestions) && <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.suggestionsTutor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorSuggestions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>}

                    {isCorrectCheckedOrEnded && !_.isEqual(initialValues.tutorSuggestions, initialValues.suggestions) &&  <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.suggestionsPretor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='suggestions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>}

                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.finalRecomendationsAdvisor')}</span>
                            </TextBlock>
                        </Col>
                        <Message>
                            <Message.Header>{I18n.t('queries.advisor.text6')}</Message.Header>
                            <p>
                                {this.getStudios('advisorSchoolSuggestions')}
                            </p>
                            <Message.Header>{I18n.t('queries.advisor.text7')}</Message.Header>
                            <p>
                                {this.getSequences('advisorSequenceSuggestions')}
                            </p>
                        </Message>
                    </Row>

                    { 
                    (!_.isEqual(initialValues.advisorSchoolSuggestions, initialValues.tutorSchoolSuggestions) 
                    || (!_.isEqual(initialValues.advisorSequenceSuggestions, initialValues.tutorSequenceSuggestions)))
                     && <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.finalRecomendationsTutor')}</span>
                            </TextBlock>
                        </Col>
                        <Message>
                            {!_.isEqual(initialValues.advisorSchoolSuggestions, initialValues.tutorSchoolSuggestions) &&
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text6')}</Message.Header>
                                <p>
                                    {this.getStudios('tutorSchoolSuggestions')}
                                </p>
                            </React.Fragment> 
                            }
                            {!_.isEqual(initialValues.advisorSequenceSuggestions, initialValues.tutorSequenceSuggestions) && 
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text7')}</Message.Header>
                                <p>
                                    {this.getSequences('tutorSequenceSuggestions')}
                                </p>
                            </React.Fragment>
                            }
                        </Message>
                    </Row>
                    }

                    {isCorrectCheckedOrEnded && 
                    (!_.isEqual(initialValues.schoolSuggestions, initialValues.tutorSchoolSuggestions) 
                    || (!_.isEqual(initialValues.sequenceSuggestions, initialValues.tutorSequenceSuggestions)))
                     && <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.finalRecomendationsPretor')}</span>
                            </TextBlock>
                        </Col>
                        <Message>
                            {!_.isEqual(initialValues.schoolSuggestions, initialValues.tutorSchoolSuggestions) &&
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text6')}</Message.Header>
                                <p>
                                    {this.getStudios('schoolSuggestions')}
                                </p>
                            </React.Fragment> 
                            }
                            {!_.isEqual(initialValues.sequenceSuggestions, initialValues.tutorSequenceSuggestions) && 
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text7')}</Message.Header>
                                <p>
                                    {this.getSequences('sequenceSuggestions')}
                                </p>
                            </React.Fragment>
                            }
                        </Message>
                    </Row>
                    }

                    { (Utils.checkIfNotEqualAndNotEmpty(initialValues.advisorRoutine, initialValues.tutorRoutine) || Utils.checkIfNotEqualAndNotEmpty(initialValues.tutorRoutine, initialValues.routine))&& 
                        <YTab menu={{ secondary: true, pointing: true }} panes={this.buildPanes()} activeIndex={activePaneIndex} onTabChange={this.handleTabChange} /> 
                    }
                    <SequenceBuilder
                        reduxFormState={initialValues}
                        initialValues={initialValues}
                        asanas={asanas}
                        advancedAsanasIds={advancedAsanasIds}
                        canEdit={false}
                        dispatch={this.props.dispatch}
                        formName="pretorianCheckerForm"
                        routineKey={routineKey}
                    />          
                    
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.tutorMessageToAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorMessageToAdvisor'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>

                    { isCorrectCheckedOrEnded && !_.isEqual(initialValues.tutorMessageToAdvisor, initialValues.messageToAdvisor) &&
                        <Row>
                            <Col equal>
                                <TextBlock>
                                    <span className="heading-3 asDisabled">{I18n.t('queries.advisor.tutorMessageToAdvisorFromPretor')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='messageToAdvisor'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={true}
                                    />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.messageToStudent')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorMessageToStudent'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>

                    {isCorrectCheckedOrEnded && !_.isEqual(initialValues.tutorMessageToStudent, initialValues.messageToStudent) && <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.messageToStudentFromPretor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='messageToStudent'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>}

                    {(isCorrectCheckedOrEnded || isInCorrectChecked) && !_.isEmpty(initialValues.checkerMessageToNexoyoga) && 
                       
                        <Message icon='info'
                            header={I18n.t('queries.advisor.checkerMessageToNexoyoga')}
                            content={initialValues.checkerMessageToNexoyoga} />
                    }
                    {isCorrectCheckedOrEnded && initialValues.messageToChecker && 
                        
                        <Message icon='info'
                            header={I18n.t('queries.advisor.messageToChecker')}
                            content={initialValues.messageToChecker} />
                    }
                    {canEdit && <FlexWrap fend style={{marginTop: '50px', marginBottom: '50px'}}>
                        <OButton type="button" primary color="#fff" upper size="big" onClick={this.onSaveCorrectFeedback}>
                            <span>{I18n.t('actions.isCorrect')}</span>
                        </OButton>
                        <OButton type="button" selected color="#FF6767" upper size="big" onClick={this.onIncorrectFeedback}>
                            <span>{I18n.t('actions.isIncorrect')}</span>
                        </OButton>
                    </FlexWrap>}
                </Form>
            </InnerContent>
        )
    }
}

const mapStateToProps = state => {

    return {
        reduxFormState: _.cloneDeep(_.get(state, 'form.pretorianCheckerForm.values', {}))
    };
};

export default reduxForm({
    form: 'pretorianCheckerForm',
    touchOnBlur: true,
    touchOnChange: true,
    enableReinitialize:true,
})(connect(mapStateToProps, { openModal, closeModal })(PretorianCheckerView));
