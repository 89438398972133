import React from 'react';
import { FixedNav } from './components/fixedNav';
import MainNav from './components/mainNav';

const HandleType = ({history, type = 'main'}) => {

    switch(type) {

        case 'main':

            return <MainNav history={ history } />

         case 'fixed':

            return <FixedNav history={ history } />

        default : 
        
            return ''

    }

}

function TopNav(props) {

    return (
        <HandleType {...props} />
    )

}

export default TopNav;
