import SessionModel from '../data/models/sessions/sessions';

const GET_ASANAS =  'GET_ASANAS';

export const getAsanas = () => async dispatch => {

    try {
        
        const asanas = await SessionModel.getAsanas();
        
        dispatch ({ type: GET_ASANAS, payload:  asanas.data });

        return asanas;

    } catch (errorCode) {

        dispatch ({ type: GET_ASANAS, payload: []});
        throw errorCode;

    }

};

const INITIAL_STATE = {

    data:[]

};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_ASANAS:
            return { ...state, data: [...action.payload]};   
        default:
            return state;
            
    }

};

export default reducer;