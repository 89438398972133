import styled, { css } from 'styled-components/macro';

const Nav = styled.nav`
    flex:none;
    background:var(--brand-white);
    padding:10px 20px;
    ${({fixed}) => fixed &&
    css`
        position:fixed;
        top:0;
        left:0;
        width:100%;
        &{
            ${ContentNav} {
                justify-content:space-between;
            }
        }
    `};
    
`;

const ContentNav = styled.div`

    display:flex;
    align-items:center;
    @media (max-width:420px) {
        justify-content:space-between;
    }
`;

const NavItem = styled.div`
    cursor:pointer;
    margin-right:20px;
    ${({branded}) => branded &&
    css`
            img {
                width:206px;
                height:auto;
                @media (max-width:420px) {
                    width:170px;
                }
            }
    `};
    &:last-child {
        margin-left:auto;
        margin-right:0;
        @media (max-width:420px) {
            
            margin-left:0;
        }
    }

    ${({toggleIcon}) => toggleIcon &&
    css`
        @media (min-width:921px) {
            display:none;
        }
    `};

`;

const Dimmer = styled.div`

    position: fixed;
    background: rgba(0,0,0,0.5);
    top: 0;
    width: 100%;
    z-index: 13;
    min-height: 100vh;
    overflow:auto;
    bottom:0;
    
`;

const SideBarMobile = styled.div`
    background-color:white;
    top: 0;
    bottom: 0;
    overflow: auto;
    width:214px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    left: -214px;
    z-index: 14;
    transition: left 0.5s ease-in-out;
    padding-top: 10px;
    ${({expanded}) => expanded &&
    css`
        @media only screen and (max-width: 920px) {
            left: 0;
        }
    `};
`;

const SideBarItems = styled.div`

    width:100%;
    margin-top:40px;
`;

const SideBarItem = styled.div`

    display: block;
    padding: 15px 20px;
    color: var(--brand-primary);
    border-bottom: solid 1px #979797;
    cursor:pointer;
`;

const CloseMenu = styled.div`

    position: absolute;
    right: 10px;
    top: 10px;
    i {
        font-size: 22px;
        &:before {
            color: var(--y-grey3);
        }
    }
`;

export {
    Nav,
    ContentNav,
    NavItem,
    Dimmer,
    SideBarMobile,
    SideBarItems,
    SideBarItem,
    CloseMenu
}