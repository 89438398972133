import React from 'react'
import TopNav from '../../../../components/topNav'
import { Container, Content, Inner, InnerContent } from '../../../../styled/components/wrappers'

export function Tos(props) {
    
    return (
        <Container fixedTopBar>
            <TopNav {...props } type="fixed" />
            <Inner>
                <Content>
                    <InnerContent>
                        <p className="heading-2">Términos de uso de Yogabot</p>
                        <p className="paragraph-to-caption regular">Yogabot ofrece un servicio de suscripción personalizado que permite a nuestros suscriptores acceder a (en adelante "contenidos de Yogabot") transmitidos por Internet a determinados teléfonos, ordenadores y otros dispositivos conectados a Internet ("dispositivos compatibles con Yogabot")</p>
                        <p className="paragraph-to-caption regular">Estos Términos de uso rigen tu uso de nuestro servicio. A los efectos de estos Términos de uso, "servicio Yogabot", "nuestro servicio" o "el servicio" hacen referencia al servicio personalizado que presta Yogabot para encontrar y ver contenido de Yogabot, incluidas todas sus características y funcionalidades, recomendaciones y reseñas, el sitio web e interfaces de usuario, así como todos los contenidos y software asociados a nuestro servicio.</p>
                        <p className="paragraph-to-caption regular">1. <b>Suscripción</b></p>
                        <p className="paragraph-to-caption regular">1.1. Tu suscripción a Yogabot continuará hasta su conclusión. Para utilizar el servicio Yogabot, debes tener acceso a Internet y un dispositivo compatible con Yogabot, y facilitarnos uno o más métodos de pago. "Método de pago" es una forma de pago aceptada, válida y vigente, que puedes actualizar cuando lo consideres oportuno y que puede incluir el pago a través de tu cuenta con terceras partes. A menos que canceles tu suscripción con anterioridad a la fecha de facturación, nos autorizas a cargar la cuota de suscripción correspondiente al siguiente ciclo de facturación en tu Método de pago (consulta "Cancelación" más adelante).</p>
                        <p className="paragraph-to-caption regular">1.2. En ciertas ocasiones, te ofreceremos diversos planes de suscripción, incluso planes promocionales o suscripciones especiales ofrecidos por un tercero en combinación con la prestación de sus propios productos y servicios. Algunos planes de suscripción pueden tener distintas condiciones y limitaciones, que se expondrán cuando te registres o en otros comunicados que podrás consultar. Puedes encontrar detalles específicos acerca de tu suscripción a Yogabot visitando nuestro sitio web y haciendo clic en el enlace "Mi Perfil - Información de Cuenta" disponible en la parte superior de las páginas del sitio web Yogabot</p>
                        <p className="paragraph-to-caption regular">2. <b>Pruebas</b></p>
                        <p className="paragraph-to-caption regular">2.1. Tu suscripción a Yogabot  “Básico” es gratuita, mientras dure el período de promoción de este servicio, sin embargo el  servicio Yogabot “Premium” puede comenzar también, con una prueba gratuita. La duración del periodo de prueba gratuita de tu suscripción se especificará en el momento de suscribirte, y su objetivo es permitir a los nuevos suscriptores, y a algunos antiguos suscriptores, probar el servicio, Yogabot “Premium”.</p>
                        <p className="paragraph-to-caption regular">2.2. Yogabot determina los requisitos de idoneidad o la duración para pruebas gratuitas de Yogabot “Premium” a su sola discreción y puede limitar la elegibilidad para evitar el abuso de las mismas. Yogabot se reserva el derecho de anular la prueba gratuita y bloquear tu cuenta si determinásemos que no cumples los requisitos de idoneidad. Los suscriptores con una suscripción a Yogabot “Premium” ya existente o reciente no pueden ser elegidos. Podemos emplear información como identificadores de dispositivo, el método de pago o la dirección de correo electrónico de una cuenta utilizados con una suscripción a Yogabot “Premium” ya existente o reciente para determinar la elegibilidad.</p>
                        <p className="paragraph-to-caption regular">2.3. Al final del periodo de prueba gratuita, para Yogabot “Premium” cargaremos en tu Método de pago, tu cuota de suscripción del siguiente período de facturación si no has cancelado tu suscripción antes de finalizar dicha prueba. Si quieres ver la cuota de suscripción y la fecha final de tu periodo de prueba gratuita, visita nuestro sitio web y haz clic en el enlace "Datos de facturación" en la opción “Mi Perfil - Información de Cuenta".</p>
                        <p className="paragraph-to-caption regular">3. <b>Facturación y cancelación</b></p>
                        <p className="paragraph-to-caption regular">3.1. Ciclo de facturación. La cuota de suscripción al servicio de Yogabot “Premium”se cobrará en tu Método de pago en la fecha de facturación indicada en tu página "Información de Cuenta". La longitud de tu ciclo de facturación dependerá del tipo de suscripción que elijas al registrarte en el servicio Yogabot “Premium”. En algunos casos, la fecha de facturación puede cambiar, como por ejemplo si tu Método de pago no se ha configurado correctamente o si tu suscripción de pago se inició en un día no existente en un mes determinado. Visita nuestro sitio web y haz clic en el enlace "Datos de facturación" en la página "Información de Cuenta" para consultar la fecha de tu próximo pago.</p>
                        <p className="paragraph-to-caption regular">3.2. Métodos de pago. Para utilizar el servicio Yogabot “Premium” debes proporcionar uno o más Métodos de pago. Si tu Método de pago principal es rechazado o ya no está disponible para que podamos cobrarte tu cuota de suscripción, nos autorizas a cobrarte a través de cualquier otro Método de pago asociado a tu cuenta. Tú seguirás siendo el responsable de cualquier importe no cobrado. Si no conseguimos cobrar un importe (por motivos como la caducidad de tu Método de pago, falta de fondos o cualquier otra causa) y tú no cancelas tu cuenta, podemos suspender tu acceso al servicio Yogabot “Premium” hasta que hayamos cobrado la deuda a través de un Método de pago válido. El emisor de algunos Métodos de pago puede cobrarte ciertas tarifas por conceptos como una operación en moneda extranjera u otros cargos relacionados con el procesamiento de tu Método de pago. Los cargos por impuestos locales pueden variar dependiendo del Método de pago usado. Consulta con tu proveedor de servicio de Método de pago para obtener detalles.</p>
                        <p className="paragraph-to-caption regular">3.3. Cambio de tus Métodos de pago. Puedes modificar tus Métodos de pago en la página "Información de Cuenta". Nosotros también podemos modificar tus Métodos de pago de acuerdo con los datos suministrados por los proveedores del servicio de pago. Después de cada modificación, nos autorizas a seguir cobrándote a través del Método o Métodos de pagos pertinentes.</p>
                        <p className="paragraph-to-caption regular">3.4. Cancelación. Puedes cancelar tu suscripción a Yogabot “Premium”  en cualquier momento, y seguirás teniendo acceso al servicio Yogabot contratado hasta el final de tu periodo de facturación. Los pagos no son reembolsables y no facilitamos devoluciones ni abonos por períodos parciales de suscripción  ni por contenidos de Yogabot contratados, no visionados. Para cancelar tu suscripción, ve a la página "Información de Cuenta" y sigue las instrucciones para la cancelación. Si cancelas tu suscripción a Yogabot “Premium” tu cuenta pasará automáticamente a la suscripción “Básica” al final de tu período de facturación corriente. Para ver cuándo se modificará la suscripción de tu cuenta, haz clic en "Datos de facturación" en la página "Información de Cuenta". Si cancelas tu suscripción “Básico”, tu cuenta se cerrará y ya no podrás disponer del servicio.</p>
                        <p className="paragraph-to-caption regular">3.5. Cambios de precios y de planes de suscripción. Podemos cambiar nuestros planes de suscripción y su precio cuando lo consideremos oportuno. Sin embargo, ningún cambio de precio ni de tus planes de suscripción entrará en vigor antes de los 30 días siguientes a nuestra notificación.</p>
                        <p className="paragraph-to-caption regular">4. <b>Servicio Yogabot</b></p>
                        <p className="paragraph-to-caption regular">4.1. Debes tener 18 años de edad, o ser mayor de edad en tu provincia, territorio o país, para poder suscribirte al servicio Yogabot. Los menores de edad solo pueden utilizar el servicio bajo la supervisión y responsabilidad de un adulto.</p>
                        <p className="paragraph-to-caption regular">4.2. El servicio Yogabot y todos los contenidos que se vean a través del servicio son para tu uso personal y no comercializable y no se pueden compartir con otros individuos. Durante tu suscripción a Yogabot, te concedemos un derecho limitado, no exclusivo e intransferible, para acceder al servicio Yogabot y ver contenidos de Yogabot. A excepción de lo mencionado, no se te transferirá ningún derecho, título o beneficio. Aceptas no utilizar el servicio para exhibiciones públicas.</p>
                        <p className="paragraph-to-caption regular">4.3. El servicio Yogabot, incluida la biblioteca de contenidos, se actualiza con regularidad. Además, analizamos continuamente varios aspectos de nuestro servicio, incluido nuestro sitio web, las interfaces de usuario, las funciones promocionales y la disponibilidad del contenido de Yogabot.</p>
                        <p className="paragraph-to-caption regular">4.4. Aceptas no archivar, reproducir, distribuir, modificar, exhibir, ejecutar, publicar, licenciar, crear trabajos derivados, poner a la venta, o (salvo lo autorizado de modo expreso en estos Términos de uso) usar contenidos o información contenida en el servicio Yogabot  u obtenidos a través de él o en él. También aceptas no burlar, eliminar, alterar, desactivar, rebajar o impedir ninguna de las protecciones de contenido del servicio Yogabot; no usar ningún robot, spider, scraper u otros medios automáticos para acceder al servicio Yogabot; no descompilar, hacer ingeniería inversa o desmontar ningún programa informático u otros productos o procesos accesibles desde el servicio Yogabot; no introducir ningún código o producto o manipular los contenidos del servicio Yogabot de ninguna manera; y no usar ningún método de búsqueda, recopilación o extracción de datos. Además, aceptas no subir, publicar, enviar por correo electrónico o transmitir por ningún otro medio ningún material diseñado para interrumpir, destruir o limitar la funcionalidad de ningún software, hardware o equipo de telecomunicaciones asociado al servicio Yogabot, como por ejemplo virus informáticos u otros códigos de ordenador, archivos o programas. Podemos resolver o restringir tu uso de nuestro servicio si infringes estos Términos de uso o si incurres en uso ilegal o fraudulento del servicio.</p>
                        <p className="paragraph-to-caption regular">4.5. La calidad de exhibición del contenido de Yogabot puede variar en función del dispositivo, y puede verse afectada por diversos factores, como tu ubicación, el ancho de banda disponible y/o la velocidad de tu conexión a Internet.. Eres responsable de todos los cargos por conexión a Internet. Comprueba con tu proveedor de Internet la información sobre posibles cargos por uso de datos de Internet. El tiempo que tarda en empezar a verse el contenido de Yogabot varía en función de varios factores, entre otros tu ubicación, el ancho de banda disponible en ese momento, el contenido que hayas seleccionado y la configuración de tu dispositivo compatible con Yogabot.</p>
                        <p className="paragraph-to-caption regular">4.6. El software de Yogabot ha sido desarrollado por Yogabot o para Yogabot y está diseñado para permitir el visionado de contenidos de Yogabot mediante dispositivos compatibles con Yogabot. Este software puede variar según el dispositivo y el soporte, y sus funciones pueden también diferir entre dispositivos. Reconoces que el uso del servicio puede requerir software de terceros sujeto a licencias de dichos terceros. Aceptas que podrías recibir de modo automático versiones actualizadas del software de Yogabot y de terceros relacionados.</p>
                        <p className="paragraph-to-caption regular">5. <b>Contraseñas y acceso a la cuenta.</b></p>
                        <p className="paragraph-to-caption regular">5.1. El suscriptor que haya creado la cuenta de Yogabot (el "Titular de la cuenta") tiene acceso a la cuenta de Yogabot y control sobre ella así como de los dispositivos compatibles con Yogabot utilizados para acceder a nuestro servicio, y es responsable de la actividad que se realice en dicha cuenta de Yogabot. Para mantener el control sobre la cuenta y evitar que cualquiera acceda a ella (que incluiría información sobre la práctica de yoga y la Matríz Biométrica personalizada del usuario), el Titular de la cuenta deberá mantener el control de todos los dispositivos compatibles con Yogabot que se usen para acceder al servicio y no revelar a nadie ni la contraseña ni los detalles del Método de pago asociados a dicha cuenta. Eres responsable de actualizar y mantener la veracidad de la información que nos facilites acerca de tu cuenta. Podemos cancelar tu cuenta o bloquearla para protegerte a ti, a Yogabot o a nuestros asociados de usurpación de identidad o de otra actividad fraudulenta</p>
                        <p className="paragraph-to-caption regular">6. <b>Disposiciones.</b></p>
                        <p className="paragraph-to-caption regular">6.1. Legislación aplicable. Estos Términos de uso se regirán e interpretarán de acuerdo con la legislación Española. Estos términos no limitarán ninguna protección al consumidor a la que puedas tener derecho conforme a la legislación vigente en tu país de residencia.</p>
                        <p className="paragraph-to-caption regular">6.2. Materiales no solicitados. Yogabot no acepta materiales no solicitados ni ideas para contenido de Yogabot, y no se hace responsable del parecido de ninguno de sus contenidos  en ningún medio con materiales o ideas enviados a Yogabot.</p>
                        <p className="paragraph-to-caption regular">6.3. Atención al cliente. Para obtener más información sobre nuestro servicio y sus opciones, o si necesitas ayuda con tu cuenta, visita el Centro de ayuda de Yogabot en nuestro sitio web. </p>
                        <p className="paragraph-to-caption regular">6.4. Subsistencia. Si alguna disposición o disposiciones de estos Términos de uso fueran declaradas inválidas, ilegales o ineficaces, la validez, legalidad y eficacia de las restantes disposiciones permanecerá en vigor y con plenos efectos.</p>
                        <p className="paragraph-to-caption regular">6.5. Cambios en los Términos de uso. Yogabot puede cambiar estos Términos de uso cuando lo considere oportuno. Te avisaremos por lo menos 30 días antes de que esos cambios te afecten.</p>
                        <p className="paragraph-to-caption regular">6.6. Comunicados electrónicos. Te enviaremos información acerca de tu cuenta (autorizaciones de pago, facturas, cambios en la contraseña o en el Método de pago, mensajes de confirmación, avisos, etc.) únicamente en formato electrónico; por ejemplo: a través de correo electrónico a la dirección proporcionada al registrarte.</p>
                        <p className="paragraph-to-caption regular"><b>Última actualización</b>: 8 de Mayo del 2020</p>
                    </InnerContent>
                </Content>
            </Inner>
        </Container>
    )
}
