import _ from 'lodash';
import React from 'react';

 const InputFile = ({ input, resetKey })  =>  {

    const { value, ...inputProps } = input;

	const handleChange = async (e) => {

        if (_.get(e, 'target.files[0]', false)) {

            var reader = new FileReader();

            window['tempFile'] = { ref: e.target.files[0] }

            reader.onload = function(event) {
    
                _.set(window, 'tempFile.raw', event.target.result);
                input.onChange('TEMPORAL_UNTIL_PRESS_SAVE');
    
            };
    
            reader.readAsDataURL(window['tempFile'].ref);

        }

	}
        
    return (
        <input {...inputProps} key={resetKey} type="file" onChange={handleChange} onBlur={()=>{}} className="hide-input" />
    );

};

export default InputFile;