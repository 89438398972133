import _ from 'lodash';
import React, { Component } from 'react';
import I18n from '../../../../../i18n';
import BarChartView from './view';

class BarChartContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            transformedData:[]
        }

    }

    componentDidMount() {

        this.transformData();

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.data, this.props.data)) {
            
            this.transformData();

        }
    
    }
    
    transformData = () => {

        const { enviroment } = this.props;

        switch(enviroment) {

            case 'queriesType':
                this.transformQueriesType();
                break;
            default:
                return null;
        }

    }

    

    transformQueriesType = () => {

        const { data } = this.props;

        let transformedData = [];

        let freeQueries = {
            category : I18n.t('resumen.freeQueries'),
            value: 0
        };
        let paidQueries = {
            category : I18n.t('resumen.paidQueries'),
            value: 0
        };

        _.each(data, ({price}) => price > 0 ? paidQueries.value++ : freeQueries.value++);

        transformedData.push(freeQueries, paidQueries);

        this.setState({transformedData});

    }

    render() {

        const { transformedData } = this.state;

        return (
            <BarChartView data={transformedData} />
        );
    }
}

export default BarChartContainer;