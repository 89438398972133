import styled from 'styled-components/macro';

const FilterBlock = styled.div`
    background:var(--dark-grey-4);
`;

const FilterContent = styled.div`
    display:flex;
    justify-content:space-between;
    @media (max-width: 920px) {
        flex-direction: column;
    }
`;

const SearchBlock = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 675px;
    flex-wrap: wrap;
    padding-left: 20px;
    @media (max-width: 920px) {
        display: block;
        padding: 0;
    }
`;

export {
    FilterBlock,
    FilterContent,
    SearchBlock
};