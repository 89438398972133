import SessionModel from '../data/models/sessions/sessions';

const GET_PROGRAMS =  'GET_PROGRAMS';

export const getPrograms = () => async dispatch => {

    try {
        
        const programs = await SessionModel.getPrograms();
        
        dispatch ({ type: GET_PROGRAMS, payload:  programs.data });

        return programs;

    } catch (errorCode) {

        dispatch ({ type: GET_PROGRAMS, payload: []});
        throw errorCode;

    }

};

const INITIAL_STATE = {

    data:[]

};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_PROGRAMS:
            return { ...state, data: [...action.payload]};   
        default:
            return state;
            
    }

};

export default reducer;