import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const getAssignedAdvisorUsers = async () => await AxiosService.get(Config.server.api.questionApi + 'advisor/user/list', true);
const getQueriesAssignedAdvisorUser = async userId => await AxiosService.get(Config.server.api.questionApi + `advisor/user/${userId}`, true);
const getQueriesAssignedAdvisorUsers = async () => await AxiosService.get(Config.server.api.questionApi + `advisor/user/query/list`, true);
const getAdvisorQuery = async queryId => await AxiosService.get(Config.server.api.questionApi + `advisor/query/${queryId}`, true);
const acceptAdvisorQuery = async advisorUserAnswersId => await AxiosService.put(Config.server.api.questionApi + `advisor/user/query/${advisorUserAnswersId}/accept`, { }, true);
const denyAdvisorQuery = async advisorUserAnswersId => await AxiosService.put(Config.server.api.questionApi + `advisor/user/query/${advisorUserAnswersId}/deny`, { }, true);
const getQueriesAdvisorStructure = async () => await AxiosService.get(Config.server.api.questionApi + 'advisor' , {}, true);

const advisorAnswer = async (queryId, payload) => await AxiosService.put(Config.server.api.questionApi + `advisor/user/query/${queryId}/answer` , payload , true);
const pretorianTutorAnswer = async (queryId, payload) => await AxiosService.put(Config.server.api.questionApi + `advisor/user/query/${queryId}/tutorize` , payload, true);
const pretorianCheckerActions = async (queryId, payload) => await AxiosService.put(Config.server.api.questionApi + `advisor/user/query/${queryId}/check` , payload, true);
const pretorAnswer = async (queryId, payload) => await AxiosService.put(Config.server.api.questionApi + `advisor/user/query/${queryId}/nexoyoga` , payload, true);

export default {
    getAssignedAdvisorUsers,
    getQueriesAssignedAdvisorUser,
    getQueriesAssignedAdvisorUsers,
    getAdvisorQuery,
    acceptAdvisorQuery,
    denyAdvisorQuery,
    getQueriesAdvisorStructure,
    advisorAnswer,
    pretorianTutorAnswer,
    pretorianCheckerActions,
    pretorAnswer
}
