import React from 'react';
import { Form, Responsive } from 'semantic-ui-react';
import I18n from '../../../../../i18n';
import LogoDesktop from '../../../../assets/img/logo_mobile.svg';
import SecurityModel from '../../../../../data/models/security/security';
import LogoMobile from '../../../../assets/img/logo_secondary.svg';
import OButton from '../../../../styled/components/button';

class verifyEmail extends React.Component {


    constructor(props){
        super(props);

        this.state =  {
            token : this.props.match.params.token,
            validated: false,
            loaded: false,
            emailAlreadyInUse: false
        }
    }

    async componentDidMount() {

        try {

            const response = await SecurityModel.verifyEmail(this.state.token);

            if (response.status === 200) {

                this.setState({ validated: true, loaded: true });

            }
            
        } catch (errorCode) {

            this.setState({ validated: false, loaded: true, emailAlreadyInUse: errorCode === 409 });

        } 

    }

    render() {

        return (

            <div className="full-wrapper">
                <div className="floating"><OButton type="button" primary upper onClick={() => this.props.history.push('/auth/login')}><p>{I18n.t('auth.login')}</p></OButton></div>
                <div className="wrapper-login">
                    <div className="box">
                    <div className="correct-logo">
                        <img src={LogoDesktop} alt="" />
                    </div>
                    <div className="correct-logo-mobile" style={{ paddingTop: '40px' }}>
                        <img src={LogoMobile} alt="" />
                    </div>
                    <div className="correct-message-title">
                        {this.state.loaded && this.state.validated && <p>{ I18n.t('auth.verifyEmail')}</p>}
                        {this.state.loaded && !this.state.validated && <p>{ I18n.t('auth.verifyEmailError')}</p>}
                    </div>
                    <div className="correct-message-subtitle">
                    {this.state.loaded && this.state.validated && <p>{ I18n.t('auth.restoreCorrectMessageSecond')}</p>}
                    {this.state.loaded && !this.state.validated && !this.state.emailAlreadyInUse && <p>{ I18n.t('auth.verifyEmailErrorTryAgain')}</p>}
                    {this.state.loaded && !this.state.validated && this.state.emailAlreadyInUse && <p>{ I18n.t('status.code.409')}</p>}
                    </div>
                    <Form name="recoveryStep3Form">
                        <div className="verticalPad-long t-center">
                                <Responsive minWidth={421}>
                                    <OButton type="button" onClick={ ()=> this.props.history.push(`/auth/login`) } primary upper size="huge"><p>{I18n.t('auth.finalize')}</p></OButton>
                                </Responsive>
                                <Responsive maxWidth={420}>
                                    <OButton type="button" onClick={ ()=> this.props.history.push(`/auth/login`) } primary upper><p>{I18n.t('auth.finalize')}</p></OButton>
                                </Responsive>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

        );

    }

}

export default verifyEmail;