import styled from 'styled-components/macro';

const Block = styled.div`

    margin:20px 0;

    i {
        margin:0;
        &:before{
            color:#fff;
        }
    }
`;

const CircleWrap = styled.span`

    background: var(--blue-dark);
    border-radius:22px;
    width:22px;
    height:22px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-right:5px;
`;

const BlockInput = styled.div`

    display:flex;
    flex-wrap:wrap;
    margin:20px 0;
    &>div {
        flex-grow:2;
        margin-right:10px;
    }

    button {
        flex-grow:1;
    }
    @media (max-width:420px) {
        &>div {
            flex:0 1 100%;
            margin-right: 0;
        }

        button {
            flex:0 1 auto;
            margin-top:10px!important;
        }
    }
`;

const BlockAccumulator = styled.div`

    padding:10px;
    border-bottom: solid 1px var(--border-color);
    display:flex;
    justify-content:space-between;
    i {
        &:before {
            color:var(--dark-grey-2);
        }
    }
`;

const BlockLink = styled.div`
    margin:20px 0;
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
`;

export {
    Block,
    CircleWrap,
    BlockInput,
    BlockAccumulator,
    BlockLink
}