import React, { Component } from 'react'
import I18n from '../../../../../../../../i18n'
import { Picture, PictureBlock, YRow, YTable } from '../../../../../../../styled/components/tables'
import { Menu, Pagination } from 'semantic-ui-react'
import _ from 'lodash'
import ProductModel from '../../../../../../../../data/models/product/product'

export default class StudioTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            studios: [],
            pageNumber: 1,
            recordsPerPage: 6,
            filteredStudios: [],
            activePage:1
        }

    }

    componentDidMount(){

        this.getUserSessionsInfos();

    }

    getUserSessionsInfos = async () => {

        const { match: { params: { userId } }, openLoader, closeLoader } = this.props;
        const { recordsPerPage } = this.state;

        try {

            openLoader();
            const allStudios = await ProductModel.getProducts();
            const studios = await ProductModel.getAssignedAdvisorUserProduct(userId);
            const mergeStudios = _.map(studios.data, studio => {
                return {
                    ...studio,
                    imageUrl: _.find(allStudios.data, ({ _id }) => _id === studio._id ).imageUrl
                }
            });
            this.setState({
                studios: mergeStudios,
                filteredStudios: mergeStudios.slice(0, recordsPerPage)
            });
            
        } catch (error) {

            
        } finally {

            closeLoader();

        }

    }

    onPageChange = (e, pageInfo) => {

        const { studios, recordsPerPage } = this.state;
        //0 * 5 , 1 * 5 => 1*5 , 2 * 5 
        this.setState({activePage: pageInfo.activePage, filteredStudios: studios.slice((pageInfo.activePage - 1) * recordsPerPage, pageInfo.activePage * recordsPerPage) });
        
    }

    render() {

        const { studios, filteredStudios, recordsPerPage, activePage } = this.state;

        return !_.isEmpty(filteredStudios) ? (
            <YTable stackable padded='very' selectable style={{marginTop:'20px'}}>
                <YTable.Header>
                    <YRow>
                        <YTable.HeaderCell>{I18n.t('users.asana')}</YTable.HeaderCell>
                        <YTable.HeaderCell>{I18n.t('users.sanscritoName')}</YTable.HeaderCell>
                        <YTable.HeaderCell>{I18n.t('users.esTitle')}</YTable.HeaderCell>
                    </YRow>
                </YTable.Header>
                <YTable.Body>
                    {
                        _.map(filteredStudios, ({imageUrl, title, subtitle}, index) => (

                            <YRow key={'s-'+ index}>
                                <YTable.Cell>
                                    <PictureBlock>
                                        <Picture square>
                                            <img src={imageUrl } alt="" />
                                        </Picture>
                                    </PictureBlock>
                                </YTable.Cell>
                                <YTable.Cell>
                                    <span>{title}</span>
                                </YTable.Cell>
                                <YTable.Cell>
                                    <span>{subtitle}</span>
                                </YTable.Cell>
                            </YRow>

                        ))
                    }
                </YTable.Body>
                <YTable.Footer>
                <YRow>
                    <YTable.HeaderCell colSpan='8'>
                        <Menu floated='right'>
                            <Pagination
                                onPageChange={this.onPageChange}
                                totalPages={ Math.ceil(studios.length / recordsPerPage) }
                                ellipsisItem={null}
                                activePage={activePage}
                                boundaryRange={0}
                                size='mini'
                            />
                        </Menu>
                        <div style={{clear:"both"}}></div>
                    </YTable.HeaderCell>
                </YRow>
            </YTable.Footer>
            </YTable>
        ) : <p className="caption regular">{I18n.t('messages.emptyData')}</p>
    }
}
