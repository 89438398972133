import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Responsive } from 'semantic-ui-react';
import CustomInput from '../../../../components/form/input';
import I18n from '../../../../../i18n';

import Logo from '../../../../assets/img/logo_secondary.svg';
import SecurityModel from '../../../../../data/models/security/security';
import OButton from '../../../../styled/components/button';

class RecoveryStep1 extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            codeError: -1,
            hasError: false,
            correct: false
        }
    }

    onRecovery = async payload => {

        try {

            const response = await SecurityModel.recoveryPassword({email: payload.email});

            if (response.status === 200) {

                this.setState({correct: true, hasError: false});
            }

        } catch (codeError) {

            this.setState({hasError: true, codeError: 'error-recovery'});

        }

    };

    onBack = () => {

        this.props.history.push(`/auth/login`);

    }

    render() {

        return (

            <div className="full-wrapper">
                <div className="floating"><OButton type="button" primary upper onClick={() => this.props.history.push('/auth/login')}><p>{I18n.t('auth.login')}</p></OButton></div>
                <div className="wrapper-login">
                    <div className="box">
                        <div className="logo">
                            <img src={Logo} alt="logo" />
                        </div>
                        <div className="logo-mobile" style={{ paddingTop: '40px' }}>
                            <img src={Logo} alt="logo" />
                        </div>
                        <div className="title upper">
                            { I18n.t("auth.recovery") }
                        </div>
                        <div className="text">
                            <p>{ I18n.t("auth.recoveryMessage") }</p>
                        </div>
                        <Form name="recoveryStep1Form" onSubmit={this.props.handleSubmit(this.onRecovery)}>
                            <div className="verticalPad">
                                <Field
                                    component={ CustomInput }
                                    placeholder={ "Dirección Email" }
                                    name="email"
                                    fieldClasses="y-input default"
                                    label={ 'EMAIL' } />
                            </div>
                            <div className="verticalPad-long t-center">
                                <Responsive minWidth={421}>
                                    <OButton type="submit" primary upper size="huge"><p>{I18n.t('auth.send')}</p></OButton>
                                </Responsive>
                                <Responsive maxWidth={420}>
                                    <OButton type="submit" primary upper><p>{I18n.t('auth.send')}</p></OButton>
                                </Responsive>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');
    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errors.email = I18n.t('validations.emailInvalid');
      }

    return errors;

};

export default reduxForm({
    form: 'recoveryStep1Form',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    enableReinitialize: true,
    initialValues: {
        email: '',
    }
})(connect(null, { })(RecoveryStep1));