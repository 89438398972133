import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import loaderImage from '../../assets/img/loader.svg';
import { DimmerWrap } from './styles/styled';

class Loader extends React.Component {

    render() {

        return this.props.open ? (
            <DimmerWrap active={true} >
                <img src={loaderImage} alt="loader" />
            </DimmerWrap>
        ) : '';

    }

}

const mapStateToProps = state => _.cloneDeep(state.loader);

export default connect(mapStateToProps, {})(Loader);