import _ from 'lodash';
import React from 'react';

import { FrameCircle, FrameCirclePercent, CircleSvg, CircleShape, CircleNumber } from './styles';

function ImcProgress({imc, divider = 100 }) {
 
    return !_.isNaN(imc) && (
        <FrameCircle>
            <FrameCirclePercent>
                <CircleSvg>
                    <CircleShape r="43" cx="43" cy="43"></CircleShape>
                    <CircleShape r="43" cx="43" cy="43" style={{ stroke: getProgressColor(imc || 0) }}>
                        <animate attributeType="XML" begin="1s" attributeName="stroke-dashoffset" from="270" to={ (270 - (270 * Math.min(imc || 0, divider) / divider)) } dur="2s" fill="freeze"/> 
                    </CircleShape>
                </CircleSvg>
                <CircleNumber><span style={{ color: getProgressColor(imc) }} className="heading-3 regular">{ imc }</span></CircleNumber>
            </FrameCirclePercent>
        </FrameCircle>
    );

}

function getProgressColor(imc) {

    if (imc < 18.5) {

        return '#ffcf00';

    } else if (imc >= 18.5 && imc < 25) {

        return '#2dcd70';

    } else {

        return '#E20D0D'

    }

}

export default ImcProgress;