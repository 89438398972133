import React from 'react'
import { Responsive } from 'semantic-ui-react'
import I18n from '../../../../../i18n'
import OButton from '../../../../styled/components/button'
import { ContentNav, Nav, NavItem } from '../../styles/styled'
import YogaIcon from '../../../../assets/img/YOGABOT-ADVISOR.png';

export function FixedNav(props) {

    return (
        <Nav fixed>
            <ContentNav>
                <NavItem branded>
                    <img src={YogaIcon} alt="" />
                </NavItem>
                <Responsive minWidth={421}>
                    <OButton type="button" primary upper color="#fff" size="small" onClick={() => props.history.push('/auth/login')}>
                        <span>{I18n.t('auth.login')}</span>
                    </OButton> 
                </Responsive>
                <Responsive maxWidth={420}>
                    <OButton type="button" primary upper color="#fff" size="mini" onClick={() => props.history.push('/auth/login')}>
                        <span>{I18n.t('auth.login')}</span>
                    </OButton> 
                </Responsive>
            </ContentNav>
        </Nav>
    )
}
