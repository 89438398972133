// Third party libraries
import _ from 'lodash';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

// Config
import Config from '../config';

// Models
import SecurityModel from '../data/models/security/security';

const firebase = Firebase.initializeApp(Config.firebase);

const askForNotificationsPermission = async notificationTokens => {

    try {

        const messaging = firebase.messaging();
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {

            const notificationToken = await messaging.getToken();

            messaging.onMessage(payload => {

                const data = payload.notification;
                new Notification(data.title, {
                    body: data.body,
                    image: data.image,
                    icon: 'https://yogabot.app/assets/img/favicon/apple-touch-icon.png'
                });

            });

            if (!_.find(notificationTokens, ({ token }) => token === notificationToken)) {

                SecurityModel.insertUserNotificationToken({ token: notificationToken, platform: 'Web' });

            }

        }

    } catch (error) {

        console.error(error);

    }

};

export {
    askForNotificationsPermission
};