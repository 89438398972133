import React, { Component } from 'react'
import { Redirect, Route } from 'react-router-dom'
import AsideMenu from '../../../../components/asideMenu'
import HeaderElement from '../../../../components/headerElement'
import TopNav from '../../../../components/topNav'
import { Container, Content, Inner } from '../../../../styled/components/wrappers'
import UserPractices from './components/practices'
import UserProfile from './components/profile'
import UserQueries from './components/queries'
import { openLoader, closeLoader } from '../../../../../redux-store/loader';
import { connect } from 'react-redux'
import SecurityModel from '../../../../../data/models/security/security'
import BiometricModel from '../../../../../data/models/biometric/biometric'
import I18n from '../../../../../i18n'

const PrivateRoute = ({ ...props }) => window.localStorage.getItem('jwt') ? <Route { ...props } /> : <Redirect to="/auth/login" />;

class User extends Component {

    constructor(props){
        super(props);

        this.state = {
            biometricData : {},
            userInfo: {}

        }
    }
    
    componentDidMount() {

        this.getUserInfos();

    }

    getUserInfos = async () => {


        const { match: { params: { userId } } } = this.props;

        try {

            this.props.openLoader();
            const userInfo = await SecurityModel.getAssignedAdvisorUserInfos(userId).catch(err => console.error(err));
            const userBiometricInfo = await BiometricModel.getAssignedAdvisorUserBiometricInfos(userId).catch(err => console.error(err));
            this.setState({
                userInfo: userInfo.data,
                biometricData: userBiometricInfo.data

            });

        } finally {

            this.props.closeLoader();

        }

    }

    render() {

        const { biometricData, userInfo } = this.state;

        const headerInfo = {
            profile: {
                breadcrumb: [{
                    text: I18n.t('users.myUsers'),
                    action: () => this.props.history.push('/users')
                },
                {
                    text: userInfo.name || ''
                }],
                title: userInfo.name || ''
            }
        }

        return (
            <Container>
                <TopNav {...this.props } />
                <Inner>
                    <AsideMenu active="users" {...this.props } />
                    <Content>
                        <HeaderElement type="simple" headerInfo={ headerInfo.profile } />
                        <PrivateRoute path="/user/:userId/profile" exact render={(props) => <UserProfile userInfo={userInfo} biometricData={biometricData} {...props }/>}/>
                        <PrivateRoute path="/user/:userId/queries" exact component={ UserQueries } />
                        <PrivateRoute path="/user/:userId/practices" exact component={ UserPractices } />
                    </Content>
                </Inner>
            </Container>
        )

    }

}

export default connect(null, { openLoader, closeLoader })(User)