import _ from 'lodash'
import React from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';
import moment from 'moment';

import { TimeContainer } from '../../../styles/styled';

import I18n from '../../../../../../i18n';

const days = {
    0: I18n.t('profile.sunday'),
    1: I18n.t('profile.monday'),
    2: I18n.t('profile.tuesday'),
    3: I18n.t('profile.wednesday'),
    4: I18n.t('profile.thursday'),
    5: I18n.t('profile.friday'),
    6: I18n.t('profile.saturday'),
};

export default function ScheduleTable(props) {

    return (
        <TimeContainer> 
            <Table stackable>
                <Table.Body>
                    {_.map(_.orderBy(props.list, ['dayOfWeek'], ['asc']) || [], (item, index) => (
                        <Table.Row key={index}>
                            <Table.Cell><span className="caption regular">{days[item.dayOfWeek]}</span></Table.Cell>
                            <Table.Cell><span className="caption regular">{moment(item.ini).format('HH:mm')}</span></Table.Cell>
                            <Table.Cell><span className="caption regular">{moment(item.end).format('HH:mm')}</span></Table.Cell>
                            <Table.Cell><Icon name="trash alternate" className="c-pointer" onClick={()=> props.onRemoveTime(item)}/></Table.Cell>
                        </Table.Row>
                    )) }
                </Table.Body>
            </Table>
            { props.meta.touched && props.meta.invalid && <Label basic color={ props.colorLabel || 'red' } pointing>{ props.meta.error }</Label> }
        </TimeContainer>
    );
     
}
