import _ from 'lodash';
import React from 'react';
import { Modal } from 'semantic-ui-react';

import { connect } from 'react-redux';

import { closeModal } from '../../../redux-store/modals';

import InvitationModal from './components/invitationModal';
import ScheduleModal from './components/scheduleModal';
import SimpleModal from './components/simpleModal';
import PretorianCheckerModal from './components/pretorianCheckerModal';
import LegendStatusModal from './components/legendStatusModal';

function HandleModal({ message, onClose }) {
    
    switch(message.type) {

        case 'invitation':

            return <InvitationModal { ...message } onClose={ onClose } />

        case 'schedule':
            
            return <ScheduleModal { ...message } onClose={ onClose } />

        case 'simple':
        
            return <SimpleModal { ...message } onClose={ onClose } />

        case 'pretorianChecker': 

            return <PretorianCheckerModal {...message} onClose={ onClose } />

        case 'legendStatus': 

            return <LegendStatusModal {...message} onClose={ onClose } />

        default:

            return ''

    }

}

class Modals extends React.Component {

    render() {

        return this.props.open && (
            <Modal open={ this.props.open } size={this.props.size}>
                <HandleModal {...this.props } onClose={this.props.closeModal} />
            </Modal>
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.modals);

export default connect(mapStateToProps, { closeModal })(Modals);