import SecurityModel from '../data/models/security/security';
import history from '../history';
import _ from 'lodash';

//actions
const LOGIN_USER = 'LOGIN_USER';
const REGISTER_USER = 'REGISTER_USER';
const CLOSE_USER_SESSION = 'CLOSE_USER_SESSION';
const GET_USER_PROFILE =  'GET_USER_PROFILE';
const UPDATE_USER_PROFILE  = 'UPDATE_USER_PROFILE';

export const getProfile = () => async dispatch => {

    try {
        
        const response = await SecurityModel.getProfile();
        
        if (response.status === 200) { 

            const role = await SecurityModel.checkJwt();
            const userRole = role.data.user.role;
            let tempUser = _.cloneDeep(response.data);

            if (userRole.indexOf('premium') === -1 && tempUser.config.practicePresentationType === 'video') {

                tempUser.config.practicePresentationType = 'image';

            }

            dispatch ({ type: GET_USER_PROFILE, payload: tempUser });

        }

    } catch (errorCode) {

        dispatch ({ type: GET_USER_PROFILE, payload: {} });
        throw errorCode;

    }

};

export const loginUser = credentials => async dispatch => {

    try {
        
        const response = await SecurityModel.login(credentials);
        
        if (response.status === 200) {

            window.localStorage.setItem('jwt', response.data.jwt);

            const role = await SecurityModel.checkJwt();
            const userRole = role.data.user.role;
            let tempUser = _.cloneDeep(response.data.user);

            if (userRole.indexOf('premium') === -1 && tempUser.config.practicePresentationType === 'video') {

                tempUser.config.practicePresentationType = 'image';
    
            }

            dispatch ({ type: LOGIN_USER, payload: tempUser });

        }

    } catch (errorCode) {

        dispatch ({ type: LOGIN_USER, payload: {} });
        throw errorCode;

    }

};

export const thirdPartyloginUser = loginObject => async dispatch => {

    try {
        
        const response = await SecurityModel.thirdPartyLogin(loginObject);

        if (response.status === 200) {

            window.localStorage.setItem('jwt', response.data.jwt);

            dispatch ({ type: LOGIN_USER, payload: response.data.user });

        }

    } catch (errorCode) {

        dispatch ({ type: LOGIN_USER, payload: {} });
        throw errorCode;

    }

};

export const registerUser = credentials => async dispatch => {

    try {
        
        const response = await SecurityModel.register(credentials);
        
        if (response.status === 200) { 

            window.localStorage.setItem('jwt', response.data.jwt);

            dispatch ({ type: REGISTER_USER, payload: response.data.user });

        }

    } catch (errorCode) {

        dispatch ({ type: REGISTER_USER, payload: {} });
        throw errorCode;

    }

};


export const updateProfile = credentials => async dispatch => {

    try {
        
        const response = await SecurityModel.updateProfile(credentials);
        
        if (response.status === 200) {

            dispatch ({ type: UPDATE_USER_PROFILE, payload: response.data.item });

        }
        
    } catch (errorCode) {

        //! remove dispatch because the userSession go to empty and clean the form profile (nickname casuistry)
        //dispatch ({ type: UPDATE_USER_PROFILE, payload: {} });
        throw errorCode;

    }

};

export const closeSession = () => {

    history.push('/auth/login');

    if(window.localStorage.getItem('jwt')) {

        window.localStorage.removeItem('jwt');

     }
    
    return { type: CLOSE_USER_SESSION, payload: {} } 

}

const INITIAL_STATE = {
    
    userSession: {}

};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case LOGIN_USER:
        case REGISTER_USER:
        case GET_USER_PROFILE:
        case UPDATE_USER_PROFILE:
        case CLOSE_USER_SESSION:
            return { ...state, userSession: action.payload };   
        default:
            return state;
            
    }

};

export default reducer;