//libraries & components libraries
import React, { Component } from 'react'
import { connect } from 'react-redux';

//components
import AdvisorView from '../form/components/advisorView';
import PretorianTutorView from './components/pretorianTutorView';
import PretorianCheckerView from './components/pretorianCheckerView';
import PretorView from './components/pretorView';

//redux actions
import { openModal, closeModal } from '../../../../../../../redux-store/modals';

const HandleView = ({ rol, status, ...rest }) => {

    switch(rol) {

        case 'advisor':

            return <AdvisorView {...rest} canEdit={status === 'assigned'} queryStatus={status} />

        case 'tutor':

            return <PretorianTutorView {...rest} canEdit={status === 'answered'} queryStatus={status} />

        case 'checker':

            return <PretorianCheckerView {...rest} canEdit={status === 'correct-reviewed' || status === 'incorrect-reviewed'} queryStatus={status} />
        
        case 'pretor':

            return <PretorView {...rest} canEdit={status === 'incorrect-checked' } queryStatus={status} />

        default: 

            return '';

    }

}

class QueryForm extends Component {

    render() {
        return (
            <HandleView {...this.props} />
        )
    }
}

export default connect(null, {openModal, closeModal })(QueryForm)