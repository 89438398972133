import ProductModel from '../data/models/product/product';

const GET_STUDIOS =  'GET_STUDIOS';

export const getStudios = () => async dispatch => {

    try {
        
        const studios = await ProductModel.getProducts();
        
        dispatch ({ type: GET_STUDIOS, payload:  studios.data });

        return studios;

    } catch (errorCode) {

        dispatch ({ type: GET_STUDIOS, payload: []});
        throw errorCode;

    }

};

const INITIAL_STATE = {

    data:[]

};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_STUDIOS:
            return { ...state, data: [...action.payload]};   
        default:
            return state;
            
    }

};

export default reducer;