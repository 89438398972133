export const GRAPH_COLORS = [
    '#32D5B9',
    '#31425A',
    '#B6B6B6',
    '#364946',
    '#2E7169',
    '#1CA18C',
    '#32D5B9',
    '#b5dbc7',
    '#469393',
    '#437082',
    '#A0D2DD',
    '#023172',
    '#152236',
]