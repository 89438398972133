import styled from 'styled-components/macro';

const Header = styled.div`

    background:${(props) => props.bgColor || 'var(--dark-grey-4)'};
    padding:32px;
    @media (max-width: 920px) {
        padding:20px;
    }

`;

export {
    Header
}