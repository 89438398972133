import React, { Component } from 'react'
import { InfoBlocks } from '../../../../../../styled/components/commons'
import { InnerContent } from '../../../../../../styled/components/wrappers'
import InnerNavigation from '../../../navigation'
import { closeLoader, openLoader } from '../../../../../../../redux-store/loader'
import { connect } from 'react-redux'
import I18n from '../../../../../../../i18n'
import PracticeTable from './practiceTable'
import StudioTable from './studioTable'

class UserPractices extends Component {
  
    render() {

        return (
            <InnerContent>
                <InnerNavigation active="practices" { ...this.props }/>
                <div style={{marginTop: '32px'}}>
                    <InfoBlocks>
                        <span className="labels">{I18n.t('users.practices')}</span>
                            <PracticeTable {...this.props} />
                    </InfoBlocks>
                    <InfoBlocks>
                        <span className="labels">{I18n.t('users.studios')}</span>
                        <StudioTable {...this.props} />
                    </InfoBlocks>
                </div>
            </InnerContent>
        )
    }
}

export default connect(null, {closeLoader, openLoader})(UserPractices)