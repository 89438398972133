import { Dimmer } from 'semantic-ui-react';
import styled from 'styled-components/macro';

const DimmerWrap = styled(Dimmer)`

    &&&{
        position:fixed;
    }

`;

export {
    DimmerWrap
}