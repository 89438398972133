import { AxiosService } from '../../../utils/AxiosService';

import Config from '../../../config';

const changeEmail = async payload => await AxiosService.post(Config.server.api.securityApi + `changeemail`, payload , true);
const checkJwt = async () => await AxiosService.get(Config.server.api.securityApi + 'check', true);
const getProfile = async () => await AxiosService.get(Config.server.api.securityApi + 'getprofile', true);
const insertUserNotificationToken = async payload => await AxiosService.put(Config.server.api.securityApi + 'advisor/user/notification/token', payload, true);
const recoveryPassword = async payload =>  await AxiosService.post(Config.server.api.securityApi + `recoverypassword/${payload.email}`, {}, false); //step1
const setRecoveryPassword = async payload => await AxiosService.post(Config.server.api.securityApi + `recoverypassword/${payload.token}/set`, {newPassword: payload.newPassword }, false); //step2
const updateProfile = async payload => await AxiosService.post(Config.server.api.securityApi + 'saveprofile', payload, true);
const verifyEmail = async token => await AxiosService.post(Config.server.api.securityApi + `verifyemail/${token}`, {}, false);

const login = async payload => await AxiosService.post(Config.server.api.securityApi + 'advisor/login', payload, false);
const register = async payload => await AxiosService.post(Config.server.api.securityApi + 'advisor/register', payload, false);

const getAssignedAdvisorUserInfos = async userId => await AxiosService.get(Config.server.api.securityApi + `advisor/user/${userId}`, true);
const sendInvitationFromAdvisor = async payload => await AxiosService.post(Config.server.api.securityApi + `advisor/email`, {template: "membershipInvitation", to: payload }, true);
const getAdvisorTeam = async () => await AxiosService.get(Config.server.api.securityApi + `advisor/team`, true);


export default {
    changeEmail,
    checkJwt,
    getProfile,
    insertUserNotificationToken,
    login,
    recoveryPassword,
    register,
    setRecoveryPassword,
    updateProfile,
    verifyEmail,
    getAssignedAdvisorUserInfos,
    getAdvisorTeam,
    sendInvitationFromAdvisor
};