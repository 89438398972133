import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { autofill, Field, reduxForm, updateSyncErrors } from 'redux-form';
import { Form} from 'semantic-ui-react';
import moment from 'moment';

import EditEmailForm from './components/editEmailForm';
import ErrorMessage from '../../../components/errorMessage';
import CustomInput from '../../../components/form/input';
import InputFile from '../../../components/form/inputFile';
import Select from '../../../components/form/select';
import CustomTextArea from '../../../components/form/textarea';

import OButton from '../../../styled/components/button';
import { CorrectMessage, LabelForm } from '../../../styled/components/commons';
import { Col, Row } from '../../../styled/components/wrappers';

import ScheduleTable from './components/scheduleTable';

import { BlockPicture, FormContent, Picture, PictureContent, BlockEmail, StyledToggleCheckbox } from '../styles/styled';

import { countries }  from '../../../../config/constants/countries';

import FilesModel from '../../../../data/models/files/files';
import SecurityModel from '../../../../data/models/security/security';

import { updateProfile } from '../../../../redux-store/auth';
import { openToast } from '../../../../redux-store/toast';
import { openModal, closeModal } from '../../../../redux-store/modals';

import I18n from '../../../../i18n';

import DemoImage from '../../../assets/img/yogabot_icon.svg';

class FormProfile extends Component {

    constructor(props) {

        super(props);

        this.state = {
            modifyEmail: false,
            codeError: -1,
            hasError: false,
            correctEmail: false
        };

    }

    componentWillUnmount() {

        this.props.closeModal();

    }

    onUpdateProfile = async data => {

        if (!_.isEmpty(window['tempFile']) ) {

            let formData = new FormData();
            let tempData = _.cloneDeep(data);
            formData.append('avatar', window['tempFile'].ref);

            try {
                
                const response = await FilesModel.uploadImage(formData);

                if (_.get(response,'data.data.url', false)) {

                    tempData.pictureUrl = response.data.data.url;
                    
                }

            } catch (error) {

                delete tempData.pictureUrl;
                
            } finally {

                this.updateProfile(tempData);

            }

        } else {

            this.updateProfile(data);

        }

    }

    updateProfile = async (data) => {

        const { dispatch, touch } = this.props;
        let hasError = false;

        try {

            if (_.get(data, 'timetable', []) ) {

                _.each(data.timetable, v => {

                    v.ini = moment(v.ini).format();
                    v.end = moment(v.end).format();

                });

            }

            await this.props.updateProfile({ ...data });
            openToast({message: I18n.t('messages.dataSaved'), type: 'success'});
            
        } catch (error) {

            if (error === 404) {

                touch('nickname');
                dispatch(updateSyncErrors('profileForm', {
                    nickname: I18n.t('validations.nicknameAlreadyUsed')
                }));
                
            } else {

                openToast({message: I18n.t(`status.code.${401}`), type: 'error'});

            }

            hasError = true;

        } finally {

            !hasError && (window['tempFile'] = undefined);

        }

    }

    onModifyEmail = () => this.setState(prevState => ({modifyEmail: !prevState.modifyEmail, correctEmail: false, hasError: false}));

    onSaveEmail = async email => {

        try {
             
            const response = await SecurityModel.changeEmail(email);

            if (response && response.status === 200) {

                this.setState({ correctEmail: true, hasError: false });

            }

        } catch (error) {
            
            this.setState({ hasError: true, codeError: error, correctEmail: false });

        } finally {

            this.setState({ modifyEmail: false });

        }

    }

    onRemove = path => {
        
        this.props.dispatch(autofill('profileForm', path, ''));
        window['tempFile'] = undefined;

    }

    onShowAttentionScheduleModal = () => {

        const { openModal, closeModal, reduxFormState, change } = this.props;
        
        openModal({
            type: 'schedule',
            title:{
                text: I18n.t('actions.addSchedule'),
                classes: ['heading-3', 'upper']
            },
            buttons:[{
                text: I18n.t('actions.add'),
                callback: times => {

                    let timetable = [...reduxFormState.timetable, ...times];
                    change('timetable', timetable);
                    closeModal();

                },
                options: {
                    primary: true,
                    color: '#fff',
                    fluid: false,
                    upper: true,
                    regular: true,
                    size: 'small'
                }
            }]
        }, true, 'small');

    }

    removeTime = item => {

        const { reduxFormState, change } = this.props;
        
        const tempTime = _.cloneDeep(reduxFormState.timetable);
        const index = _.findIndex(tempTime, tempTimeItem => _.isEqual(tempTimeItem, item));
        tempTime.splice(index, 1);
        change('timetable', tempTime);

    }

    render() {

        const { modifyEmail, hasError, codeError, correctEmail } = this.state;
        const { userSession, reduxFormState } = this.props;

        const pictureSrc = !_.isEmpty(_.get(window,'tempFile.raw',[])) ? window['tempFile'].raw : !_.isEmpty(reduxFormState.pictureUrl) ? reduxFormState.pictureUrl : DemoImage;

        return (
            <FormContent>
                <BlockEmail>
                    <LabelForm>{I18n.t('auth.email')}</LabelForm>
                    <span style={{marginRight: '10px'}} className="paragraph regular brand-primary-extra-light">{ userSession.email }</span>
                    <OButton primary type="button" color="#fff" regular size="small" onClick={this.onModifyEmail }>
                        <span >{ modifyEmail ? I18n.t('actions.cancel') : I18n.t('actions.editEmail')}</span>
                    </OButton>
                    {
                        modifyEmail && <EditEmailForm initialValues={{email: userSession.email}} onSaveEmail={this.onSaveEmail } />
                    }
                    <ErrorMessage code={codeError} active={hasError} />
                    { correctEmail && 
                        <CorrectMessage>
                            <span className="caption regular white">{I18n.t('messages.changedEmail')}</span>
                        </CorrectMessage>
                    }
                </BlockEmail>
                <BlockPicture>
                    <LabelForm>{I18n.t('profile.profilePicture')}</LabelForm>
                    <PictureContent>
                        <Picture>
                            <img src={ pictureSrc } alt="" onError={(e)=> e.target.src = DemoImage }/>
                        </Picture>
                        <OButton primary type="button" color="#fff" regular size="small" style={{overflow: 'hidden'}}>
                            <span>{_.isEmpty(reduxFormState.pictureUrl) ? I18n.t('actions.addImage') : I18n.t('actions.editImage')}</span>
                            <Field 
                                className="hide-input"
                                component={ InputFile }
                                name="pictureUrl" 
                            />
                        </OButton>
                        { !_.isEmpty(reduxFormState.pictureUrl) && 
                        <OButton  selected type="button" color="#FF6767" regular size="small" onClick={ ()=> this.onRemove('pictureUrl')}>
                            <span>{I18n.t('actions.remove')}</span>
                        </OButton> 
                        }
                    </PictureContent>
                </BlockPicture>
                <Form name="profileForm" noValidate onSubmit={this.props.handleSubmit(this.onUpdateProfile)}>
                    <Row>
                        <Col equal>
                            <Field
                                component={ CustomInput }
                                name='nickname'
                                label={ I18n.t("auth.nickname") }
                                placeholder={ I18n.t("auth.nickname") }
                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col two>
                            <Field
                                component={ CustomInput }
                                placeholder={ I18n.t('profile.name') }
                                name="name"
                                label={ I18n.t('profile.name') } />
                        </Col>
                        <Col two>
                            <Field
                                component={ CustomInput }
                                placeholder={ I18n.t('profile.surname') }
                                name="surname"
                                label={ I18n.t('profile.surname') } />
                        </Col>
                    </Row>
                    <Row>
                        <Col two>
                            <Field
                                component={ Select }
                                name='country'
                                options={countries}
                                search={true}
                                label={ I18n.t("auth.country") } />
                        </Col>
                        <Col two>
                            <Field
                                component={ CustomInput }
                                placeholder={ I18n.t('auth.city') }
                                name="city"
                                label={ I18n.t('auth.city') } />
                        </Col>
                    </Row>
                    <Row>
                        <Col equal>
                            <Field
                                component={ CustomTextArea }
                                name='about'
                                label={ I18n.t("auth.about") }
                                placeholder={ 'Máximo 200 carácteres' }
                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col equal>
                            <Field
                                component={ CustomInput }
                                name='comercialName'
                                label={ I18n.t("auth.yogaCenter") }
                                placeholder={ I18n.t("auth.yogaCenter") } />
                        </Col>
                    </Row>
                    <Row>
                        <Col equal>
                            <Field
                                component={ StyledToggleCheckbox }
                                name='answerDurationDisabled'
                                label={ I18n.t("auth.answerDurationDisabled") }
                                toggle={true}
                               />
                        </Col>
                    </Row>
                    
                    <LabelForm>{I18n.t('profile.attentionTime')}</LabelForm>                    
                    <Field
                        component={ ScheduleTable }
                        list={reduxFormState.timetable}
                        name="timetable"
                        onRemoveTime={ this.removeTime }
                    />
                    <Row>
                        <div style={{width: '100%', textAlign: 'right'}}>
                            <OButton primary type="button" color="#FFF" regular size="mini" onClick={ ()=> this.onShowAttentionScheduleModal()}>
                                <span>{I18n.t('actions.addSchedule')}</span>
                            </OButton> 
                        </div>
                    </Row>
                    <Row>
                        <Col equal>
                            <Field
                                component={ StyledToggleCheckbox }
                                name='offline'
                                label={ I18n.t("auth.tempOffline") }
                                toggle={true}
                               />
                        </Col>
                    </Row>
                    <LabelForm>{I18n.t('profile.rrss')}</LabelForm>
                    <Row>
                        <Col equal>
                            <div>
                                <Field
                                    icon={"instagram"}
                                    component={ CustomInput }
                                    name='ssmm.instagram'
                                    placeholder={I18n.t('profile.addInstagram')}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col equal>
                            <Field
                                icon={"facebook"}
                                component={ CustomInput }
                                placeholder={I18n.t('profile.addFacebook')}
                                name='ssmm.facebook'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col equal>
                            <Field
                                icon={"globe"}
                                component={ CustomInput }
                                placeholder={I18n.t('profile.addWebSite')}
                                name='ssmm.web'
                            />
                        </Col>
                    </Row>
                    <LabelForm>{I18n.t('profile.paypalAccount')}</LabelForm>
                    <Row>
                        <Col equal>
                            <div>
                                <Field
                                    icon={"paypal"}
                                    component={ CustomInput }
                                    name='paypalAccount'
                                    placeholder={I18n.t('profile.addPayPal')}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row flexEnd>
                        <OButton type="submit" primary upper color="#fff" size="big"><span>{I18n.t('actions.saveChanges')}</span></OButton>
                    </Row>
                </Form>
            </FormContent>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.nickname)) {

        errors.nickname = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.nickname) && !/^[\d\w_-]+$/gmi.test(formValues.nickname)) {

        errors.nickname = I18n.t('validations.specialChars');

    }

    if (_.isEmpty(formValues.name)) {

        errors.name = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.surname)) {

        errors.surname = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.city)) {

        errors.city = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.about)) {

        errors.about = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.timetable)) {

        errors.timetable = I18n.t('validations.atLeastOneInterval');

    }

    if (!_.isEmpty(formValues.ssmm) && !_.isEmpty(formValues.ssmm.instagram) && formValues.ssmm.instagram.indexOf('instagram') === -1) {

        _.set(errors, 'ssmm.instagram', I18n.t('validations.wrongUrl'))

    }

    if (!_.isEmpty(formValues.ssmm) && !_.isEmpty(formValues.ssmm.facebook) && formValues.ssmm.facebook.indexOf('facebook') === -1) {

        _.set(errors, 'ssmm.facebook', I18n.t('validations.wrongUrl'))

    }

    if (!_.isEmpty(formValues.paypalAccount) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.paypalAccount)) {

        errors.paypalAccount = I18n.t('validations.emailInvalid');
        
    }

    return errors;

};

FormProfile = connect(state => {

    const reduxFormState = _.cloneDeep(_.get(state, 'form.profileForm.values', {}));

    return {
        reduxFormState
    };

})(FormProfile);

export default reduxForm({
    form: 'profileForm',
    touchOnBlur: true,
    touchOnChange: true,
    enableReinitialize:true,
    validate
})(connect(null, { updateProfile, openToast, openModal, closeModal })(FormProfile));
