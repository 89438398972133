import React from 'react';

import SearchHeader from './components/searchHeader';
import SimpleHeader from './components/simpleHeader';

const HandleType = props => {
    
    switch(props.type) {

        case 'search':

            return <SearchHeader { ...props } />
        
        case 'simple':

            return <SimpleHeader { ...props } />

        default:

            return ''

    }

}

export default props => <HandleType {...props} />;