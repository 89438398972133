import styled from 'styled-components/macro';

const FrameContainer = styled.div`

    display:flex;
    width:100%;
    @media only screen and (max-width: 1110px) {
        flex-direction:column;
     }
`;

const FrameIndicator = styled.div`

    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    align-self: center;
    
`;

const MatrixBodies = styled.div`

    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    display:flex;

`;

const MatrixContainer = styled.div`

    display:flex;
    margin-top:20px;
    &{
        .shape {
            height:500px;
            flex: 1;
            margin: 0 10px;
            svg {
                width:100%;
                height:100%;
            }
            @media only screen and (max-width: 1110px) {
                height:400px;
             }
             @media only screen and (max-width: 420px) {
                height:auto;
             }
        }
    }
`;

export {
    FrameContainer,
    FrameIndicator,
    MatrixBodies,
    MatrixContainer
}