import styled from 'styled-components/macro';


const ErrorBlock = styled.div`

    background: var(--red-color);
    display: block;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    a{
        color: white!important;
        text-decoration: underline;
    }
    
`;

export {
    ErrorBlock
}