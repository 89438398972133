import styled, { css } from 'styled-components/macro';

const Container = styled.div`
    display:flex;
    min-height:100%;
    flex-direction:column;
    ${({fixedTopBar}) => fixedTopBar &&
        css`
            padding-top:80px;
        `};
`;

const Inner = styled.div`
    display:flex;
    flex:1 0 auto;
    flex-direction:row;
    
`;

const Content = styled.div`
    flex:1;
    background:white;
`;

const InnerContent = styled.div`

    width:100%;
    max-width:1440px;
    margin:0 auto;
    padding:20px;
`;

const Row = styled.div`

    display:flex;
    flex-wrap:wrap;
    justify-content: ${props => props.flexEnd ? "flex-end" : "space-between"};
    margin-bottom:30px;
    @media (max-width:768px) {
        flex-direction:column;
        margin-bottom:0px;
    }
`;

const Col = styled.div`

    ${({equal}) => equal &&
        css`
            flex-grow:0;
            flex-shrink:1;
            flex-basis:100%;
            min-width:0;
        `};

    ${({two}) => two &&
        css`
            flex-grow:0;
            flex-shrink:1;
            flex-basis:48%;
            min-width:0;
        `};

    ${({three}) => three &&
        css`
            flex-grow:0;
            flex-shrink:1;
            flex-basis:32%;
            min-width:0;
        `};

    ${({fourth}) => fourth &&
        css`
            flex-grow:0;
            flex-shrink:1;
            flex-basis:23%;
            min-width:0;
        `};

    ${({growed}) => growed &&
        css`
            flex-grow:1;
            flex-shrink:1;
            flex-basis:0;
            min-width:0;
        `};

    ${({flex}) => flex &&
        css`
            display:flex;
            min-width:0;
        `};

    ${({auto}) => auto &&
        css`
            flex: 0 0 auto;
            width: auto;
            max-width: none;
        `};

    ${({aicenter}) => aicenter &&
        css`
            align-items:center;
        `};

    ${({jccenter}) => jccenter &&
        css`
            justify-content:center;
        `};

    @media (max-width:768px) {
        margin-bottom:30px;
    }

`;

const Block = styled.div`

    ${({flex}) => flex &&
        css`
            display:flex;
        `};
    ${({wrapped}) => wrapped &&
        css`
            flex-wrap:wrap;
        `};
    ${({aicenter}) => aicenter &&
        css`
            align-items:center;
        `};
    ${({jccenter}) => jccenter &&
        css`
            justify-content:center;
        `};
    ${({between}) => between && 
    css`
        justify-content:space-between;
    `}
`;


export {
    
    Inner,
    Container,
    Content,
    InnerContent,
    Row,
    Col,
    Block

};