import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const getStatsAdvisorUserCommission = async () => await AxiosService.get(Config.server.api.statsApi + 'advisor/user/commission', {}, true);
const getStatsAdvisorUserQuery = async () => await AxiosService.get(Config.server.api.statsApi + 'advisor/user/query', {}, true);
const getStatsAdvisorUserQueryDenies = async () => await AxiosService.get(Config.server.api.statsApi + 'advisor/user/query/denied', {}, true);
const getStatsAdvisorUser = async () => await AxiosService.get(Config.server.api.statsApi + 'advisor/user', {}, true);
const getExchange = async currency => await AxiosService.get(Config.server.api.statsApi + `exchange/rate/${currency}`, {}, true);

export default {
    getStatsAdvisorUserCommission,
    getStatsAdvisorUserQuery,
    getStatsAdvisorUserQueryDenies,
    getStatsAdvisorUser,
    getExchange
};