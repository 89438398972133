import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { Aside, AsideItem, Text } from "./styles/styled";

import { ROLES } from '../../../config/constants/roles';

import I18n from '../../../i18n';

function AsideMenu({ advisorVerified, history, active, role }) {
    
    return advisorVerified ? (
        <Aside>
            <AsideItem active={ active === "resume" } onClick={ () => history.push('/resume') }>
                <Text className="upper">
                    { I18n.t('navigation.resume') }
                </Text>
            </AsideItem>
            <AsideItem active={ active === "queries" } onClick={ () => history.push('/queries') }>
                <Text className="upper">
                    { I18n.t('navigation.myQueries') }
                </Text>
            </AsideItem>
            <AsideItem active={ active === "users" } onClick={ () => history.push('/users') }>
                <Text className="upper">
                    { I18n.t('navigation.myUsers') }
                </Text>
            </AsideItem>
            { role.includes(ROLES.PRETORIAN) && 
                <AsideItem active={ active === "myTeam" } onClick={ () => history.push('/myTeam') }>
                    <Text className="upper">
                        { I18n.t('navigation.myTeam') }
                    </Text>
                </AsideItem>
            }
        </Aside>
    ) : null;
    
}

const mapStateToProps = state => {

    return {
        advisorVerified: _.get(state, 'role.advisorVerified', {}),
        role: _.get(state, 'role.role', {}),
    };
    
};

export default connect(mapStateToProps, {})(AsideMenu);