
import React, { useState } from 'react';
import _ from 'lodash';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { FrameClose, FrameModal, ModalContent } from '../../sharedStyles';
import OButton from '../../../../styled/components/button';
import { Icon, Input } from 'semantic-ui-react';
import { Block, CircleWrap, BlockInput, BlockAccumulator, BlockLink } from './styles';
import { LabelForm } from '../../../../styled/components/commons';

import I18n from '../../../../../i18n';

/***
 * * SHARED DATA ACCEPT MODAL
 * 
 * @param buttons Array Objects
 * @param onClose Function
 * @param title String
 * @param subtitle String
 * @param description String 
 * @params @buttons { text, callback, options = { any button props } }
 * @description Modal to ask to the user to accept the biometric matrix data required for the adivsor
 */
export default function InvitationModal({ title = {}, subtitle = {}, description = {}, button = {}, onClose, ...restProps }) {

    const [emails, onAddEmail] = useState([]);
    const [value, onChangeValue] = useState('');
    const [copied, onCopyLink] = useState(false);

    const addEmail = email => {

        let tempEmails = _.cloneDeep(emails);
        tempEmails.push(email);
        onAddEmail(tempEmails);
        onChangeValue('');

    }

    const onRemoveEmail = index => {

        let tempEmails = _.cloneDeep(emails);
        tempEmails.splice(index, 1);
        onAddEmail(tempEmails);

    }


    const onSave = callback => {

        if (typeof callback === 'function') {

            callback(emails);
            
        }

    }

    const linkValue = 'https://yogabot.app/#/?ref=' + restProps.nickname;

    return (

        <FrameModal>
            <FrameClose>
                <Icon name="close" size="large" onClick={ onClose }/>
            </FrameClose>
            <ModalContent>
                {!_.isEmpty(title) && <p className={title.classes.join(" ")} >{title.text}</p>}
                <Block>
                    <LabelForm><CircleWrap><Icon name="plus"  size="small" /></CircleWrap>{I18n.t('actions.sendInvitation')}</LabelForm>
                    <BlockInput>
                        <Input value={value} type="text" placeholder="Escribe el correo aqui" onChange={(e) => onChangeValue(e.target.value)}/>
                        <OButton type="button" secondary regular upper onClick={() => addEmail(value)}><span>{I18n.t('actions.add')}</span></OButton>
                    </BlockInput>
                    {
                        _.map(emails, (email, index) => (
                            <BlockAccumulator key={index}>
                                <span className="caption regular">{email}</span>
                                <Icon name="trash alternate" size="large" onClick={()=> onRemoveEmail(index)}/>
                            </BlockAccumulator>
                        ))
                    }
                </Block>
                <Block>
                    <OButton disabled={emails.length < 1} type="button" {...button.options} onClick={ () => onSave(button.callback) }><span>{button.text}</span></OButton>
                </Block>
                <Block>
                    <LabelForm>
                        <CircleWrap>
                            <Icon name="chain" size="small" />
                        </CircleWrap>
                        {I18n.t('actions.obtainLink')}
                    </LabelForm>
                    <BlockLink>
                        <span className="caption regular" style={{marginBottom:'10px'}}>{I18n.t('modals.invitationModal.text1')}</span>
                        <CopyToClipboard text={linkValue}
                            onCopy={() => onCopyLink(true)}>
                            <span className="caption regular brand-secondary c-pointer">{I18n.t('actions.copyLink')}</span>
                        </CopyToClipboard>
                        
                    </BlockLink>
                </Block>
                <Block>
                    <Input fluid value={linkValue} type="text" readOnly />
                </Block>
                <Block>
                    {copied && <span className="caption regular">{I18n.t('actions.copiedLink')}</span>}
                </Block>
            </ModalContent>
        </FrameModal>
    )

}
