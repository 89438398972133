import _ from 'lodash';
import React, { Component } from 'react';
import I18n from '../../../../../i18n';
import RtdGraphView from './view';

class RtdGraphContainer extends Component {


    constructor(props) {
        super(props);

        this.state = {
            transformedData:[]
        }

    }
    componentDidMount() {

        this.transformData();

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.data, this.props.data)) {
            
            this.transformData();

        }
    
    }
    
    transformData = () => {

        const { enviroment } = this.props;

        switch(enviroment) {

            case 'commissions':
                this.transformCommisionsData();
                break;
            case 'accountType':
                this.transformAccountType();
                break;
            default:
                return null;
        }

    }

    transformAccountType = () => {

        let transformedData = [];

        const { data } = this.props;

        let freeAccount = {
            category: I18n.t('resumen.free'),
            value: 0
        }
        let trialAccount = {
            category: I18n.t('resumen.trial'),
            value: 0
        }
        let monthlyPremiumAccount = {
            category: I18n.t('resumen.pAnnual'),
            value: 0
        }
        let anualPremiumAccount = {
            category: I18n.t('resumen.pMonthly'),
            value: 0
        }

        _.each(data, ({premiumType}) => {

            if (premiumType === 'lite') {

                freeAccount.value++;

            }

            if (trialAccount === 'trial') {

                trialAccount.value++;
                
            }

            if (premiumType === 'annualPremium') {

                monthlyPremiumAccount.value++;
                
            }

            if (premiumType === 'monthlyPremium') {

                anualPremiumAccount.value++;
                
            }

        });

        transformedData.push(freeAccount, trialAccount, monthlyPremiumAccount, anualPremiumAccount);

        this.setState({transformedData});
        
    }

    transformCommisionsData = () => {

        const { data, exchangeEUR } = this.props;
        let advisorCommisions = [];
        let transformedData = [];
        let queries = [];
        let asanas = [];
        let subscription = [];

        advisorCommisions = _.filter(data, ({type}) => ['advisor','affiliation'].includes(type));

        let exchange = {
            EUR: exchangeEUR,
            USD: 1
        }
        
        //obtener consultas => filtro por concepto => agrupacion por currency => output => { EUR : [ ARRAY]} => map por cada key currency para un output de { category: '', value : sumTotal de cada valor de amount }
        queries = 
        _.chain(advisorCommisions)
            .filter(({concept}) => ['answer'].includes(concept))
                .map(item => {
                    return {
                        ...item,
                        amount: item.amount * exchange[item.currency],
                        currency: 'USD'
                    }
                })
                .groupBy('currency')
                    .map((values, currency) => {
                        return {
                            category: I18n.t('resumen.queries'),
                            value: _.reduce(values, (accumulator, cur) => accumulator + cur.amount, 0)
                        }
                }).value();

        transformedData.push(...queries);
        
        //obtener consultas => filtro por 'annual-subscription' o 'monthly-subscription'  => agrupacion por currency => output => { EUR : [ ARRAY]} => map por cada key currency para un output de { category: '', value : sumTotal de cada valor de amount }

        subscription = 
        _.chain(advisorCommisions)
            .filter(({concept}) => ['annual-subscription', 'monthly-subscription'].includes(concept))
            .map(item => {
                return {
                    ...item,
                    amount: item.amount * exchange[item.currency],
                    currency: 'USD'
                }
            })
            .groupBy('currency')
                .map((values, currency) => {
                    return {
                        category: I18n.t('resumen.subscription'),
                        value: _.reduce(values, (accumulator, cur) => accumulator + cur.amount, 0)
                    }
                }).value();

        transformedData.push(...subscription);

        //obtener consultas => filtro por 'school-product' => agrupacion por currency => output => { EUR : [ ARRAY]} => map por cada key currency para un output de { category: '', value : sumTotal de cada valor de amount }

        asanas = 
        _.chain(advisorCommisions)
            .filter(({concept}) => ['school-product'].includes(concept))
            .map(item => {
                return {
                    ...item,
                    amount: item.amount * exchange[item.currency],
                    currency: 'USD'
                }
            })
            .groupBy('currency')
                .map((values, currency) => {
                    return {
                        category: I18n.t('resumen.asanas'),
                        value: _.reduce(values, (accumulator, cur) => accumulator + cur.amount, 0)
                    }
                }).value();

        transformedData.push(...asanas);

        this.setState({transformedData});

    }

    render() {

        const { transformedData } = this.state;

        return (
            <RtdGraphView data={transformedData} />
        );
    }
}

export default RtdGraphContainer;