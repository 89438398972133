import Promise from 'bluebird';
import _ from 'lodash';

const asyncForEach = async (array, callback) => {

    for (let index = 0; index < array.length; index++) {

        await callback(array[index], index, array);

    }
    
};

const asyncMap = async (array, callback) => {

    var arrayOfPromises = array.map(async item => {
        return await callback(item);
    });
    
    return Promise.all(arrayOfPromises);

};

const checkIfNotEqualAndNotEmpty = (ob1, ob2) => (!_.isEmpty(ob1) && !_.isEmpty(ob2) && !_.isEqual(JSON.stringify(ob1), JSON.stringify(ob2)));

export default { asyncForEach, asyncMap, checkIfNotEqualAndNotEmpty };