import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import I18n from '../../../i18n';
import { ErrorBlock } from './styles/styled';

const ErrorMessage = (props) => {

    const [message, setMessage] = useState('');

    useEffect(()=> {

        switch(props.code) {

            case 'error-login':
                setMessage(<React.Fragment><p>Credenciales incorrectas. Vuelve a intentarlo o <Link to="/auth/recovery/step/1">restablece tu contraseña.</Link></p></React.Fragment>)
                break;
            case 'error-recovery':
                setMessage(<React.Fragment><p>Ha ocurrido un error, inténtelo de nuevo y si el problema persiste contacte con soporte.</p></React.Fragment>)
                break;
            case 'register-repeat-email':
                setMessage(<React.Fragment><p>El email ya está en uso.</p></React.Fragment>)
                break;
            case 409:
                setMessage(<p>{I18n.t(`status.code.${props.code}`)}</p>)
                break;
            default:
                setMessage('Se ha producido un error inesperado, por favor vuelve a intentarlo')

        }

    },[props.code]);

    return (
        <React.Fragment>
            {props.active && <ErrorBlock className={"verticalPad " + (props.classes || '')}>
                <span className="caption regular white">{message}</span>
            </ErrorBlock>}
        </React.Fragment>
    );

}

export default ErrorMessage;