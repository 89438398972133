//libraries
import React, { Component } from 'react';
import _ from 'lodash';
import arrayMove from 'array-move';
import { autofill, Field } from 'redux-form';
import I18n from '../../../../../../../../../i18n';

//components libraries
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Icon } from 'semantic-ui-react';

//styled components
import { Picture, PictureBlock, YRow, YTable } from '../../../../../../../../styled/components/tables';
import { ScrollBlock } from '../../../../styles';
import { FlexWrap } from '../../../../../../../../styled/components/commons';
import OButton from '../../../../../../../../styled/components/button';

//components
import CustomInput from '../../../../../../../../components/form/input';
import Select from '../../../../../../../../components/form/select';

//utils
import { intervalWarning, required } from '../../../../../../../../../config/validations';


const DragHandle = SortableHandle(() => <Icon name='arrows alternate' />);

const SortableList = SortableContainer(({items, reduxFormState, asanas, onSpreadAsana, onRemoveAsana, onRedoneAsana, advancedAsanasIds, routineKey, canEdit }) => (
    <YTable stackable padded='very' selectable style={{marginTop:'20px'}}>
        <YTable.Header>
            <YRow>
                <YTable.HeaderCell></YTable.HeaderCell>
                <YTable.HeaderCell>{I18n.t('queries.advisor.asana')}</YTable.HeaderCell>
                <YTable.HeaderCell>{I18n.t('queries.advisor.name')}</YTable.HeaderCell>
                {canEdit && <YTable.HeaderCell>{I18n.t('queries.advisor.spread')}</YTable.HeaderCell>}
                <YTable.HeaderCell>{I18n.t('queries.advisor.duration')}</YTable.HeaderCell>
                {canEdit && <YTable.HeaderCell>{I18n.t('actions.action')}</YTable.HeaderCell>}
            </YRow>
        </YTable.Header>
        <YTable.Body>
            { items.map((asana, index) => <SortableItem canEdit={canEdit} routineKey={routineKey} disabled={!asana.added || !canEdit } key={ `item-${ index }` } index={ index } idx={ index } value={ asana } advancedAsanasIds={advancedAsanasIds} reduxFormState={reduxFormState} asanas={asanas} onSpreadAsana={onSpreadAsana} onRemoveAsana={onRemoveAsana} onRedoneAsana={onRedoneAsana} />)}
        </YTable.Body>
    </YTable>
));

const SortableItem = SortableElement(({ value, idx, reduxFormState, asanas, advancedAsanasIds, onSpreadAsana, onRemoveAsana, onRedoneAsana, routineKey, canEdit }) => (
    <YRow 
        toYellow={ !_.isEqual(_.parseInt(reduxFormState[routineKey][idx].duration), _.parseInt(value.originalDuration))}
        toRed={value.deleted || value.deconstructed }
        toGreen={value.added || value.deconstructedChild}>
        <YTable.Cell>
            {value.added && <DragHandle /> }
        </YTable.Cell>
        <YTable.Cell>
            <PictureBlock>
                <Picture square>
                    <img src={asanas[value._id].image} alt="" />
                </Picture>
            </PictureBlock>
        </YTable.Cell>
        <YTable.Cell>
            <span>{asanas[value._id].name.ES}</span>
        </YTable.Cell>
        {canEdit && <YTable.Cell textAlign='center'>

            {!value.added && <Icon name="sitemap" onClick={() => (!_.isEmpty(value.compatibleAsanas) && !value.deconstructed) && onSpreadAsana(idx) } size="large" className={"c-pointer " + ( (_.isEmpty(value.compatibleAsanas) || value.deconstructed) ? 'brand-primary-extra-light' : 'brand-secondary')}/>}
            {value.added && 
                <Field
                    component={ Select }
                    name={`[${routineKey}][${idx}]._id`}
                    disabled={value.deleted}
                    search
                    options={_.map(advancedAsanasIds, id => {

                        return {

                            key: id,
                            text: asanas[id].name.ES,
                            value: id,
                            image : { 
                                avatar : true,
                                src    :  asanas[id].image,
                            }
                            
                        }

                    })}
                    label={ '' }
                    placeholder={ I18n.t("queries.advisor.duration") }

                />
            }
            
        </YTable.Cell>}
        <YTable.Cell>
            <Field
                component={ CustomInput }
                name={`[${routineKey}][${idx}].duration`}
                label={ '' }
                placeholder={ I18n.t("queries.advisor.duration") }
                disabled={value.deleted || value.deconstructed || !canEdit}
                restrictions={ [{ numeric: true }] }
                validate={canEdit ? [required] : []}
                warn={(canEdit && !(value.originalDuration > 120 || value.originalDuration < 15) ) ? intervalWarning : null}
                />
        </YTable.Cell>
        {canEdit && <YTable.Cell textAlign='center'>

            {/* Control para borrar asanas normales como asanas añadidas //Si deleted es falso y la duracion son la misma se puede eliminar => se comprueba si es added o no para ser un borrado logico de las added sino se pone delete a true para mostrar el icon redone */}
            {( !value.deleted && !value.deconstructedChild && !value.deconstructed && (_.isEqual(_.parseInt(reduxFormState[routineKey][idx].duration), _.parseInt(value.originalDuration)) || value.added)) && <><Icon name="trash" size="large" className="c-pointer brand-error" onClick={() => onRemoveAsana(value, idx) }/></>}
            
            {/* Si no es added y la duraciones no son iguales se muestra icono de redone */}
            { !value.added && !value.deconstructedChild && (value.deconstructed || value.deleted || !_.isEqual(_.parseInt(reduxFormState[routineKey][idx].duration), _.parseInt(value.originalDuration))) && <><Icon name="undo" size="large" className="c-pointer" onClick={() => onRedoneAsana(idx) }/></>}
            
        </YTable.Cell>}
    </YRow>
));

export default class SequenceBuilder extends Component {

    constructor(props) {

        super(props);
        this.scrollRef = React.createRef();

    }

    onSortEnd = ({oldIndex, newIndex}) => {

        const { dispatch, formName, routineKey } = this.props;

        let tempState = { ...this.props.reduxFormState };
        tempState[routineKey] = arrayMove(tempState[routineKey], oldIndex, newIndex);
        dispatch(autofill(formName, routineKey, tempState[routineKey]));

    };

    onAddAsana = () => {

        const { initialValues, reduxFormState, formName, routineKey, dispatch } = this.props; 

        let tempRoutine = _.cloneDeep(reduxFormState[routineKey]);

        let asanaToAdd = {
            duration: 30,
            originalDuration: 30,
            _id: initialValues[routineKey][0]._id,
            compatibleAsanas: [],
            transitionVideos: [],
            added: true,
            deleted: false,
            deconstructed: false,
            code: initialValues[routineKey][0].code
 
        }

        tempRoutine.push(asanaToAdd);

        dispatch(autofill(formName, routineKey, tempRoutine));

        let that = this;
        
        setTimeout(() => {
            
            that.scrollRef.current.scrollTop = that.scrollRef.current.scrollHeight;

        }, 1000);
       
    }

    onSpreadAsana = idx => {

        const { reduxFormState, asanas, formName, routineKey, dispatch } = this.props;

        let tempRoutine = _.cloneDeep(reduxFormState[routineKey]);

        let deconstructedChilds = [];

        tempRoutine[idx].deconstructed = true;

        _.each(tempRoutine[idx].compatibleAsanas, asanaId => {

            deconstructedChilds.push({
                duration: tempRoutine[idx].duration,
                originalDuration: tempRoutine[idx].duration,
                _id: asanaId,
                compatibleAsanas: [],
                transitionVideos: [],
                added: false,
                deleted: false,
                deconstructed: false,
                uniqId: null,
                deconstructedChild: tempRoutine[idx].uniqId,
                code: asanas[asanaId].code

            })

        });

        tempRoutine.splice(idx + 1, 0, ...deconstructedChilds);

        dispatch(autofill(formName, routineKey, tempRoutine));

    }

    onRemoveAsana = (asana, idx) => {

        const { reduxFormState, formName, routineKey, dispatch } = this.props;

        let tempRoutine = _.cloneDeep(reduxFormState[routineKey]);

        if (asana.added || asana.deconstructedChild) {

            //eliminar físicamente
            tempRoutine.splice(idx, 1);
            dispatch(autofill(formName, routineKey, tempRoutine));

        } else {

            //eliminar logicamente
            tempRoutine[idx].deleted = true;
            dispatch(autofill(formName, routineKey, tempRoutine));

        }

    }

    onRedoneAsana = idx => {

        const { reduxFormState, formName, routineKey, dispatch } = this.props;

        let tempRoutine = _.cloneDeep(reduxFormState[routineKey]);
        
        tempRoutine[idx].deleted = false;
        tempRoutine[idx].duration = tempRoutine[idx].originalDuration;

        if (tempRoutine[idx].deconstructed) {

            tempRoutine[idx].deconstructed = false;

            tempRoutine = _.filter(tempRoutine, routine => routine.deconstructedChild !== tempRoutine[idx].uniqId );

        }

        dispatch(autofill(formName, routineKey, tempRoutine));
        
    }

    onRestoreRoutine = () => {

        const { reduxFormState, routineKey, dispatch, formName } = this.props;

        let tempRoutine = _.cloneDeep(reduxFormState[routineKey]);

        //Hago chain porque pueden existir added a false con deconstructedChild
        tempRoutine = _
            .chain(tempRoutine)
            .filter(item => !item.added)
            .filter(item => !item.deconstructedChild)
            .each(item => {

                item.deleted = false;
                item.added = false;
                item.deconstructed = false;
                item.duration = item.originalDuration;

            }).value();

        dispatch(autofill(formName, routineKey, tempRoutine));

    }

    onConfirmRestoreRoutine = () => {

        const { openModal, closeModal } = this.props;

        openModal({
            type: 'simple',
            title:{
                text: I18n.t('messages.alert'),
                classes: ['heading-2', 'upper']
            },
            description:{
                text: I18n.t('messages.restoreRoutine'),
                classes: ['labels', 'regular']
            },
            buttons:[
                {
                    text: I18n.t('actions.cancel'),
                    callback: ()=> {

                        closeModal();

                    },
                    options: {
                        secondary: true,
                        fluid: false,
                        upper: true
                        
                    }
                },
                {
                    text: I18n.t('actions.confirm'),
                    callback: ()=> {

                        closeModal();
                        this.onRestoreRoutine();

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: false,
                        upper: true
                        
                    }
                }
            ]
        }, true, 'mini');

    }

    render() {

        const { reduxFormState, asanas, advancedAsanasIds, canEdit, routineKey } = this.props;

        return (
            <>
                <ScrollBlock ref={this.scrollRef}>
                    <SortableList 
                        helperClass="dragging-helper-class" 
                        useDragHandle 
                        items={ _.get(reduxFormState, `[${routineKey}]`, []) } 
                        onSortEnd={ this.onSortEnd } 
                        reduxFormState={reduxFormState}
                        asanas={asanas}
                        advancedAsanasIds={advancedAsanasIds}
                        onSpreadAsana={(value, asanaId) => this.onSpreadAsana(value, asanaId)}
                        onRemoveAsana={(value, idx) => this.onRemoveAsana(value, idx)}
                        onRedoneAsana={(idx) => this.onRedoneAsana(idx)}
                        routineKey={routineKey}
                        canEdit={canEdit}
                    />  
                </ScrollBlock>
                {canEdit && 
                    <FlexWrap fend>
                        <OButton type="button" secondary upper size="small" onClick={this.onConfirmRestoreRoutine} style={{marginRight:'10px'}}>
                            <span>{I18n.t('actions.restoreRoutine')}</span>
                        </OButton>
                        <OButton type="button" primary upper color="#fff" size="small" onClick={this.onAddAsana}>
                            <span>{I18n.t('actions.addAsana')}</span>
                        </OButton>
                    </FlexWrap> 
                }

            </>

        )

    }

}