//libraries
import _ from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import I18n from '../../../../../../../../../i18n';

//libraries components
import { Form, Message} from 'semantic-ui-react';

//components
import CustomTextArea from '../../../../../../../../components/form/textarea';
import SequenceBuilder from '../sequenceBuilder';

//styled components
import { Col, InnerContent, Row } from '../../../../../../../../styled/components/wrappers';
import { CheckboxBlock, Sections, SelectBlock, TextBlock, ScrollBlock, FlexibleBlock, StudioBlock, PracticeBlock, FlexibleRow, FieldAsInput, styledCheckboxList } from '../../../../styles';
import { FlexWrap, YTab } from '../../../../../../../../styled/components/commons';
import OButton from '../../../../../../../../styled/components/button';

//models
import QuestionModel from '../../../../../../../../../data/models/questions/questions';

//utils
import { maxLength } from '../../../../../../../../../config/validations';
import Utils from  '../../../../../../../../../utils';

//redux actions
import { openModal, closeModal } from '../../../../../../../../../redux-store/modals';

const maxLength300 = maxLength(300);

class PretorianTutorView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDisabled: true,
            activePaneIndex: 0,
            routineKey: "advisorRoutine"
        }

    }

    onSavePretorianTutorFeedback = async data => {

        const { openModal, closeModal, match: { params: { queryId } }, openLoader, closeLoader, history: { push }} = this.props;
        const { isDisabled } = this.state;
        let hasError = false;
        const requiredFields = ['fixing', 'tutorSuggestions', 'tutorSequenceSuggestions', 'tutorSchoolSuggestions', 'tutorRestrictions', 'tutorToPowered', 'tutorSummary', 'tutorRoutine', 'tutorMessageToAdvisor', 'tutorMessageToStudent'];

        try {

            openLoader();
            data.fixing = !isDisabled;
            await QuestionModel.pretorianTutorAnswer(queryId, _.pick(data, requiredFields));

        } catch (error) {

            console.error(error);
            hasError = true;
            
        } finally {

            closeLoader();

            if (hasError) {

                openModal({
                    type: 'simple',
                    title:{
                        text: I18n.t('messages.alert'),
                        classes: ['heading-2', 'upper']
                    },
                    description:{
                        text: I18n.t('messages.commonError'),
                        classes: ['labels', 'regular']
                    },
                    buttons:[
                        {
                            text: I18n.t('actions.understood'),
                            callback: ()=> {

                                closeModal();

                            },
                            options: {
                                primary: true,
                                color: '#fff',
                                fluid: false,
                                upper: true
                                
                            }
                        }
                    ]
                }, true, 'mini');

            } else {

                push('/queries');

            }
            
        }

    }

    componentWillUnmount() {

        const { closeModal } = this.props;

        closeModal();

    }

    enableFields = () => {
        
        const { openModal, closeModal } = this.props;

        openModal({
            type: 'simple',
            title:{
                text: I18n.t('messages.alert'),
                classes: ['heading-2', 'upper']
            },
            description:{
                text: I18n.t('messages.correctQuery'),
                classes: ['labels', 'regular']
            },
            buttons:[
                {
                    text: I18n.t('actions.cancel'),
                    callback: ()=> {

                        closeModal();

                    },
                    options: {
                        secondary: true,
                        fluid: false,
                        upper: true
                        
                    }
                },
                {
                    text: I18n.t('actions.understood'),
                    callback: ()=> {

                        closeModal();
                        this.setState({isDisabled: false})

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: false,
                        upper: true
                        
                    }
                }
            ]
        }, true, 'mini');

    
    }

    getStudios = target => {

        const { initialValues, mappedStudios } = this.props;

        let studios = [];

        if (initialValues[target]) {

            _.each(initialValues[target], studioId => {

                if (mappedStudios[studioId]) {

                    studios.push(mappedStudios[studioId]);

                }

            });

        }

        return studios.join(', ');

    }

    getSequences = target => {

        const { initialValues, mappedAlternativePractices } = this.props;

        let sequences = [];

        if (initialValues[target]) {

            _.each(initialValues[target], secId => {

                if (mappedAlternativePractices[secId]) {

                    sequences.push(mappedAlternativePractices[secId]);

                }

            });

        }

        return sequences.join(', ');

    }

    handleTabChange = (e, { activeIndex, panes }) => {
        
        let routineKey = "";

        switch(panes[activeIndex].menuItem.key) {

            case 'tutor':
                routineKey = "tutorRoutine";
                break;
            case 'nexoyoga':
                routineKey = "routine";
                break;
            default: 
                routineKey = "advisorRoutine";

        }
        
        this.setState({ activePaneIndex: activeIndex, routineKey });
    
    }

    buildPanes = () => {
        
        const { initialValues, queryStatus, canEdit } = this.props;
        const { isDisabled } = this.state;
        const isCorrectCheckedOrEnded = (queryStatus === 'correct-checked' || queryStatus === 'ended');

        let panes = [{
            menuItem: { key: 'advisor', content: I18n.t('queries.advisor.routineAdvisor')}
        }];

        //evitar que pueda editar cuando tiene permisos de editar el nodo de advisorRoutine, el de routine no lo deberia poder editar porque estaría sólo visible en los tabs si el estado 
        //es correct checked or ended por lo que no sería un estado que permitar editar el role de tutor 

        if ( (canEdit && !isDisabled) || Utils.checkIfNotEqualAndNotEmpty(initialValues.advisorRoutine, initialValues.tutorRoutine)) {

            panes.push({menuItem: { key: 'tutor', content: I18n.t('queries.advisor.routineTutor')} });

        }

        if (isCorrectCheckedOrEnded && Utils.checkIfNotEqualAndNotEmpty(initialValues.tutorRoutine, initialValues.routine)) {

            panes.push({menuItem: { key: 'nexoyoga', content: I18n.t('queries.advisor.routinePretor')} });

        }

        return panes;

    }

    render() {

        const { studios, alternativePractices, canEdit, queryStatus, initialValues, reduxFormState, asanas, advancedAsanasIds, invalid } = this.props;

        const { isDisabled, activePaneIndex, routineKey } = this.state;

        const isCorrectCheckedOrEnded = (queryStatus === 'correct-checked' || queryStatus === 'ended');

        return (
            <InnerContent>
                <Form name="pretorianTutorForm" noValidate onSubmit={this.props.handleSubmit(this.onSavePretorianTutorFeedback)}>

                    {/*/*************************************************************SUMMARY**************************************************************/}
                {/*/*************************************************************SUMMARY ADVISOR**************************************************************/}

                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.summaryAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorSummary'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                             />
                        </Col>
                    </Row>

                {/*/*************************************************************SUMMARY TUTOR**************************************************************/}

                { (!isDisabled || !_.isEqual(initialValues.advisorSummary, initialValues.tutorSummary) ) && <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.yourSummary')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorSummary'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit || isDisabled}
                                validate={(canEdit && !isDisabled) ? [maxLength300] : []}
                             />
                        </Col>
                    </Row>}

                    {/*/*************************************************************SUMMARY PRETOR**************************************************************/}
                    
                    { isCorrectCheckedOrEnded && !_.isEqual(initialValues.summary, initialValues.tutorSummary) && 

                        <Row>
                            <Col equal>
                                <TextBlock>
                                    <span className="heading-3 asDisabled">{I18n.t('queries.advisor.summaryPretor')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='summary'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                    }
                        {/*/*************************************************************RESTRICCTIONS ADVISOR**************************************************************/}

                    <Row>

                        <Col two>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.restrictionsAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorRestrictions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                             /> 
                        </Col>

                        {/*/*************************************************************RESTRICCTIONS TUTOR**************************************************************/}

                        { (!isDisabled || !_.isEqual(initialValues.advisorRestrictions, initialValues.tutorRestrictions)) && 
                        <Col two>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit || isDisabled ? 'asDisabled' : '') }>{I18n.t('queries.advisor.yourRestrictions')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorRestrictions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit || isDisabled}
                                validate={(canEdit && !isDisabled) ? [maxLength300] : []}
                             /> 
                        </Col>}

                        {/*/*************************************************************RESTRICCTIONS PRETOR**************************************************************/}

                        { isCorrectCheckedOrEnded && !_.isEqual(initialValues.restrictions, initialValues.tutorRestrictions) &&

                            <Col two className={isCorrectCheckedOrEnded && !_.isEqual(initialValues.restrictions, initialValues.tutorRestrictions) ? 'mTop30' : ''}>
                                <TextBlock>
                                    <span className="heading-3 asDisabled">{I18n.t('queries.advisor.restrictionsPretor')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='tutorRestrictions'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={true}
                                /> 
                            </Col>
                        }

                    </Row>

                    <Row>

                    {/*/*************************************************************TO POWERED ADVISOR**************************************************************/}

                        <Col two>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.toPoweredAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorToPowered'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                            />
                        </Col>

                    {/*/*************************************************************TO POWERED TUTOR**************************************************************/}

                       { (!isDisabled || !_.isEqual(initialValues.advisorToPowered, initialValues.tutorToPowered)) && 
                            <Col two>
                                <TextBlock>
                                    <span className={"heading-3 " + (!canEdit || isDisabled ? 'asDisabled' : '') }>{I18n.t('queries.advisor.yourToPowered')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='tutorToPowered'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={!canEdit || isDisabled}
                                    validate={(canEdit && !isDisabled) ? [maxLength300] : []}
                                />
                            </Col>
                            }

                    {/*/*************************************************************TO POWERED PRETOR**************************************************************/}

                        { isCorrectCheckedOrEnded && !_.isEqual(initialValues.toPowered, initialValues.tutorToPowered) && 
                            <Col two>
                                <TextBlock>
                                    <span className={isCorrectCheckedOrEnded && !_.isEqual(initialValues.toPowered, initialValues.tutorToPowered) ? 'mTop30' : ''}>{I18n.t('queries.advisor.toPoweredPretor')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='toPowered'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={true}
                                />
                            </Col>
                        }
                    </Row>

                    {/*/*************************************************************SUGGESTIONS ADVISOR**************************************************************/}
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.suggestionsAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorSuggestions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                             />
                        </Col>
                    </Row>

                    {/*/*************************************************************SUGGESTIONS TUTOR**************************************************************/}

                    { (!isDisabled || !_.isEqual(initialValues.advisorSuggestions, initialValues.tutorSuggestions) ) && 
                        <Row>
                            <Col equal>
                                <TextBlock>
                                    <span className={"heading-3 " + (!canEdit || isDisabled ? 'asDisabled' : '') }>{I18n.t('queries.advisor.yourSuggestions')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='tutorSuggestions'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={!canEdit || isDisabled}
                                    validate={(canEdit && !isDisabled) ? [maxLength300] : []}
                                />
                            </Col>
                        </Row>
                    }

                    {/*/*************************************************************SUGGESTIONS ADVISOR**************************************************************/}

                    {isCorrectCheckedOrEnded && !_.isEqual(initialValues.suggestions, initialValues.tutorSuggestions) ? <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.suggestionsPretor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='suggestions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                             />
                        </Col>
                    </Row>: null}

                    { (this.getStudios('advisorSchoolSuggestions').length || this.getStudios('advisorSequenceSuggestions').length) ? 
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.finalRecomendationsAdvisor')}</span>
                            </TextBlock>
                        </Col>
                        <Message>
                            { this.getStudios('advisorSchoolSuggestions').length > 0 && 
                            <>
                                <Message.Header>{I18n.t('queries.advisor.text6')}</Message.Header>
                                <p>
                                    {this.getStudios('advisorSchoolSuggestions')}
                                </p>
                            </>}
                            { this.getSequences('advisorSequenceSuggestions').length > 0 && 
                            <>
                                <Message.Header>{I18n.t('queries.advisor.text7')}</Message.Header>
                                <p>
                                    {this.getSequences('advisorSequenceSuggestions')}
                                </p>
                            </>}
                        </Message>
                    </Row>
                    : null}

                    {isCorrectCheckedOrEnded && 
                    (!_.isEqual(initialValues.advisorSchoolSuggestions, initialValues.tutorSchoolSuggestions) 
                    || (!_.isEqual(initialValues.advisorSequenceSuggestions, initialValues.tutorSequenceSuggestions)))
                     ? <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.finalRecomendationsTutor')}</span>
                            </TextBlock>
                        </Col>
                        <Message>
                            {!_.isEqual(initialValues.advisorSchoolSuggestions, initialValues.tutorSchoolSuggestions) &&
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text6')}</Message.Header>
                                <p>
                                    {this.getStudios('tutorSchoolSuggestions')}
                                </p>
                            </React.Fragment> 
                            }
                            {!_.isEqual(initialValues.advisorSequenceSuggestions, initialValues.tutorSequenceSuggestions) && 
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text7')}</Message.Header>
                                <p>
                                    {this.getSequences('tutorSequenceSuggestions')}
                                </p>
                            </React.Fragment>
                            }
                        </Message>
                    </Row>
                    : null}

                    {isCorrectCheckedOrEnded && 
                    (!_.isEqual(initialValues.schoolSuggestions, initialValues.tutorSchoolSuggestions) 
                    || (!_.isEqual(initialValues.sequenceSuggestions, initialValues.tutorSequenceSuggestions)))
                     ? <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.finalRecomendationsPretor')}</span>
                            </TextBlock>
                        </Col>
                        <Message>
                            {!_.isEqual(initialValues.schoolSuggestions, initialValues.tutorSchoolSuggestions) &&
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text6')}</Message.Header>
                                <p>
                                    {this.getStudios('schoolSuggestions')}
                                </p>
                            </React.Fragment> 
                            }
                            {!_.isEqual(initialValues.sequenceSuggestions, initialValues.tutorSequenceSuggestions) && 
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text7')}</Message.Header>
                                <p>
                                    {this.getSequences('sequenceSuggestions')}
                                </p>
                            </React.Fragment>
                            }
                        </Message>
                    </Row>
                     : null}

                    {(canEdit && !isDisabled) ? 
                    <ScrollBlock>
                        <FlexibleBlock>
                            <StudioBlock>
                                <Sections>
                                    <span className="label regular">{I18n.t('queries.advisor.text6')}</span>
                                </Sections>
                                    {
                                        _.map(studios, studio => (
                                            <FlexibleRow key={studio.key}>
                                                <CheckboxBlock>
                                                    <Field
                                                        component={ styledCheckboxList }
                                                        name='tutorSchoolSuggestions'
                                                        id={studio.value}
                                                        label={ '' }
                                                        disabled={!canEdit || isDisabled}
                                                        />
                                                </CheckboxBlock>
                                                <SelectBlock>
                                                        <FieldAsInput>
                                                            <span>{studio.text}</span>
                                                        </FieldAsInput>
                                                </SelectBlock>
                                            </FlexibleRow>
                                        ))
                                    }
                                </StudioBlock>
                            <PracticeBlock>
                            <Sections>
                                <span className="label regular">{I18n.t('queries.advisor.text7')}</span>
                            </Sections>
                                {
                                    _.map(alternativePractices, practice => (

                                        <FlexibleRow key={practice._id}>
                                            <CheckboxBlock>
                                                <Field
                                                    component={ styledCheckboxList }
                                                    name='tutorSequenceSuggestions'
                                                    id={practice._id}
                                                    label={ '' }
                                                    disabled={!canEdit || isDisabled}
                                                    />
                                            </CheckboxBlock>
                                            <SelectBlock>
                                                    <FieldAsInput>
                                                        <span>{practice.title['ES']}</span>
                                                    </FieldAsInput>
                                            </SelectBlock>
                                        </FlexibleRow>

                                    ))
                                }
        
                            </PracticeBlock>
                        </FlexibleBlock>
                    </ScrollBlock> : ''}

                    {( (canEdit && !isDisabled) || 
                    Utils.checkIfNotEqualAndNotEmpty(initialValues.tutorRoutine, initialValues.routine) 
                    || Utils.checkIfNotEqualAndNotEmpty(initialValues.tutorRoutine, initialValues.advisorRoutine) ) && 
                        <YTab menu={{ secondary: true, pointing: true }} panes={this.buildPanes()} activeIndex={activePaneIndex} onTabChange={this.handleTabChange} />}
                   
                    <SequenceBuilder
                        reduxFormState={reduxFormState}
                        initialValues={initialValues}
                        asanas={asanas}
                        advancedAsanasIds={advancedAsanasIds}
                        canEdit= { routineKey === 'tutorRoutine' && (canEdit || !isDisabled) }
                        dispatch={this.props.dispatch}
                        formName="pretorianTutorForm"
                        routineKey={routineKey}
                    />   
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.tutorMessageToAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorMessageToAdvisor'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={[maxLength300]}
                             />
                        </Col>
                    </Row>

                    {isCorrectCheckedOrEnded && !_.isEqual(initialValues.tutorMessageToAdvisor, initialValues.messageToAdvisor) && <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.tutorMessageToAdvisorFromPretor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='messageToAdvisor'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                             />
                        </Col>
                    </Row>}

                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.messageToStudent')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorMessageToStudent'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={[maxLength300]}
                             />
                        </Col>
                    </Row>

                    {isCorrectCheckedOrEnded && !_.isEqual(initialValues.tutorMessageToStudent, initialValues.messageToStudent) && <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.messageToStudentFromPretor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='messageToStudent'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                             />
                        </Col>
                    </Row>
                    }

                    {isCorrectCheckedOrEnded && !_.isEmpty(initialValues.messageToTutor) && 

                        <Message icon='info' header={I18n.t('queries.advisor.messageToTutor')} content={initialValues.messageToTutor}/>

                    }
                    {canEdit && isDisabled && <FlexWrap fend style={{marginTop: '50px', marginBottom: '50px'}}>
                        <OButton type="submit" primary color="#fff" upper size="big">
                            <span>{I18n.t('actions.confirm')}</span>
                        </OButton>
                        <OButton type="button" onClick={() => this.enableFields() } secondary color="#fff" upper size="big">
                            <span>{I18n.t('actions.correct')}</span>
                        </OButton>
                    </FlexWrap>}
                    {canEdit && !isDisabled && <FlexWrap fend style={{marginTop: '50px', marginBottom: '50px'}}>
                        <OButton type="submit" primary color="#fff" upper size="big"  disabled={invalid}>
                            <span>{I18n.t('actions.sendCorrection')}</span>
                        </OButton>
                    </FlexWrap>}
                </Form>
            </InnerContent>
        )
    }
}

PretorianTutorView = connect(state => {

    const reduxFormState = _.cloneDeep(_.get(state, 'form.pretorianTutorForm.values', {}));

    return {
        reduxFormState
    };

})(PretorianTutorView);

export default reduxForm({
    form: 'pretorianTutorForm',
    touchOnBlur: true,
    touchOnChange: true,
    enableReinitialize:true,
})(connect(null, { openModal, closeModal  })(PretorianTutorView));
