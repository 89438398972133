import i18n from "../../i18n";

/**************************************** Validations ****************************************/

export const required = value => value || value === 0 ? undefined : i18n.t('validations.required');
export const minLength = min => value => !value || min < value.length ? undefined : i18n.t('validations.minLength', { min });
export const maxLength = max => value => !value || value.length < max ? undefined : i18n.t('validations.maxLength', { max });

/**************************************** Warnings ****************************************/
export const intervalWarning = value => value && (value < 15 || value > 120 ) ? i18n.t('validations.durationInterval') : undefined
/**************************************** Restrictions ***************************************/

export const convertToNumeric = (value, canBeFloat = false) => {

    if (value) {

        let result = canBeFloat ? value.replace(/[^0-9.-]/g, '') : value.replace(/[^0-9-]/g, '');

        if (result.split('.').length > 2 || result.split('-').length > 2) {

            result = result.substring(0, result.length - 1);

        }

        return result;
        
    }

    return "";

};

export const trimString = (value) => {

    if (value) {

        let result = value.replace(/\s/g,'')

        return result;
        
    }

    return "";

};