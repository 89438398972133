import SecurityModel from '../data/models/security/security';
//actions
import { get } from 'lodash';

const GET_USER =  'GET_USER';

export const getUser = () => async dispatch => {

    try {
        
        const response = await SecurityModel.checkJwt();

        if (!get(response, 'data.user.advisorVerified', false)) {

            response.data.user.advisorVerified = false;

        }

        //response.data.user.role = ['pretorian']
        
        dispatch ({ type: GET_USER, payload:  response.data.user});

    } catch (errorCode) {

        dispatch ({ type: GET_USER, payload: {} });
        throw errorCode;

    }

};

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_USER:
            return { ...state, ...action.payload };   
        default:
            return state;
            
    }

};

export default reducer;