import React from 'react';
import _ from 'lodash';
import { Form, Input as SemanticInput, Label } from 'semantic-ui-react';
import { convertToNumeric, trimString } from '../../../../config/validations';
import { LabelForm } from '../../../styled/components/commons';

class CustomInput extends React.Component {

    onChange = (success, event) => {

        if (_.get(this.props, 'restrictions[0].trim', false) ) {
            
            event.target.value = trimString(event.target.value);
            
        }
        
        if (_.get(this.props, 'restrictions[0].canBeFloat', false) || _.get(this.props, 'restrictions[0].numeric', false)) {
            
            event.target.value = convertToNumeric(event.target.value, _.get(this.props, 'restrictions[0].canBeFloat', false));
            
        } 
        
        success(event);

    }

    render() {

        const { input, disabled, meta, fieldClasses, placeholder, icon, type, label, colorLabel } = this.props;

        return (
            <Form.Field className={ fieldClasses } error={ meta.touched && meta.invalid }>
                <LabelForm>{ label }</LabelForm>
                <SemanticInput
                    disabled={disabled || false}
                    { ...input }
                    placeholder={ placeholder }
                    onBlur={ e => this.onChange(input.onChange, e) }
                    onChange={ e => this.onChange(input.onChange, e) }
                    icon={icon || false}
                    type={ type || 'text'} />
                { meta.touched && meta.invalid && <Label basic color={ colorLabel || 'red' } pointing>{ meta.error }</Label> }
                { meta.touched && meta.warning && <Label basic className='label-warning' pointing>{ meta.warning }</Label> }
            </Form.Field>
        );

    }

}

export default CustomInput;