import styled, { css } from 'styled-components/macro';
import TimePicker from 'rc-time-picker';

const BlockForm = styled.div``;

const FormItem = styled.div`
    margin-bottom:10px;
    display:flex;
    align-items:center;
    ${({inline}) => inline && css`
        display:inline-flex;
        margin-bottom: 20px;
    `}
`;

const Time = styled(TimePicker)`

    &&& {
        min-width:80px;
        width: 100%;
        margin:0;
        font-size:14px;
        &+&{
            margin-left:10px;
        }
       
        ${({disabled}) => disabled && css`
            input {
                color: #ccc;
                background: #f7f7f7;
            }
    `}
    }
    
`;

const TimeWrapper = styled.div`

    margin-left:20px;
    display:flex;
    justify-content:space-between;
    width:100%;
    min-width: 0;
    align-items:center;
`;

const DayWrapper = styled.div`

    min-width:90px;

`;

export {
    BlockForm,
    FormItem,
    Time,
    TimeWrapper,
    DayWrapper
}