// Third party libraries
import React from 'react';
import I18n from '../../../i18n';

// Components
import AsideMenu from '../../components/asideMenu';
import BlockCalulator from './components/blockCalculator';
import PageHeader from '../../components/pageHeader';
import TopNav from '../../components/topNav';
import BarChartContainer from './graphs/barChart';
import DonutChartContainer from './graphs/donutChart';
import PieChartContainer from './graphs/pieChart';
import RtdGraphContainer from './graphs/rtdGraph';

// Styled components
import { BlockStats } from '../../styled/components/commons';
import {Inner, Container, Content, InnerContent} from '../../styled/components/wrappers';
import { ColStats, RowStats } from './styles/styled';

// Models
import StatsModel from '../../../data/models/stats/stats';
import { connect } from 'react-redux';

// Redux
import { openLoader, closeLoader } from '../../../redux-store/loader';

class MyResume extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            statsCommissions: [],
            statsQueries: [],
            statsQueriesDenies: [],
            statsUser: [],
            exchangeEUR: 0
        };

    }

    componentDidMount() {

        this.onGetData();

    }

    onGetData = async () => {

        const { openLoader, closeLoader } = this.props;

        try {

            openLoader();
            const statsCommissions = (await StatsModel.getStatsAdvisorUserCommission()).data;
            const statsQueries = (await StatsModel.getStatsAdvisorUserQuery()).data;
            const statsQueriesDenies = (await StatsModel.getStatsAdvisorUserQueryDenies()).data;
            const statsUser = (await StatsModel.getStatsAdvisorUser()).data;
            const exchangeEUR = (await StatsModel.getExchange('EUR')).data;

            this.setState({
                statsCommissions,
                statsQueries,
                statsQueriesDenies,
                statsUser,
                exchangeEUR: exchangeEUR.value
            });


        } catch (error) {

            console.error('error', error);

        } finally {

            closeLoader();

        }

    }

    render() {

        const { statsCommissions, statsQueries, statsQueriesDenies, statsUser, exchangeEUR} = this.state;

        return (
            <Container>
                <TopNav {...this.props } />
                <Inner>
                    <AsideMenu active="resume" {...this.props }/>
                    <Content>
                        <PageHeader>
                            <span className="heading-1 upper">{I18n.t('resumen.incomeStats')}</span>
                        </PageHeader>
                        <InnerContent>
                            <RowStats breakpoint={1024}>
                                <ColStats>
                                <div>
                                    <p className="labels">{I18n.t('resumen.monthlyIncome')}</p>
                                    <p className="labels regular">{I18n.t('resumen.monthlyIncomeDescription')}</p>
                                </div>
                                <div style={{width: '100%'}}>
                                    <RtdGraphContainer data={statsCommissions} enviroment="commissions" exchangeEUR={exchangeEUR}/>
                                </div>
                                </ColStats>
                                <ColStats >
                                    <div>
                                        <p className="labels" style={{maxWidth: '250px'}}>{I18n.t('resumen.avgHours')}</p>

                                        <BlockCalulator
                                            data={statsQueries}
                                            type="avgWeeklyHours"
                                            property={'duration'}
                                            outputRender={ output => (
                                                <BlockStats style={{'--stats-color' : 'var(--stats-color_h)'}} column>
                                                    <span className="stats-text wordBreak">{output || 0}</span>
                                                    <span className="labels" >{I18n.t('resumen.weekHours')}</span>
                                                </BlockStats>
                                            )}>
                                        </BlockCalulator>
                                    </div>
                                    <div style={{marginTop: '32px'}}>
                                        <p className="labels" style={{maxWidth: '250px'}}>{I18n.t('resumen.avgMonthlyIncome')}</p>
                                        <BlockCalulator
                                            data={statsCommissions}
                                            type="avgTotalMonthlyIncome"
                                            property={'amount'}
                                            exchangeEUR={exchangeEUR}
                                            outputRender={ output => (
                                                <BlockStats style={{'--stats-color' : 'var(--stats-color_h)'}} column>
                                                    <span className="stats-text wordBreak">{output || 0}</span>
                                                    <span className="labels" >{I18n.t('resumen.monthlyUSD')}</span>
                                                </BlockStats>
                                            )}>
                                        </BlockCalulator>
                                    </div>
                                </ColStats>
                            </RowStats>
                        </InnerContent>
                        <PageHeader>
                            <span className="heading-1 upper">{I18n.t('resumen.queryStats')}</span>
                        </PageHeader>
                        <InnerContent>
                            <RowStats breakpoint={1024}>
                                <ColStats >
                                    <div style={{width: '100%'}}>
                                        <DonutChartContainer primaryData={statsQueries} secondaryData={statsQueriesDenies} enviroment="queriesAcceptedAndDenied" chartText={I18n.t('resumen.totalQueriesReached')}/>
                                    </div>
                                    <div>
                                        <p className="labels">{I18n.t('resumen.totalQueries')}</p>
                                        <p className="labels regular">{I18n.t('resumen.totalQueriesDescription')}</p>
                                    </div>
                                </ColStats>
                                <ColStats>
                                    <div>
                                        <p className="labels">{I18n.t('resumen.queryType')}</p>
                                        <p className="labels regular">{I18n.t('resumen.queryTypeDescription')}</p>
                                    </div>
                                    <div style={{width: '100%'}}>
                                        <BarChartContainer data={statsQueries} enviroment="queriesType" />
                                    </div>
                                </ColStats>
                            </RowStats>
                            <RowStats breakpoint={1024}>
                                <ColStats>
                                    <div style={{width: '100%'}}>
                                        <DonutChartContainer primaryData={statsQueries} enviroment="queriesCorrectedAndIncorrected" chartText={I18n.t('resumen.totalQueriesMade')}/>
                                    </div>
                                    <div>
                                        <p className="labels">{I18n.t('resumen.queriesMake')}</p>
                                        <p className="labels regular">{I18n.t('resumen.queriesMakeDescription')}</p>
                                    </div>
                                </ColStats>
                                <ColStats>
                                    <div>
                                        <p className="labels" style={{maxWidth: '250px'}}>{I18n.t('resumen.avgTimeToAnswer')}</p>
                                        <BlockCalulator
                                            data={statsQueries}
                                            type="avgAnswer"
                                            property={'duration'}
                                            outputRender={ output => (
                                                <BlockStats style={{'--stats-color' : 'var(--stats-color_f)'}} spaceAround>
                                                    <span className="stats-text white wordBreak">{output || 0}</span>
                                                    <span className="labels white" >{I18n.t('resumen.minutes')}</span>
                                                </BlockStats>
                                            )}>
                                        </BlockCalulator>
                                    </div>
                                    <div style={{marginTop: '32px'}}>
                                        <p className="labels" style={{maxWidth: '250px'}}>{I18n.t('resumen.avgFeedback')}</p>
                                        <BlockCalulator
                                            data={statsQueries}
                                            type="avgFeedback"
                                            property={'ranking'}
                                            outputRender={ output => (
                                                <BlockStats style={{'--stats-color' : 'var(--stats-color_k)'}} spaceAround>
                                                    <span className="stats-text wordBreak">{output || 0}</span>
                                                    <span className="labels" >{I18n.t('resumen.system')}</span>
                                                </BlockStats>

                                            )}>
                                        </BlockCalulator>
                                        <BlockCalulator
                                            data={statsQueries}
                                            type="avgFeedback"
                                            property={'feedback'}
                                            outputRender={ output => (
                                                <BlockStats style={{'--stats-color' : 'var(--stats-color_k)'}} spaceAround>
                                                    <span className="stats-text wordBreak">{output || 0}</span>
                                                    <span className="labels" >{I18n.t('resumen.user')}</span>
                                                </BlockStats>

                                            )}>
                                        </BlockCalulator>
                                    </div>
                                </ColStats>
                            </RowStats>
                        </InnerContent>
                        <PageHeader>
                            <span className="heading-1 upper">{I18n.t('resumen.userStats')}</span>
                        </PageHeader>
                        <InnerContent>
                            <RowStats breakpoint={1024}>
                                <ColStats>
                                    <div>
                                        <p className="labels">{I18n.t('resumen.linkType')}</p>
                                        <p className="labels regular">{I18n.t('resumen.linkTypeDescription')}</p>
                                    </div>
                                    <div style={{width: '100%'}}>
                                        <PieChartContainer data={statsUser} hasInnerRadius={false} enviroment="userStatsAffiliateAndJoined" />
                                    </div>
                                </ColStats>
                                <ColStats>
                                    <div>
                                        <p className="labels">{I18n.t('resumen.userUsability')}</p>
                                        <p className="labels regular">{I18n.t('resumen.userUsabilityDescription')}</p>
                                    </div>
                                    <div style={{width: '100%'}}>
                                        <PieChartContainer data={statsUser} hasInnerRadius={true} enviroment="userStatsUsability" />
                                    </div>
                                </ColStats>
                            </RowStats>
                            <RowStats breakpoint={1024}>
                                <ColStats>
                                    <div>
                                        <p className="labels" style={{maxWidth: '240px'}}>{I18n.t('resumen.accountType')}</p>
                                        <p className="labels regular">{I18n.t('resumen.accounTypeDescription')}</p>
                                        <p dangerouslySetInnerHTML={{__html: I18n.t('resumen.accounTypeDescriptionList')}}></p>
                                    </div>
                                </ColStats>
                                <ColStats>
                                    <div style={{width: '100%'}}>
                                        <RtdGraphContainer data={statsUser} enviroment="accountType" />
                                    </div>
                                </ColStats>
                            </RowStats>
                        </InnerContent>

                    </Content>
                </Inner>
            </Container>
        );

    }

}

export default connect(null, { openLoader, closeLoader })(MyResume);