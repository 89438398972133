import React, { PureComponent } from 'react';
import { Header } from './styles';

class PageHeader extends PureComponent {

    render() {

        const { children, bgColor } = this.props;

        return (
            <Header bgColor={bgColor}>
                {children}
            </Header>

        );
    }
}

export default PageHeader;