import _ from 'lodash';
import React, { Component } from 'react';
import { Responsive } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as uuid from 'uuid'

import AsideMenu from '../../../../components/asideMenu';
import BiometricMatrix from '../../../../components/biometricMatrix';
import HeaderElement from '../../../../components/headerElement';
import TopNav from '../../../../components/topNav';

import UserPersonalData from './components/UserPersonalData';

import OButton from '../../../../styled/components/button';
import { FlexWrap, InfoBlocks } from '../../../../styled/components/commons';
import { Container, Content, Inner, InnerContent } from '../../../../styled/components/wrappers';

import { Separator, SeparatorTextWrapper, TextBlock } from './styles';

import QueryForm from '../query/components/form';

import BiometricModel from '../../../../../data/models/biometric/biometric';
import ProductModel from '../../../../../data/models/product/product';
import QuestionModel from '../../../../../data/models/questions/questions';
import SessionModel from '../../../../../data/models/sessions/sessions';

import { openLoader, closeLoader } from '../../../../../redux-store/loader';
import { openModal, closeModal } from '../../../../../redux-store/modals';
import { getAlternativePractices } from '../../../../../redux-store/practices';
import { getPrograms } from '../../../../../redux-store/programs';
import { getAsanas } from '../../../../../redux-store/asanas';
import { getStudios } from '../../../../../redux-store/studios';

import { DEFAULT_ADVISOR } from '../../../../../config/constants/defaultAdvisor';

import { MatrixContainer } from '../../../../components/biometricMatrix/styles';

import I18n from '../../../../../i18n';
import { ROLES } from '../../../../../config/constants/roles';

class Query extends Component {

    constructor(props) {

        super(props)

        this.state = {
           query: {},
           biometricData: {},
           studios: [],
           alternativePractices: []
        };

    }

    componentDidMount() {

        this.getInfos();

    }

    componentWillUnmount() {

        this.props.closeModal();

    }

    getInfos = async () => {

        const { studios, getStudios, asanas, getAsanas, getPrograms, programs, getAlternativePractices, alternativePractices, match: { params: { queryId } }, openLoader, closeLoader} = this.props;

        let fetchData = {
            query: {},
            biometricData: {},
            queryStructure: {},
            resultStructure: {},
            userId: null,
            studios: [],
            alternativePractices: [],
            asanas: {},
            sessionsRoutine: [],
            advancedAsanasIds: []
        };

        try {

            openLoader();
            const query = await QuestionModel.getAdvisorQuery(queryId);
            const queryStructure = await QuestionModel.getQueriesAdvisorStructure();
            const practices = !_.isEmpty(alternativePractices.data) ? alternativePractices : await getAlternativePractices();
            const _programs = !_.isEmpty(programs.data) ? programs : await getPrograms();
            const _asanas = !_.isEmpty(asanas.data) ? asanas : await getAsanas();
            const allStudios = !_.isEmpty(studios.data) ? studios : await getStudios();

            //output => { _id_asana : { asana objeto } } => lo uso para obtener las imagenes de las ásanas en la tabla y el nombre y la imagen el desplegabe de las asanas compatibles
            _.each(_asanas.data, asana => {

                fetchData.asanas[asana._id] = asana;

            });

            //obtener listado de asanas por isAdvanced y formatearlo como un arrays de ids para que tenga el mismo formato de entra de datos que las asanas compatibles que es un arrays de ids
            //este listado se usará en el formulario/tabla de rutia cuando un registro tenga el added a true
            _.each(_asanas.data, asana => {

                if (asana.isAdvanced) {

                    fetchData.advancedAsanasIds.push(asana._id);

                }

            });

            fetchData.query = _.omit(query.data, ['history']);
            //fetchData.query = stage2;
            fetchData.queryStructure = queryStructure.data;
            this.buildQueryStructure(fetchData.query, fetchData.queryStructure);

            fetchData.alternativePractices = _.filter(practices.data, ap => !ap.onlyProgram && ap.published);
            _.each(_.filter(_programs.data, program => program.published), program => {

                program.isProgram = true;
                fetchData.alternativePractices.push(program);

            });

            if (_.get(fetchData,'query.userId', false)) {

                const biometric = await BiometricModel.getAssignedAdvisorUserBiometricInfos(fetchData.query.userId).catch(err => console.error(err));
                const userStudios = await ProductModel.getAssignedAdvisorUserProduct(fetchData.query.userId);
                fetchData.studios = _.chain(allStudios.data).filter( studio => _.findIndex(userStudios.data, uStudio => uStudio._id === studio._id) === -1).map(mappedStudio => {
                    return {
                        value: mappedStudio._id,
                        text: mappedStudio.title,
                        key: mappedStudio._id
                    }
                }).value();

                fetchData.biometricData = biometric.data;

                if ( _.isEmpty(_.get(fetchData.query, 'advisorRoutine', []))) {

                    let routineParam = `1${fetchData.biometricData.sequence}_00`;
                    const sessionsRoutine = await SessionModel.getSessionsRoutine(routineParam);

                    _.each(sessionsRoutine.data, routine => {

                        fetchData.sessionsRoutine.push({
                            ...routine,
                            originalDuration: routine.duration,
                            compatibleAsanas: fetchData.asanas[routine._id].compatibleAsanas || [],
                            added: false,
                            deleted: false,
                            deconstructed: false,
                            uniqId: uuid.v4()
                        });

                    });

                    fetchData.query.advisorRoutine = fetchData.sessionsRoutine;
                    fetchData.query.routineId = `1${fetchData.biometricData.sequence}_00`;

                }

            }

            this.setState({
                query: _.get(fetchData, 'query', {}),
                biometricData: _.get(fetchData, 'biometricData', {}),
                userId: _.get(fetchData,'query.userId', null),
                studios: _.orderBy(fetchData.studios, 'text', 'asc'),
                alternativePractices: _.orderBy(_.flatMap(fetchData.alternativePractices), ['title.ES'], ['asc']),
                asanas: fetchData.asanas,
                sessions: fetchData.sessionsRoutine,
                advancedAsanasIds: fetchData.advancedAsanasIds
            });

        } catch (error) {

            console.error('error', error)

        } finally {

            closeLoader();

        }

    }

    buildQueryStructure = (query, tree) => {

        let levels = {};
        let structure = {};
        _.each(query, (value, key) => {

            if (key.indexOf('level') >- 1) {

                levels[key] = value;

            }

        });

        this.readTree(levels, tree, structure);

    }

    readTree(levels, tree, structure) {

        if (!_.isEmpty(levels) && _.get(tree,'section.values', false)) {

            _.each(tree.section.values, v => {

                if (levels.hasOwnProperty(v.name) && levels[v.name].step == v.id.step) {

                    structure[v.name] = v.text;

                    if (v.hasChilds) {

                        let childKey = v.name.replace('level', '');
                        childKey++;
                        structure[`level${childKey}-${v.id.step}`] = [];

                        if (_.isArray(v.childs)) {

                            _.each(v.childs, child => {

                                //If levels have no child with values as array then convert it to array : case (derecho izquierdo) to follow the normal flow
                                if (!_.isArray(levels[`level${childKey}-${v.id.step}`])) {

                                    levels[`level${childKey}-${v.id.step}`] = [levels[`level${childKey}-${v.id.step}`]];

                                }

                                if (_.isArray(levels[`level${childKey}-${v.id.step}`]) && _.findIndex(levels[`level${childKey}-${v.id.step}`], level => level.index == child.id.index) > -1 ) {

                                    structure[`level${childKey}-${v.id.step}`].push({
                                        value: child.text,
                                        text: _.get(_.find(levels[`level${childKey}-${v.id.step}`], item => item.index == child.id.index),'text', ''),
                                        image: _.get(child,'image', null)
                                    });

                                }

                            });

                        }

                    }

                    this.readTree(levels, v, structure);

                }

            });

        } else {

            this.setState({ resultStructure: structure });

        }

    }

    refuseQuery =  () => {

        const { openModal, closeModal } = this.props;

        openModal({
            type: 'simple',
            title: {
                text: I18n.t('messages.alert'),
                classes: ['heading-2', 'upper']
            },
            description: {
                text: I18n.t('messages.refuseQuery'),
                classes: ['labels', 'regular']
            },
            buttons: [{
                text: I18n.t('actions.cancel'),
                callback: () => {

                    closeModal();

                },
                options: {
                    secondary: true,
                    fluid: false,
                    upper: true

                }
            },
            {
                text: I18n.t('actions.understood'),
                callback: () => {

                    closeModal();
                    this.onRefuseQuery();

                },
                options: {
                    primary: true,
                    color: '#fff',
                    fluid: false,
                    upper: true
                }
            }]
        }, true, 'mini');

    }

    onRefuseQuery = async () => {

        const { openLoader, closeLoader, history: { push }} = this.props;
        const {  query: { _id } } = this.state;

        try {

            openLoader()
            await QuestionModel.denyAdvisorQuery(_id);

        } catch (error) { } finally {

            closeLoader();
            push('/queries');

        }

    }

    onAcceptQuery = async () => {

        const { openLoader} = this.props;
        const { query: { _id } } = this.state;

        try {

            openLoader();
            await QuestionModel.acceptAdvisorQuery(_id);
            this.props.openModal({
                type: 'simple',
                title: {
                    text: I18n.t('messages.toast'),
                    classes: ['heading-2', 'upper']
                },
                description: {
                    text: I18n.t('messages.queryAccepted'),
                    classes: ['labels', 'regular']
                },
                buttons: [{
                    text: I18n.t('actions.understood'),
                    callback: this.props.closeModal,
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: false,
                        upper: true
                    }
                }]
            }, true, 'mini');
            this.getInfos();

        } catch (error) {

            console.error(error);
        }

    };


    getPretorianType = () => {

        const { query } = this.state;
        const { userSessionId } = this.props;

        if (query.tutorUserId && query.tutorUserId === userSessionId) {

            return ROLES.PRETORIAN_TUTOR;

        } else if (query.checkerUserId && query.checkerUserId === userSessionId) {

            return ROLES.PRETORIAN_SUPERVISOR;

        }

        return null;

    }

    renderViewType = () => {

        const { userId, studios, alternativePractices, query, asanas, advancedAsanasIds} = this.state;
        const { user: { role }, userSessionId } = this.props;
        let currentViewByRol = null;

        let mappedStudios = {};
        let mappedAlternativePractices = {};

        _.each(studios, studio => {

            mappedStudios[studio.value] = studio.text;

        });

        _.each(alternativePractices, practice => {

            mappedAlternativePractices[practice._id] = practice.title.ES;

        });

        switch(true) {

            case (query.provisionalAdvisorUserId && query.provisionalAdvisorUserId === userSessionId) || (query.advisorUserId && query.advisorUserId === userSessionId):
                currentViewByRol = ROLES.ADVISOR;
                break;

            case role.indexOf(ROLES.PRETORIAN) > -1:
                currentViewByRol = this.getPretorianType();
                break;

            case role.indexOf(ROLES.PRETOR) > -1:
                currentViewByRol = ROLES.PRETOR;
                break;

            default:
                currentViewByRol = null;

        }

        //inicializamos los valores por defectos minimos para un advisor para evitar inconsistencias en vistas de pretor y pretorian
        const mergeQuery =  _.merge(_.clone(DEFAULT_ADVISOR), query);

        return (currentViewByRol && !_.isEmpty(mergeQuery)) ? (
            <Content>
                <InnerContent>
                    <Separator>
                        <SeparatorTextWrapper>
                            <span className="heading-2 brand-secondary">{I18n.t('queries.mySpaceOfAnswer')}</span>
                        </SeparatorTextWrapper>
                    </Separator>
                </InnerContent>
                <QueryForm
                    {...this.props}
                    asanas={asanas}
                    advancedAsanasIds={advancedAsanasIds}
                    rol={currentViewByRol}
                    status={query.status}
                    userId={userId}
                    mappedAlternativePractices={mappedAlternativePractices}
                    mappedStudios={mappedStudios}
                    studios={studios}
                    alternativePractices={alternativePractices}
                    initialValues={mergeQuery} />
            </Content>
        ) : '';

    }

    render() {

        const headerInfo = {
            query: {
                breadcrumb: [{
                    text: I18n.t('queries.myQueries'),
                    action: () => this.props.history.push('/queries')
                },
                {
                    text: I18n.t('queries.queryDetails')
                }],
                title: I18n.t('queries.queryDetails')
            }
        }

        const { query, biometricData, resultStructure } = this.state;

        return !_.isEmpty(query) ? (
            <Container>
                <TopNav {...this.props } />
                <Inner>
                    <AsideMenu active="queries" {...this.props } />
                    <Content>
                        <HeaderElement type="simple" headerInfo={ headerInfo.query } />
                        <InnerContent>
                            <UserPersonalData query={query} resultStructure={resultStructure} />
                            <TextBlock>
                                <span className="heading-3">{I18n.t('users.biometricMatrix')}</span>
                                <p className="labels regular">{I18n.t('users.lastUpdated')}: {moment(biometricData.updated).format('DD/MM/YYYY')}</p>
                            </TextBlock>
                            <InfoBlocks>
                            <MatrixContainer>
                                <BiometricMatrix matrix={biometricData} gender={_.get(query, 'gender', 2)} />
                            </MatrixContainer>
                            </InfoBlocks>
                            {query.status === 'preassigned' &&
                                <React.Fragment>
                                    <Responsive minWidth={560}>
                                        <FlexWrap fend>
                                            <OButton type="button" primary color="#fff" upper size="big" onClick={() => this.onAcceptQuery()}>
                                                <span>{I18n.t('actions.acceptQuery')}</span>
                                            </OButton>
                                            <OButton type="button" selected color="#FF6767" upper size="big" onClick={() => this.refuseQuery()}>
                                                <span>{I18n.t('actions.refuseQuery')}</span>
                                            </OButton>
                                        </FlexWrap>
                                    </Responsive>
                                    <Responsive maxWidth={559}>
                                        <FlexWrap fend>
                                            <OButton fluid type="button" primary color="#fff" upper size="small" onClick={() => this.onAcceptQuery()} style={{marginBottom:'10px'}}>
                                                <span>{I18n.t('actions.acceptQuery')}</span>
                                            </OButton>
                                            <OButton fluid type="button" selected color="#FF6767" upper size="small" onClick={() => this.refuseQuery()}>
                                                <span>{I18n.t('actions.refuseQuery')}</span>
                                            </OButton>
                                        </FlexWrap>
                                    </Responsive>
                                </React.Fragment>
                            }
                        </InnerContent>
                        {query.status !== 'preassigned' && this.renderViewType()}
                    </Content>
                </Inner>
            </Container>
        ) : '';

    }

}

const mapStateToProps = state => {

    return {
        user: _.get(state, 'role', {}),
        userSessionId: _.get(state, 'auth.userSession._id', null),
        alternativePractices: _.get(state, 'alternativePractices', []),
        programs: _.get(state, 'programs', []),
        asanas: _.get(state, 'asanas', []),
        studios: _.get(state, 'studios', [])

    };

};

export default connect(mapStateToProps, { getStudios, getAsanas, getPrograms, getAlternativePractices, openLoader, closeLoader, openModal, closeModal })(Query)