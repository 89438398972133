
import styled from 'styled-components/macro';

const FrameCircle = styled.div`
    
`;

const FrameCirclePercent = styled.div`
    text-align: center;
    position: relative;
    height:97px;
    width: 97px;
    margin:0 auto;
`;

const CircleSvg = styled.svg`
    position: relative;
    z-index: 50;
    width: 100%;
    height: 100%;
`;

const CircleShape = styled.circle`
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 10;
    stroke: var(--y-grey14);
    
    transform: translate(5px, 5px);
    &:nth-child(2) {      
        stroke-dasharray:270;
        stroke-dashoffset:270;
    }

`;

const CircleNumber = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 420px) {
        span {
            font-size: 14px;
        }
    }
    
`;

export {
    FrameCircle, 
    FrameCirclePercent,
    CircleSvg,
    CircleShape,
    CircleNumber
}