import _ from 'lodash';
import React, { Component } from 'react';

import PieChartView from './view';

import I18n from '../../../../../i18n';

class PieChartContainer extends Component {

    constructor(props) {

        super(props);

        this.state = {
            transformedData:[]
        }

    }

    componentDidMount() {

        this.transformData();

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.data, this.props.data)) {

            this.transformData();

        }

    }

    transformData = () => {

        const { enviroment } = this.props;

        switch (enviroment) {

            case 'userStatsAffiliateAndJoined':

                this.transformUserStatsAffiliateAndJoinedData();
                break;

            case 'userStatsUsability':

                this.transformUserStatsUsabilityData();
                break;

            default:

                break;

        }

    }

    transformUserStatsUsabilityData = () => {

        const { data } = this.props;

        let transformedData = [];

        let inactiveUsers = {
            category: I18n.t('resumen.inactive'),
            value: 0
        };

        let activeUsers = {
            category: I18n.t('resumen.active'),
            value: 0
        };

        let OnTheGoUsers = {
            category: I18n.t('resumen.quiteActive'),
            value: 0
        };

        let quiteActiveUsers = {
            category: I18n.t('resumen.lessActive'),
            value: 0
        };

        _.each(data, ({uses}) => {

            if (uses === 'inactive') {

                inactiveUsers.value++;

            }

            if (uses === 'active') {

                activeUsers.value++;

            }

            if (uses === 'on-the-go') {

                OnTheGoUsers.value++;

            }

            if (uses === 'quiet-active') {

                quiteActiveUsers.value++;

            }

        });

        transformedData.push(inactiveUsers, OnTheGoUsers, quiteActiveUsers, activeUsers);

        this.setState({ transformedData });

    }

    transformUserStatsAffiliateAndJoinedData = () => {

        const { data } = this.props;

        let transformedData = [];

        let affiliatedUsers = {
            category: I18n.t('resumen.direct'),
            value: 0
        };

        let joinedUsers = {
            category: I18n.t('resumen.byQuery'),
            value: 0
        };

        _.each(data, ({type}) => {

            if (type === 'affiliated') {

                affiliatedUsers.value++;

            }

            if (type === 'joined') {

                joinedUsers.value++;

            }

        });

        transformedData.push(affiliatedUsers, joinedUsers);

        this.setState({transformedData});

    }

    render() {

        const { transformedData } = this.state;
        const { hasInnerRadius } = this.props;

        return <PieChartView data={transformedData} hasInnerRadius={hasInnerRadius}/>
        ;
    }

}

export default PieChartContainer;