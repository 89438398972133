import React from 'react';
import { NavigationContainer, NavigationItem } from './styles/styled';


export default function InnerNavigation({active, history, match }) {
    

    return (
        <NavigationContainer>
            <NavigationItem active={ active === 'profile'} onClick={() => history.push(`/user/${match.params.userId}/profile`)}>
                <span className="labels">Perfil</span>
            </NavigationItem>
            <NavigationItem active={ active === 'queries'} onClick={() => history.push(`/user/${match.params.userId}/queries`)}>
                <span className="labels">Consultas</span>
            </NavigationItem>
            <NavigationItem active={ active === 'practices'} onClick={() => history.push(`/user/${match.params.userId}/practices`)}>
                <span className="labels">Prácticas y estudios</span>
            </NavigationItem>
        </NavigationContainer>
    )

}
