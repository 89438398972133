import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const getAssignedAdvisorUserSessions = async userId => await AxiosService.get(Config.server.api.sessionApi + `advisor/user/${userId}`, true);
const getSequenceCategories = async () => await AxiosService.get(Config.server.api.sessionApi + 'sequenceCategories', {}, true);
const getPrograms = async () => await AxiosService.get(Config.server.api.sessionApi + `program`, {}, true);
const getSequences = async () => await AxiosService.get(Config.server.api.sessionApi + `sequence/`, true);

const getAsanas = async () => await AxiosService.get(Config.server.api.sessionApi + `advisor/asana`, true);
const getSessionsRoutine = async routine => await AxiosService.get(Config.server.api.sessionApi + `advisor/sequence/${routine}/routine`, true);


export default {
    getAssignedAdvisorUserSessions,
    getSequenceCategories,
    getPrograms,
    getSequences,
    getAsanas,
    getSessionsRoutine
}
