import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';

import AsideMenu from '../../components/asideMenu';
import HeaderElement from '../../components/headerElement';
import TopNav from '../../components/topNav';

import { StatusItem, YTable, YRow, Picture, PictureBlock } from '../../styled/components/tables';
import { Container, Content, Inner, InnerContent } from '../../styled/components/wrappers';

import QuestionModel from '../../../data/models/questions/questions';

import { openLoader, closeLoader } from '../../../redux-store/loader';
import { openModal, closeModal } from '../../../redux-store/modals';

import I18n from '../../../i18n';

import placeHolderImage from '../../assets/img/yogabot_icon.svg';
import starNexoImage from '../../assets/img/star_nexo.png';
import { Icon } from 'semantic-ui-react';
import { YTooltip } from '../../styled/components/commons';
import { toHHMMSS } from '../../../utils/formatTime';


class MyQueries extends Component {

    constructor(props) {

        super(props);

        this.interval = null;

        this.state = {  
            indexCountDownTooltip: -1,
            elapsedTime: 0,
            searchValue: '',
            filter1Value: 'a',
            filter2Value: 'm',
            originialList: [],
            list: [],
            filter1Options: [{
                key: 0,
                text: I18n.t('queries.advisor.all'),
                value: 'a'
            }, {
                key: 1,
                text: I18n.t('status.btn.preassigned'),
                value: 'preassigned'
            }, {
                key: 2,
                text: I18n.t('status.btn.assigned'),
                value: 'assigned'
            }, {
                key: 3,
                text: I18n.t('status.btn.answered'),
                value: 'answered'
            }, {
                key: 4,
                text: I18n.t('status.btn.correct-reviewed'),
                value: 'reviewed'
            }, {
                key: 5,
                text: I18n.t('status.btn.incorrect-checked'),
                value: 'incorrect-checked'
            }, {
                key: 6,
                text: I18n.t('status.btn.ended'),
                value: 'ended'
            }],
            filter2Options: [{
                key: 1,
                text: I18n.t('queries.advisor.all'),
                value: 'a'
            }, {
                key: 2,
                text: I18n.t('queries.advisor.onlyMine'),
                value: 'm'
            }]
        };

    }
    
    componentDidMount() {

        this.getQueries();


    }

    componentWillUnmount() {

        this.props.closeModal();
        clearInterval(this.interval);

    }

    getQueries = async () => {

        try {

            this.props.openLoader();

            const list = await QuestionModel.getQueriesAssignedAdvisorUsers();
            const dataDownloadedTime = Moment();
            this.interval = !this.interval && setInterval(()=>{

                this.setState({elapsedTime: Moment().diff(Moment(dataDownloadedTime), 'seconds') });

            },500);

            this.setState({ originialList: list.data, list: this.getTableResult(list.data, this.state.searchValue, this.state.filter1Value, this.state.filter2Value) });

        } catch (error) {
            
            console.error('error', error);

        } finally {

            this.props.closeLoader();

        }

    }

    getTableResult = (data, searchValue, filter1Value, filter2Value) => _.orderBy(_.filter(data, ({ name, provisionalAdvisorUserId, advisorUserId, tutorUserId, checkerUserId, status }) => {

        const { _id } = this.props.user;
        return name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 && 
            (filter1Value === 'a' || 
                filter1Value === status || 
                (filter1Value === 'reviewed' && ['correct-reviewed', 'incorrect-reviewed'].indexOf(status) > -1) ||
                (filter1Value === 'ended' && ['ended', 'correct-checked'].indexOf(status) > -1)) &&
            (filter2Value === 'a' || 
                (provisionalAdvisorUserId === _id && status === 'preassigned') ||
                (advisorUserId === _id && status === 'assigned') ||
                (tutorUserId === _id && status === 'answered') ||
                (checkerUserId === _id && ['correct-reviewed', 'incorrect-reviewed'].indexOf(status) > -1) ||
                (this.props.role.indexOf('pretor') > -1 && status === 'incorrect-checked'));

    }), ['minutesToExpire'], ['asc']);

    onSearchValue = value => this.setState({ searchValue: value, list: this.getTableResult(this.state.originialList, value, this.state.filter1Value, this.state.filter2Value) });

    onFilter1Value = value => this.setState({ filter1Value: value, list: this.getTableResult(this.state.originialList, this.state.searchValue, value, this.state.filter2Value) });

    onFilter2Value = value => this.setState({ filter2Value: value, list: this.getTableResult(this.state.originialList, this.state.searchValue, this.state.filter1Value, value) });

    getStatusColour = query => {

        const { _id } = this.props.user;
        const role = this.props.role;
        const { status, provisionalAdvisorUserId, advisorUserId, tutorUserId, checkerUserId } = query;

        if (role.indexOf('pretor') > -1) {

            return status;

        }

        switch (status) {

            case 'preassigned':

                return _id === provisionalAdvisorUserId ? status : 'ended';

            case 'assigned':

                return _id === advisorUserId ? status : 'ended';

            case 'answered':

                return _id === tutorUserId ? status : 'ended';

            case 'correct-reviewed':
            case 'incorrect-reviewed':

                return _id === checkerUserId ? status : 'ended';
                
            case 'incorrect-checked':
            case 'correct-checked':
            case 'ended':
            default:

                return 'ended';

        }

    }

    renderAssignment = query => {

        const { _id, pictureUrl } = this.props.user;
        const { status, provisionalAdvisorUserId, advisorUserId, tutorUserId, checkerUserId } = query;

        if (status === 'incorrect-checked') {

            return (
                <PictureBlock>
                    <Picture initials square>
                        <img src={ starNexoImage } alt="" />
                    </Picture>
                </PictureBlock>
            )

        } else if (['correct-reviewed', 'incorrect-reviewed'].indexOf(status) > -1 && _id !== checkerUserId) {

            return (
                <PictureBlock>
                    <Picture initials square>
                        <span className="heading-2 white upper">S</span>
                    </Picture>
                </PictureBlock>
            )

        } else if (status === 'answered' && _id !== tutorUserId) {

            return (
                <PictureBlock>
                    <Picture initials square>
                        <span className="heading-2 white upper">T</span>
                    </Picture>
                </PictureBlock>
            )

        } else if ((status === 'preassigned' && _id !== provisionalAdvisorUserId) 
            || (status === 'assigned' && _id !== advisorUserId)
            || (['correct-checked', 'ended'].indexOf(status)) > -1) {

            return '';

        } else {

            return (
                <PictureBlock>
                    <Picture>
                        <img src={ pictureUrl || placeHolderImage } onError={ e => e.target.src = placeHolderImage } alt="" />
                    </Picture>
                </PictureBlock>
            );

        }

    }

    onInfo = () => {

        const { openModal } = this.props;
        
        openModal({
            type: 'legendStatus',
            headerTitle: I18n.t('actions.help')
        }, true, 'large');

    }

    onToggleCountDownTooltip = (index, event) => {

        event && event.stopPropagation();
        this.setState({indexCountDownTooltip: index});

    }

    render() {

        const { searchValue, filter1Options, filter1Value, filter2Options, filter2Value, list, indexCountDownTooltip, elapsedTime } = this.state;

        return (
            <Container>
                <TopNav { ...this.props } />
                <Inner>
                    <AsideMenu active="queries" { ...this.props }/>
                    <Content>
                        <HeaderElement type="search" 
                            title={ I18n.t('queries.myQueries') } 
                            searchValue={ searchValue } 
                            onSearchValue={ this.onSearchValue } 
                            filter1Options={ filter1Options } 
                            filter1Value={ filter1Value } 
                            onFilter1Value={ this.onFilter1Value }
                            filter2Options={ filter2Options } 
                            filter2Value={ filter2Value } 
                            onFilter2Value={ this.onFilter2Value }
                            infoCallback={this.onInfo}
                        />
                        <InnerContent>
                            { !_.isEmpty(list) ? <YTable stackable padded='very' selectable style={{ marginTop: '20px' }}>
                                <YTable.Header>
                                <YRow>
                                    <YTable.HeaderCell>{ I18n.t('queries.user') }</YTable.HeaderCell>
                                    <YTable.HeaderCell textAlign='center'>{ I18n.t('queries.advisor.assignment') }</YTable.HeaderCell>
                                    <YTable.HeaderCell>{ I18n.t('queries.date') }</YTable.HeaderCell>
                                    <YTable.HeaderCell>{ I18n.t('queries.account') }</YTable.HeaderCell>
                                    <YTable.HeaderCell>{ I18n.t('queries.query') }</YTable.HeaderCell>
                                    <YTable.HeaderCell>{ I18n.t('queries.status') }</YTable.HeaderCell>
                                </YRow>
                                </YTable.Header>
                                <YTable.Body>
                                    { _.map(list, (item, index) => (
                                        <YRow toRed={ item.minutesToExpire < 60 } toYellow={ item.minutesToExpire >= 60 && item.minutesToExpire < 180 } onClick={ () => this.props.history.push(`/queries/query/${item._id}`) } key={ item._id }>
                                            <YTable.Cell>
                                                <PictureBlock>
                                                    <Picture>
                                                        <img src={ item.pictureUrl || placeHolderImage } onError={ e => e.target.src = placeHolderImage } alt="" />
                                                    </Picture>
                                                    <span>{ item.name }</span>
                                                </PictureBlock>
                                            </YTable.Cell>
                                            <YTable.Cell textAlign='center'>
                                                { this.renderAssignment(item) }
                                            </YTable.Cell>
                                            <YTable.Cell>{ Moment(item.created).format('DD/MM/YYYY - HH:mm') }</YTable.Cell>
                                            <YTable.Cell><span>{ item.role.indexOf('premium') > -1 ? I18n.t('users.premium') : I18n.t('users.lite') }</span></YTable.Cell>
                                            <YTable.Cell>{ item.price ? I18n.t('queries.notFree') : I18n.t('queries.free') }</YTable.Cell>
                                            <YTable.Cell>
                                                <StatusItem status={ this.getStatusColour(item) }>
                                                    <span className="upper">{ I18n.t(`status.btn.${ item.status }`) }</span>
                                                </StatusItem>
                                                {(item.minutesToExpire < 180) && 
                                                    <YTooltip
                                                        bgColor="#e20d0d"
                                                        open={indexCountDownTooltip === index}
                                                        content={<span className="caption regular white">{ toHHMMSS(Math.max(item.minutesToExpire * 60 - elapsedTime, 0)) }</span>}
                                                        trigger={
                                                        <Icon 
                                                            onClick={(e) => this.onToggleCountDownTooltip(index, e)}
                                                            onMouseEnter={() => this.onToggleCountDownTooltip(index)}
                                                            onMouseLeave={() =>  this.onToggleCountDownTooltip(-1)}
                                                            style={{marginLeft: '10px'}} 
                                                            name="exclamation triangle" 
                                                            className="brand-error c-pointer" 
                                                            size="large" 
                                                        />}

                                                    />
                                                }
                                            </YTable.Cell>
                                        </YRow>
                                    )) }
                                </YTable.Body>
                            </YTable> : <p className="caption regular">{ I18n.t('messages.emptyData') }</p> }
                        </InnerContent>
                    </Content>
                </Inner>
            </Container>
        );

    }
    
}

const mapStateToProps = state => {

    return {
        user: _.cloneDeep(_.get(state, 'auth.userSession', {})),
        role: _.cloneDeep(_.get(state, 'role.role', []))
    };

};

export default connect(mapStateToProps, { openLoader, closeLoader, openModal, closeModal })(MyQueries);