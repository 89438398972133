import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Responsive } from 'semantic-ui-react';
// import ErrorMessage from '../../../../components/errorMessage';

import CustomInput from '../../../../components/form/input';
import LogoMobile from '../../../../assets/img/logo_secondary.svg';
import SecurityModel from '../../../../../data/models/security/security';

import I18n from '../../../../../i18n';
import OButton from '../../../../styled/components/button';

class RecoveryStep2 extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            codeError: -1,
            hasError: false,
            token: this.props.match.params.token || ''
        }
    }

    
     onRecovery = async payload => {

        try {
            
            const response = await SecurityModel.setRecoveryPassword({newPassword: payload.newPassword, token: this.state.token });

           if (response.status === 200) {

                this.props.history.push(`/auth/recovery/step/3`);

           }

        } catch (errorCode) {
            
            this.setState({hasError: true, codeError: 'error-recovery'});
        }

    };

    render() {

        return (

            <div className="full-wrapper">
                <div className="floating"><OButton type="button" primary  upper onClick={() => this.props.history.push('/auth/login')}><p>{I18n.t('auth.login')}</p></OButton></div>
                <div className="wrapper-login">
                    <div className="box">
                        <div className="logo">
                            <img src={LogoMobile} alt="logo" />
                        </div>
                        <div className="logo-mobile" style={{ paddingTop: '40px' }}>
                            <img src={LogoMobile} alt="logo" />
                        </div>
                        <div className="title upper">
                             {I18n.t('auth.restore')} <br /><span >{I18n.t('auth.password')}</span>
                        </div>
                        <div className="text t-center">
                            <p>{I18n.t('auth.restoreMessage')}</p>
                        </div>
                        <Form name="recoveryStep2Form" onSubmit={this.props.handleSubmit(this.onRecovery)}>
                            {/* <ErrorMessage code={this.state.codeError} active={this.state.hasError} /> */}
                            <div className="verticalPad">
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t("auth.newPassword") }
                                    name="verifyPassword"
                                    fieldClasses="y-input default"
                                    label={ I18n.t("auth.newPassword") }
                                    type="password" />
                            </div>
                            <div className="verticalPad">
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t("auth.verifyPassword") }
                                    name="newPassword"
                                    fieldClasses="y-input default"
                                    label={ I18n.t("auth.verifyPassword") }
                                    type="password" />
                            </div>
                            
                            <div className="verticalPad-long t-center">
                                <Responsive minWidth={421}>
                                    <OButton type="submit" primary upper size="huge"><p>{I18n.t('auth.send')}</p></OButton>
                                </Responsive>
                                <Responsive maxWidth={420}>
                                    <OButton type="submit" primary upper ><p>{I18n.t('auth.send')}</p></OButton>
                                </Responsive>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.verifyPassword)) {

        errors.verifyPassword = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.newPassword)) {

        errors.newPassword = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.verifyPassword) && !_.isEmpty(formValues.newPassword) && !_.isEqual(formValues.verifyPassword, formValues.newPassword) ) {

        errors.newPassword = I18n.t('validations.passwordNotMatch');

    }

    return errors;

};

export default reduxForm({
    form: 'recoveryStep2Form',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    enableReinitialize: true,
    initialValues: {
        verifyPassword : '',
        newPassword: ''
    }
})(connect(null, { })(RecoveryStep2));