import styled, { css } from 'styled-components/macro';


const InfoContent = styled.div`

    display:flex;
    padding-top:20px;
    margin-bottom:20px;
    @media (max-width:880px) {
        flex-wrap:wrap;
    }
`;


const InfoItem = styled.div`

    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom:10px;
    ${ ({ wider }) => wider && css`
        flex-grow: 2;
    `};
    
    ${ ({ bordered }) => bordered && css`
        border-radius:6px;
        border: solid 1px var(--border-color-secondary);
    `};

    ${ ({ padded }) => padded && css`
        padding: 10px;
    `};

    @media (max-width:880px) {
        flex-basis: 100%;
    }
    
`;

const InfoStatusItems = styled.div`

    display:flex;
    align-items:center;
    margin-bottom:20px;

`;

const InfoStatusItem = styled.div``;
const InfoStatusText = styled.div``;

const CounterItem = styled.div`

    display: inline-block;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    background-color: var(--brand-error);
    line-height: normal;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const GridStatus = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const GridStatusItem = styled.div`
    flex: 0 49%;
    margin-bottom: 1%;
    >div {
        width: 100%;
        height: 100%;
        
    }
`;

export {
    InfoContent,
    InfoItem,
    InfoStatusItems,
    InfoStatusItem,
    InfoStatusText,
    CounterItem,
    GridStatus,
    GridStatusItem
}