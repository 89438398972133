
export const daysOfWeek = {

    sunday:{
        checked:false,
        ini: null,
        end: null,
        dayOfWeek: 0
    },
    monday:{
        checked:false,
        ini: null,
        end: null,
        dayOfWeek: 1
    },
    tuesday:{
        checked:false,
        ini: null,
        end: null,
        dayOfWeek: 2
    },
    wednesday:{
        checked:false,
        ini: null,
        end: null,
        dayOfWeek: 3
    },
    thursday:{
        checked:false,
        ini: null,
        end: null,
        dayOfWeek: 4
    },
    friday:{
        checked:false,
        ini: null,
        end: null,
        dayOfWeek: 5
    },
    saturday:{
        checked:false,
        ini: null,
        end: null,
        dayOfWeek: 6
    },
    

}