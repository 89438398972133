import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import { LabelForm } from '../../../styled/components/commons';

class Select extends React.Component {

    render() {
        
        return (
            <Form.Field className={ this.props.fieldClasses }>
                {this.props.label && <LabelForm>{ this.props.label }</LabelForm>}
                <Dropdown
                    value={this.props.input.value}
                    placeholder={ this.props.placeholder }
                    selection
                    search={this.props.search || false}
                    options={ this.props.options }
                    className="fluid"
                    disabled={this.props.disabled || false}
                    onChange={ (e, { value }) => this.props.input.onChange(value) } />
            </Form.Field>
        );

    }

};

export default Select;