import React, { Component } from 'react'
import _ from 'lodash'

import AsideMenu from '../../components/asideMenu'
import TopNav from '../../components/topNav'
import { Container, Content, Inner, InnerContent } from '../../styled/components/wrappers'

import I18n from '../../../i18n'
import { Picture, PictureBlock, YRow, YTable } from '../../styled/components/tables'
import HeaderElement from '../../components/headerElement'
import SecurityModel from '../../../data/models/security/security'

import placeHolderImage from '../../assets/img/yogabot_icon.svg';
import { indexedCountries } from '../../../config/constants/countries'

export default class MyTeam extends Component {
    constructor(props) {
        super(props)

        this.state = {
            list:[],
            searchValue: "",
            originalList: []
        }

    }

    componentDidMount() {

        this.onGetData();

    }

    onGetData = async () => {

        try {

            const {data} = await SecurityModel.getAdvisorTeam();

            this.setState({originalList: data, list: this.getTableResult(data, this.state.searchValue)});
            
        } catch (error) {
            
            console.error(error);

        } 

    }

    getTableResult = (data, searchValue) => _.orderBy(_.filter(data, ({ name, surname }) => {

        return [name,surname].join(' ').toLowerCase().indexOf(searchValue.toLowerCase()) > -1;

    }), ['name', 'surname'], ['asc']);

    onSearchValue = value => this.setState({ searchValue: value, list: this.getTableResult(this.state.originalList, value) });

    render() {

        const { list, searchValue} = this.state; 

        return (
            <Container>
                <TopNav {...this.props } />
                <Inner>
                    <AsideMenu active="myTeam" {...this.props }/>
                    <Content>
                    <HeaderElement type="search" title={_.upperFirst(I18n.t('navigation.myTeam'))} searchValue={ searchValue } onSearchValue={ this.onSearchValue } />
                        <InnerContent>
                            { !_.isEmpty(list) ? <YTable stackable padded='very' selectable style={{marginTop:'20px'}}>
                                <YTable.Header>
                                    <YRow>
                                        <YTable.HeaderCell>{I18n.t('users.user')}</YTable.HeaderCell>
                                        <YTable.HeaderCell>{I18n.t('users.country')}</YTable.HeaderCell>
                                        <YTable.HeaderCell>{I18n.t('auth.city')}</YTable.HeaderCell>
                                    </YRow>
                                </YTable.Header>
                                <YTable.Body>
                                    { _.map(list, item => (

                                        <YRow onClick={ () => {} } key={ item._id }>
                                            <YTable.Cell>
                                                <PictureBlock>
                                                    <Picture>
                                                        <img src={ item.pictureUrl || placeHolderImage } onError={ e => e.target.src = placeHolderImage } alt="" />
                                                    </Picture>
                                                    <span>{ item?.name + " " + item.surname}</span>
                                                </PictureBlock>
                                            </YTable.Cell>
                                            <YTable.Cell>
                                                { indexedCountries[item.country] || I18n.t('users.notCountry') }
                                            </YTable.Cell>
                                            <YTable.Cell>
                                                { item.city || I18n.t('users.notCity') }
                                            </YTable.Cell>
                                        </YRow>

                                    )) }
                                </YTable.Body>
                            </YTable> : <p className="caption regular">{I18n.t('messages.emptyData')}</p> }
                        </InnerContent>
                    </Content>
                </Inner>
            </Container>
        )
    }
}