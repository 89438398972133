
import { Popup, Tab } from 'semantic-ui-react';
import styled, { css } from 'styled-components/macro';
import React from 'react';

const YTab = styled(Tab)`

    &&& {
        .menu {
            font-family:PoppinsRegular; 
        }

     }

`;

const LabelForm = styled.label`
    &&&&& {
        display: block;
        margin: 0 0 .28571429rem 0;
        color: var(--brand-primary);
        font-weight: 400;
        text-transform: none;
        font-family: PoppinsBold;
        font-size:14px;
    }
    
`;

const CorrectMessage = styled.div`
    background:var(--brand-positive);
    padding:10px;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
`;

const InfoBlocks = styled.div`

    background-color:rgba(var(--info-block), 0.1);
    padding:20px 32px;
    border-radius:5px;
    margin-bottom:20px;
    @media (max-width:420px) {
        padding:20px;
    }

    ${({transparent}) => transparent && 
    css`
        background-color:transparent;
    `}
`;

const FlexWrap = styled.div`

    display:flex;
    flex-wrap:wrap;
    width:100%;
    
    ${({between}) => between && 
    css`
        justify-content:space-between;
    `}
    ${({fstart}) => fstart && 
    css`
        justify-content:flex-start;
    `}
    ${({fend}) => fend && 
    css`
        justify-content:flex-end;
    `}
`;

const MessageBox = styled.div`

    padding:10px;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    flex-basis:100%;
    max-width:100%;
    ${({tutor}) => tutor && css`

        background-color: rgba(var(--tutor-message-color), 0.5)
    
    `}

    ${({advisor}) => advisor && css`

        background-color: rgba(var(--advisor-message-color), 0.5)
    
    `}

    ${({pretor}) => pretor && css`

        background-color: rgba(var(--tutor-message-color), 0.2)
    
    
    `}

`;

const YTooltip = styled(({ color, bgColor, ...rest })=> <Popup {...rest} />)`

    &&&& {
        border-radius:6px;
        padding: 5px 10px;
        color: ${({color}) => color || "#000"};
        background-color: ${({bgColor}) => bgColor || "#fff"};
        &:before {
            background-color: ${({bgColor}) => bgColor || "#fff"};
        }
    }
    
`;

const BlockStats = styled.div`

    display:flex;
    align-items:center;
    justify-content:center;
    background-color: var(--stats-color);
    max-width: 100%;
    width: 340px;
    padding:10px 20px;

    ${({column}) => column && css`

        flex-direction:column;
    
    `};

    ${({spaceAround}) => spaceAround && css`

        justify-content:space-around;
    
    `};

`;

export {
    LabelForm,
    CorrectMessage,
    InfoBlocks,
    FlexWrap,
    MessageBox,
    YTooltip,
    YTab,
    BlockStats
}