
import styled from 'styled-components/macro';

const IndicatorContent = styled.div`
@media only screen and (max-width: 820px) {
    margin: 30px 0;
    
}`;
const Indicator = styled.div`
    display:flex;
    margin-bottom:30px;
    align-items: center;
   
`;
const IndicatorCircle = styled.div`
    flex-shrink:0;
    flex-grow:0;
    flex-basis:120px;
    @media only screen and (max-width: 820px) {
        flex-basis:110px;
        
    }

    @media only screen and (max-width: 420px) {
        flex-basis:70px;
        
    }
   
`;
const IndicatorText = styled.div`
    flex-grow:1;
    flex-shrink:1;
    flex-basis:0;
`;

const Title = styled.div`
    margin-bottom:5px;
`;
const Subtitle = styled.div`
    margin-bottom:10px;
`;


export {

    IndicatorContent,
    Indicator,
    IndicatorCircle,
    IndicatorText,
    Title,
    Subtitle
}