import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import ReduxThunk from 'redux-thunk';

import Loader from './ui/components/loader';
import KeepAlive from './ui/components/keepAlive';

import Reducers from './redux-store';

import App from './ui/app';

import './ui/assets/scss/variables.scss';
import './ui/assets/scss/colors.scss';
import './ui/assets/scss/fonts.scss';
import './ui/assets/scss/typography.scss';
import './ui/assets/scss/wrappers.scss';
import 'semantic-ui-css/semantic.min.css';
import './ui/assets/scss/helpers.scss';
import './ui/assets/scss/buttons.scss';
import './ui/assets/scss/inputs.scss';
import './ui/assets/scss/generic.scss';
import './ui/assets/scss/interface.scss';
import './ui/assets/scss/links.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-time-picker/assets/index.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    Reducers,
    composeEnhancers(applyMiddleware(ReduxThunk))
    
);

ReactDOM.render(
    <Provider store={ store }>
        <App />
        <Loader />
        <KeepAlive />
    </Provider>,
    document.getElementById('root')
);