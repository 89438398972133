import _ from 'lodash';
import React from 'react';
import Moment from 'moment';

class BlockCalulator extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            output: 0
        };

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.data,this.props.data)) {

            this.handleData();

        }

    }

    componentDidMount() {

        this.handleData();

    }

    handleData = () => {

        const { type = "" } = this.props;

        switch (type) {

            case 'avgAnswer':

                this.avgDurationToAnswerQueries();
                break;

            case 'avgFeedback':

                this.avgSystemAndUserFeedback();
                break;

            case 'avgTotalMonthlyIncome':

                this.avgTotalMonthlyIncome();
                break;

            case 'avgWeeklyHours':

                this.avgWeeklyHours();
                break;

            default:

                break;

        }

    }

    avgDurationToAnswerQueries = () => {

        const { data = [], property = "" } = this.props;

        let output = 0;

        output = (!_.isEmpty(data) && !_.isEmpty(property)) ? ((_.reduce(data, (acc, el) => _.get(el, `${property}`, false) ? acc + el[property] : 0 , 0)) / data.length) / 60 : 0;

        this.setState({ output });

    }

    avgSystemAndUserFeedback = () => {

        const { data = [], property = "" } = this.props;

        let output = 0;

        output = (!_.isEmpty(data) && !_.isEmpty(property)) ? (_.reduce(data, (acc, el) => _.get(el, `${property}`, false) ? acc + el[property] : 0 , 0)) / data.length : 0;

        this.setState({ output });

    }

    avgTotalMonthlyIncome = () => {

        const { data = [], property = "", exchangeEUR } = this.props;

        let exchange = {
            EUR: exchangeEUR,
            USD: 1
        };

        let transform = _.chain(data)
        .map(item => {

            return {
                ...item,
                month: Moment(item.createdAt).startOf('month').format('YYYYMM'),
                amount: item.amount * exchange[item.currency]
            };

        }).groupBy('month')
        .map((values, key, objs) => {

            return { total: _.reduce(values, (acc, el) => acc + el[property], 0) / _.size(objs) };

        })
        .reduce((acc, el) => Math.round(acc + el.total), 0)
        .value();

        this.setState({ output : transform || 0 });

    }

    avgWeeklyHours = () => {

        const { data = [], property = "" } = this.props;

        let transform = _.chain(data)
        .map(item => {

            return {
                ...item,
                week: Moment(item.createdAt).startOf('week')
            };

        }).groupBy('week')
        .map((values, key, objs) => {

            return { total: (_.reduce(values, (acc, el) => acc + el[property], 0) / _.size(objs)) / 3600 };

        })
        .reduce((acc, el) => acc + el.total, 0)
        .value()

        this.setState({output : transform || 0});

    }

    render() {

        const { output } = this.state;
        const { outputRender } = this.props;

        return outputRender(!_.isNaN(output) ? output : 0);

    }

}

export default BlockCalulator;