// Types
const SET_REGISTER_EMAIL = 'SET_REGISTER_EMAIL';

// Actions
export const setRegisterEmail = email => { return { type: SET_REGISTER_EMAIL, payload: { registerEmail: email} } };

// Reducer
const INITIAL_STATE = {
    registerEmail: ''
};

function reducer (state = INITIAL_STATE, action) {

    switch (action.type) {

        case SET_REGISTER_EMAIL:
            
            return { ...action.payload };

        default:

            return state;

    }

};

export default reducer; 