import SessionModel from '../data/models/sessions/sessions';

const GET_PRACTICES =  'GET_PRACTICES';

export const getAlternativePractices = () => async dispatch => {

    try {
        
        const alternativePractices = await SessionModel.getSequences();
        
        dispatch ({ type: GET_PRACTICES, payload:  alternativePractices.data });

        return alternativePractices;

    } catch (errorCode) {

        dispatch ({ type: GET_PRACTICES, payload: []});
        throw errorCode;

    }

};

const INITIAL_STATE = {

    data:[]

};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_PRACTICES:
            return { ...state, data: [...action.payload]};   
        default:
            return state;
            
    }

};

export default reducer;