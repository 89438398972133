import styled, { css } from 'styled-components/macro';
import { Table } from 'semantic-ui-react';

import React from 'react';

const YTable = styled(Table)`
    &&& {
        font-family:PoppinsRegular;
        font-size:14px;
        border:none;
    }
`;

const YRow = styled(({ toYellow, toGrey, toRed, toGreen, ...rest })=> <Table.Row {...rest} />)`
    ${ ({ toYellow }) => toYellow && css`
        background-color: rgba(var(--highlight-row), 0.1);
    `};

    ${ ({ toRed }) => toRed && css`
        background-color: rgba(226, 13, 13, 0.1);
    `};

    ${ ({ toGreen }) => toGreen && css`
        background-color:rgba(45, 205, 112, 0.1);
    `};

    ${ ({ toGrey }) => toGrey && css`
        background-color: var(--dark-grey-4);
    `};

    &&&&& {
        &:hover {
            background: transparent!important;
            box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2);
        }
    }
`;

const handleColorType = status => {

    switch (status) {

        case 'preassigned':

            return 'var(--pending-accept)';

        case 'assigned':

            return 'var(--pending-answer)';

        case 'answered':

            return 'var(--pending-revision)';

        case 'correct-reviewed':
        case 'incorrect-reviewed':

            return 'var(--pending-supervision)';
            
        case 'Iniciada':
        case 'incorrect-checked':

            return 'var(--pending-nexoyoga)';

        case 'correct-checked':
        case 'ended':
        case 'Terminada':
        default:

            return 'var(--ended)';

    }

};

const StatusItem = styled.div`
    display: inline-block;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    line-height: normal;
    background-color: ${ ({ status }) => handleColorType(status) };
`;

const PictureBlock = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
`;

const Picture = styled.div`
    
    width: 48px;
    height: 48px;
    overflow: hidden;
    background: white;
    border-radius: 48px;
    margin-right: 20px;
    img {
        width:100%;
        height:100%;
        object-fit:cover;
    }

    ${({square}) => square && css`
        border-radius: 6px;
    `};

    ${({initials}) => initials && css`
        background:var(--initial-color);
        display:flex;
        align-items:center;
        justify-content:center;
    `};

    ${({withIcon}) => withIcon && css`
        background:var(--dark-grey-5);
    `};
`;

export {
    YTable,
    StatusItem,
    YRow,
    PictureBlock,
    Picture
}