import React from 'react';
import I18n from '../../../i18n';
import NotFoundImage from '../../assets/img/not_found_image.png';
import './notFound.scss';

class NotFoundPage extends React.Component {

    isLogged = () => {

        return window.localStorage.getItem('jwt');

    }

    render() {

        return (
           <React.Fragment>
               <div className="wrap">
                    <div className="wrap-grid">
                        <div className="left-content">
                            <div className="first-title"><p>{I18n.t('404.title')}</p></div>
                            <div className="first-description"><p>{I18n.t('404.description')}</p></div>
                            <div className="second-title"><p>{I18n.t('404.question')}</p></div>
                            <div className="wrap-list">
                                {this.isLogged() && <div className="list-item" onClick={()=> this.props.history.push('/users')} dangerouslySetInnerHTML={{__html: I18n.t('404.firstItemIsLogged')}}>
                                </div>}
                                {!this.isLogged() && <div className="list-item" onClick={()=> this.props.history.push('/auth/login')} dangerouslySetInnerHTML={{__html: I18n.t('404.firstItem')}}> 
                                </div>}
                                {!this.isLogged() && <div className="list-item" onClick={()=> this.props.history.push('/auth/register/step/1/fill')} dangerouslySetInnerHTML={{__html: I18n.t('404.secondItem')}} >
                                </div>}
                            </div>
                        </div>
                        <div className="right-content">
                            <img src={NotFoundImage} alt="" />
                        </div>
                    </div>
               </div>
           </React.Fragment>
        );

    }

}

export default NotFoundPage