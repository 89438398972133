import styled from 'styled-components/macro';

const RowStats = styled.div`
    display:flex;
    margin: 32px 0;
    @media (max-width: ${(props) => `${props.breakpoint}px` || '720px'}) {
        flex-wrap:wrap;
        margin: 12px 0;
        & > div {
            align-items: flex-start;
        }
    }
`;

const ColStats = styled.div`
    flex-shrink:1;
    flex-grow:0;
    flex-basis: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 520px) {
        padding: 10px 0;
    }
`;

export {
    RowStats,
    ColStats
}