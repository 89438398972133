import _ from 'lodash';
import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { GRAPH_COLORS } from '../../../../../../config/constants/graphColors';

am4core.useTheme(am4themes_kelly);
am4core.useTheme(am4themes_animated);

class PieChartView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: `piechart#${Math.floor(Math.random() * 100)}`
        }

    }

    componentWillUnmount() {

        if (this.chart) {
            
            this.chart.dispose();

        }

    }

    componentDidUpdate(oldProps) {

        if (!_.isEqual(oldProps.data,this.props.data)) {

            if(this.chart._super) {

                this.chart.dispose();

            }
            
            this.initChart();

        }

    }

    componentDidMount() {

        this.initChart();

    }

    initChart = () => {

        const { id } = this.state;

        const { hasInnerRadius, data } = this.props;

        let chart = am4core.create(id, am4charts.PieChart);

        // Add data
        chart.data = data;
        
        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;

        if (hasInnerRadius) {
            pieSeries.innerRadius = am4core.percent(75);
        }

        pieSeries.slices.template.adapter.add("fill", (fill, target) => GRAPH_COLORS[target.dataItem.index]);
        
        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
        
        chart.hiddenState.properties.radius = am4core.percent(0);

        chart.legend = new am4charts.Legend();
        chart.padding(40,0,40,0);
        
        chart.responsive.enabled = true;
        chart.responsive.rules.push({
        relevant: function(target) {
            if (target.pixelWidth <= 600) {

                return true;

            }

            return false;
        },
        state: function(target, stateId) {
            if (target instanceof am4charts.PieSeries) {

                let state = target.states.create(stateId);

                let labelState = target.labels.template.states.create(stateId);
                labelState.properties.disabled = true;

                let tickState = target.ticks.template.states.create(stateId);
                tickState.properties.disabled = true;

                return state;

            }

            return null;
        }
        });

        this.chart = chart;
        
    }

    render() {

        const { id } = this.state;

        return (
           
            <div id={id} style={{width:'100%', height: '500px'}}></div>

        )
    }
}

export default PieChartView;