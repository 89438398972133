import React from 'react';
import _ from 'lodash';

import { InnerContent } from '../../../../styled/components/wrappers';

import { FilterBlock, FilterContent } from '../../styles/styled';

export default function SimpleHeader ({ headerInfo = {} }) {
    
    return (
        <FilterBlock>
            <InnerContent>
                <FilterContent>
                    <div>
                        <p className="caption regular" style={{marginBottom: '0'}}>
                            {
                                _.map(_.get(headerInfo,'breadcrumb', []), (item, index) => (

                                    <span key={index} className={item.action ? 'c-pointer' : ''} onClick={item.action}>{item.text}</span>

                                )).reduce((prev, curr) => [prev, ' > ', curr])
                            }
                        </p>
                        <p className="heading-1 upper">{headerInfo.title}</p>
                    </div>
                </FilterContent>
            </InnerContent>
        </FilterBlock>
    );
    
}