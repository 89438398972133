import _ from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import I18n from '../../../../../../../../../i18n';

//libraries components
import { Form, Message } from 'semantic-ui-react';

//components
import CustomTextArea from '../../../../../../../../components/form/textarea';
import SequenceBuilder from '../sequenceBuilder';

//styled components
import { Col, InnerContent, Row } from '../../../../../../../../styled/components/wrappers';
import { CheckboxBlock, Sections, SelectBlock, TextBlock, ScrollBlock, FlexibleBlock, StudioBlock, PracticeBlock, FlexibleRow, FieldAsInput, styledCheckboxList } from '../../../../styles';
import { FlexWrap, YTab } from '../../../../../../../../styled/components/commons';
import OButton from '../../../../../../../../styled/components/button';

//models
import QuestionModel from '../../../../../../../../../data/models/questions/questions';

//utils
import { maxLength } from '../../../../../../../../../config/validations';
import Utils from  '../../../../../../../../../utils';

const maxLength300 = maxLength(300);


class PretorView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activePaneIndex: 0,
            routineKey: "advisorRoutine"
        }

    }

    onSavePretorFeedback = async data => {

        const { openModal, closeModal, match: { params: { queryId } }, openLoader, closeLoader, history: { push }} = this.props;
        let hasError = false;
        const requiredFields = ['suggestions', 'sequenceSuggestions', 'schoolSuggestions', 'restrictions', 'toPowered', 'summary', 'routine', 'messageToAdvisor', 'messageToStudent', 'messageToChecker', 'messageToTutor'];

        try {

            openLoader()
            await QuestionModel.pretorAnswer(queryId, _.pick(data, requiredFields));

        } catch (error) {

            console.error(error);
            hasError = true;
            
        } finally {

            closeLoader();

            if (hasError) {

                openModal({
                    type: 'simple',
                    title:{
                        text: I18n.t('messages.alert'),
                        classes: ['heading-2', 'upper']
                    },
                    description:{
                        text: I18n.t('messages.commonError'),
                        classes: ['labels', 'regular']
                    },
                    buttons:[
                        {
                            text: I18n.t('actions.understood'),
                            callback: ()=> {

                                closeModal();

                            },
                            options: {
                                primary: true,
                                color: '#fff',
                                fluid: false,
                                upper: true
                                
                            }
                        }
                    ]
                }, true, 'mini');

            } else {

                push('/queries');

            }
            
        }

    }

    getStudios = target => {

        const { initialValues, mappedStudios } = this.props;

        let studios = [];

        if (initialValues[target]) {

            _.each(initialValues[target], studioId => {

                if (mappedStudios[studioId]) {

                    studios.push(mappedStudios[studioId])
                }

            })

        }

        return studios.join(', ');

    }


    getSequences = target => {

        const { initialValues, mappedAlternativePractices } = this.props;

        let sequences = [];

        if (initialValues[target]) {

            _.each(initialValues[target], secId => {

                if (mappedAlternativePractices[secId]) {

                    sequences.push(mappedAlternativePractices[secId])
                }

            })

        }

        return sequences.join(', ');

    }

    handleTabChange = (e, { activeIndex, panes }) => {
        
        let routineKey = "";

        switch(panes[activeIndex].menuItem.key) {

            case 'tutor':
                routineKey = "tutorRoutine";
                break;
            case 'nexoyoga':
                routineKey = "routine";
                break;
            default: 
                routineKey = "advisorRoutine";

        }
        
        this.setState({ activePaneIndex: activeIndex, routineKey });
    
    }

    buildPanes = () => {
        
        const { initialValues, canEdit } = this.props;

        let panes = [{
            menuItem: { key: 'advisor', content: I18n.t('queries.advisor.routineAdvisor')}
        }];

        if (Utils.checkIfNotEqualAndNotEmpty(initialValues.advisorRoutine, initialValues.tutorRoutine)) {

            panes.push({menuItem: { key: 'tutor', content: I18n.t('queries.advisor.routineTutor')} });

        }

        if ( canEdit || Utils.checkIfNotEqualAndNotEmpty(initialValues.tutorRoutine, initialValues.routine)) {

            panes.push({menuItem: { key: 'nexoyoga', content: I18n.t('queries.advisor.routinePretor') } });
            
        }

        return panes;

    }

    render() {

        const { studios, alternativePractices, canEdit, queryStatus, initialValues, reduxFormState, asanas, advancedAsanasIds, invalid } = this.props;
        const isCorrectCheckedOrEnded = (queryStatus === 'correct-checked' || queryStatus === 'ended');

        const { routineKey, activePaneIndex } = this.state;

        return (
            <InnerContent>
                <Form name="pretorForm" noValidate onSubmit={this.props.handleSubmit(this.onSavePretorFeedback)}>

                    {/*/*************************************************************SUMMARY**************************************************************/}
                {/*/*************************************************************SUMMARY ADVISOR**************************************************************/}

                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.summaryAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorSummary'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>

                    {/*/*************************************************************SUMMARY TUTOR**************************************************************/}

                    {!_.isEqual(initialValues.tutorSummary, initialValues.advisorSummary) && 
                        <Row>
                            <Col equal>
                                <TextBlock>
                                    <span className="heading-3 asDisabled">{I18n.t('queries.advisor.summaryTutor')}</span>
                                </TextBlock>
                                <Field
                                    component={ CustomTextArea }
                                    name='tutorSummary'
                                    label={ '' }
                                    placeholder={ I18n.t('actions.writeHere') }
                                    disabled={true}
                                    />
                            </Col>
                        </Row>
                    }

                    {/*/*************************************************************SUMMARY PRETOR**************************************************************/}

                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.summaryPretor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='summary'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                                />
                        </Col>
                    </Row>

                   {/*/*************************************************************RESTRICTIONS**************************************************************/}
                {/*/*************************************************************RESTRICTIONS ADVISOR **************************************************************/}

                    <Row>
                        <Col two>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.restrictionsAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorRestrictions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>

                    {/*/*************************************************************RESTRICTIONS TUTOR **************************************************************/}


                    {!_.isEqual(initialValues.tutorRestrictions, initialValues.advisorRestrictions) && 
                        <Col two>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.restrictionsTutor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorRestrictions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    }

                    {/*/*************************************************************RESTRICTIONS PRETOR **************************************************************/}

                        <Col two className={(!_.isEqual(initialValues.tutorRestrictions, initialValues.advisorRestrictions) ? 'mTop30' : '')}>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.restrictionsPretor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='restrictions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                                
                                />
                        </Col>
                    </Row>

                    {/*/*************************************************************TO POWERED**************************************************************/}
                {/*/*************************************************************TO POWERED ADVISOR **************************************************************/}

                    <Row>
                        <Col two>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.toPoweredAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorToPowered'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>

                    {/*/*************************************************************TO POWERED TUTOR **************************************************************/}


                    {!_.isEqual(initialValues.tutorToPowered, initialValues.advisorToPowered) && 
                        <Col two>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.toPoweredTutor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorToPowered'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    }

                    {/*/*************************************************************TO POWERED PRETOR **************************************************************/}

                        <Col two className={(!_.isEqual(initialValues.tutorToPowered, initialValues.advisorToPowered) ? 'mTop30' : '')}>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.toPoweredPretor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='toPowered'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                                />
                        </Col>
                    </Row>


                    {/*/*************************************************************SUGGESTIONS **************************************************************/}
                    {/*/*************************************************************SUGGESTIONS ADVISOR**************************************************************/}

                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.suggestionsAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='advisorSuggestions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>

                    { !_.isEqual(initialValues.tutorRestrictions, initialValues.advisorRestrictions) &&
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3 asDisabled">{I18n.t('queries.advisor.suggestionsTutor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorSuggestions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>
                    }

                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.suggestionsPretor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='suggestions'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                                />
                        </Col>
                    </Row>

                    { (this.getStudios('advisorSchoolSuggestions').length || this.getStudios('advisorSequenceSuggestions').length) ? 
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.finalRecomendationsAdvisor')}</span>
                            </TextBlock>
                        </Col>
                        <Message>
                            { this.getStudios('advisorSchoolSuggestions').length > 0 && 
                            <>
                                <Message.Header>{I18n.t('queries.advisor.text6')}</Message.Header>
                                <p>
                                    {this.getStudios('advisorSchoolSuggestions')}
                                </p>
                            </>}
                            { this.getSequences('advisorSequenceSuggestions').length > 0 && 
                            <>
                                <Message.Header>{I18n.t('queries.advisor.text7')}</Message.Header>
                                <p>
                                    {this.getSequences('advisorSequenceSuggestions')}
                                </p>
                            </>}
                        </Message>
                    </Row>
                    : null}

                    { 
                    (!_.isEqual(initialValues.advisorSchoolSuggestions, initialValues.tutorSchoolSuggestions) 
                    || (!_.isEqual(initialValues.advisorSequenceSuggestions, initialValues.tutorSequenceSuggestions)))
                     && <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.finalRecomendationsTutor')}</span>
                            </TextBlock>
                        </Col>
                        <Message>
                            {!_.isEqual(initialValues.advisorSchoolSuggestions, initialValues.tutorSchoolSuggestions) &&
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text6')}</Message.Header>
                                <p>
                                    {this.getStudios('tutorSchoolSuggestions')}
                                </p>
                            </React.Fragment> 
                            }
                            {!_.isEqual(initialValues.advisorSequenceSuggestions, initialValues.tutorSequenceSuggestions) && 
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text7')}</Message.Header>
                                <p>
                                    {this.getSequences('tutorSequenceSuggestions')}
                                </p>
                            </React.Fragment>
                            }
                        </Message>
                    </Row>
                    }

                {isCorrectCheckedOrEnded && 
                    (!_.isEqual(initialValues.schoolSuggestions, initialValues.tutorSchoolSuggestions) 
                    || (!_.isEqual(initialValues.sequenceSuggestions, initialValues.tutorSequenceSuggestions)))
                     && <Row>
                        <Col equal>
                            <TextBlock>
                                <span className="heading-3">{I18n.t('queries.advisor.finalRecomendationsPretor')}</span>
                            </TextBlock>
                        </Col>
                        <Message>
                            {!_.isEqual(initialValues.schoolSuggestions, initialValues.tutorSchoolSuggestions) &&
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text6')}</Message.Header>
                                <p>
                                    {this.getStudios('schoolSuggestions')}
                                </p>
                            </React.Fragment> 
                            }
                            {!_.isEqual(initialValues.sequenceSuggestions, initialValues.tutorSequenceSuggestions) && 
                            <React.Fragment>
                                <Message.Header>{I18n.t('queries.advisor.text7')}</Message.Header>
                                <p>
                                    {this.getSequences('sequenceSuggestions')}
                                </p>
                            </React.Fragment>
                            }
                        </Message>
                    </Row>
                    }

                    {canEdit && 
                        <ScrollBlock>
                            <FlexibleBlock>
                                <StudioBlock>
                                <Sections>
                                    <span className="label regular">{I18n.t('queries.advisor.text6')}</span>
                                </Sections>
                                    {
                                        _.map(studios, studio => (
                                            <FlexibleRow key={studio.key}>
                                                <CheckboxBlock>
                                                    <Field
                                                        component={ styledCheckboxList }
                                                        name='schoolSuggestions'
                                                        id={studio.value}
                                                        label={ '' }
                                                        disabled={!canEdit}
                                                        />
                                                </CheckboxBlock>
                                                <SelectBlock>
                                                        <FieldAsInput>
                                                            <span>{studio.text}</span>
                                                        </FieldAsInput>
                                                </SelectBlock>
                                            </FlexibleRow>
                                        ))
                                    }
                                </StudioBlock>
                            <PracticeBlock>
                            <Sections>
                                <span className="label regular">{I18n.t('queries.advisor.text7')}</span>
                            </Sections>
                                {
                                    _.map(alternativePractices, practice => (

                                        <FlexibleRow key={practice._id}>
                                            <CheckboxBlock>
                                                <Field
                                                    component={ styledCheckboxList }
                                                    name='sequenceSuggestions'
                                                    id={practice._id}
                                                    label={ '' }
                                                    disabled={!canEdit}
                                                    />
                                            </CheckboxBlock>
                                            <SelectBlock>
                                                    <FieldAsInput>
                                                        <span>{practice.title['ES']}</span>
                                                    </FieldAsInput>
                                            </SelectBlock>
                                        </FlexibleRow>

                                    ))
                                }
        
                            </PracticeBlock>
                        </FlexibleBlock>
                    </ScrollBlock>}
                    {( canEdit || Utils.checkIfNotEqualAndNotEmpty(initialValues.tutorRoutine, initialValues.routine) || Utils.checkIfNotEqualAndNotEmpty(initialValues.tutorRoutine, initialValues.advisorRoutine) ) && 
                        <YTab menu={{ secondary: true, pointing: true }} panes={this.buildPanes()} activeIndex={activePaneIndex} onTabChange={this.handleTabChange} />
                    }
                    <SequenceBuilder
                        reduxFormState={reduxFormState}
                        initialValues={initialValues}
                        asanas={asanas}
                        advancedAsanasIds={advancedAsanasIds}
                        canEdit={routineKey === 'routine' && canEdit}
                        dispatch={this.props.dispatch}
                        formName="pretorForm"
                        routineKey={routineKey}
                    />   
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className={"heading-3 asDisabled"}>{I18n.t('queries.advisor.tutorMessageToAdvisorFromTutor') }</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorMessageToAdvisor'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.tutorMessageToAdvisor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='messageToAdvisor'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                                />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className={"heading-3 asDisabled"}>{I18n.t('queries.advisor.messageToStudentFromTutor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='tutorMessageToStudent'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={true}
                                />
                        </Col>
                    </Row>

                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.messageToStudent')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='messageToStudent'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                                />
                        </Col>
                    </Row>

                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.messageToTutor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='messageToTutor'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                                />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col equal>
                            <TextBlock>
                                <span className={"heading-3 " + (!canEdit ? 'asDisabled' : '')}>{I18n.t('queries.advisor.messageToCheckerFromPretor')}</span>
                            </TextBlock>
                            <Field
                                component={ CustomTextArea }
                                name='messageToChecker'
                                label={ '' }
                                placeholder={ I18n.t('actions.writeHere') }
                                disabled={!canEdit}
                                validate={canEdit ? [maxLength300] : []}
                                />
                        </Col>
                    </Row>
                    {initialValues.checkerMessageToNexoyoga && 
                        <Message icon='info'
                            header={I18n.t('queries.advisor.messageFromChecker')}
                            content={initialValues.checkerMessageToNexoyoga}
                        >
                        </Message>
                    }
                    {canEdit && <FlexWrap fend style={{marginTop: '50px', marginBottom: '50px'}}>
                        <OButton type="submit" primary color="#fff" upper size="big" disabled={invalid}>
                            <span>{I18n.t('actions.send')}</span>
                        </OButton>
                    </FlexWrap>}
                </Form>
            </InnerContent>
        )
    }
}

PretorView = connect(state => {

    const reduxFormState = _.cloneDeep(_.get(state, 'form.pretorForm.values', {}));

    return {
        reduxFormState
    };

})(PretorView);

export default reduxForm({
    form: 'pretorForm',
    touchOnBlur: true,
    touchOnChange: true,
    enableReinitialize:true,
})(connect(null, {  })(PretorView));
