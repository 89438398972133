/* Imports */
import _ from 'lodash';
import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { GRAPH_COLORS } from '../../../../../../config/constants/graphColors';

am4core.useTheme(am4themes_animated);

class BarChartView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: `barchart#${Math.floor(Math.random() * 100)}`
        }

    }

    componentWillUnmount() {

        if (this.chart) {
            
            this.chart.dispose();

        }

    }

    componentDidUpdate(oldProps) {

        if (!_.isEqual(oldProps.data,this.props.data)) {

            this.chart.data = this.props.data;
        }

    }

    componentDidMount() {

        const { id } = this.state;

        const { data } = this.props;

        let chart = am4core.create(id, am4charts.XYChart);
        chart.padding(40, 40, 40, 40);

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;

        let label = categoryAxis.renderer.labels.template;
        label.maxWidth = 130;
        label.wrap = true;

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "category";
        series.dataFields.valueX = "value";
        series.tooltipText = "{valueX.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;

        let labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.dx = 10;
        labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.')}";
        labelBullet.locationX = 1;
        labelBullet.label.fill = am4core.color("#fff");

        let legend = new am4charts.Legend();
        legend.parent = chart.chartContainer;
        legend.itemContainers.template.togglable = false;
        legend.marginTop = 20;
        legend.disabled = true;

        series.events.on("ready", function(ev) {
            let legenddata = [];
            series.columns.each(function(column) {
              legenddata.push({
                name: column.dataItem.categoryY,
                fill: column.fill
              });
            });
            legend.data = legenddata;
          });
        
        

        series.columns.template.adapter.add("fill", function(fill, target){

            return GRAPH_COLORS[target.dataItem.index]

        });

        categoryAxis.sortBySeries = series;
        chart.data = data;

        chart.responsive.enabled = true;
        chart.responsive.rules.push({
            relevant: function(target) {

              if (target.pixelWidth <= 420) {
                  
                    return true;
              }
              
              return false;
            },
            state: function(target, stateId) {
                
              if (target instanceof am4charts.Chart) {

                let state = target.states.create(stateId);
                state.properties.paddingTop = 40;
                state.properties.paddingRight = 5;
                state.properties.paddingBottom = 0;
                state.properties.paddingLeft = 5;

                return state;

              }

              if (target instanceof am4charts.AxisRendererY) {

                let state = target.labels.template.states.create(stateId);
                state.properties.fontSize = 0;

                return state;

            }

            if (target instanceof am4charts.Legend) {

                let state = target.states.create(stateId);
                state.properties.disabled = false;
                state.properties.position = 'center';
                state.properties.paddingLeft = 0;
                state.properties.marginLeft = 0;
                state.properties.fontSize = 11;
                
                return state;

            }
            
              return null;

            }
          });
        
        this.chart = chart;
        
    }

    render() {

        const { id } = this.state;

        return (
            <div id={id} style={{width:'100%', height: '500px'}}></div>
            
        );

    }
}

export default BarChartView;