import React, { Component } from 'react'
import I18n from '../../../../../../../../i18n'
import { toHHMMSS } from '../../../../../../../../utils/formatTime'
import { StatusItem, YRow, YTable } from '../../../../../../../styled/components/tables'
import moment from 'moment'
import { Menu, Pagination } from 'semantic-ui-react'
import _ from 'lodash'
import SessionModel from '../../../../../../../../data/models/sessions/sessions'

export default class PracticeTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sessions:[],
            pageNumber: 1,
            recordsPerPage: 6,
            filteredSessions: [],
            activePage:1
        }

    }

    componentDidMount(){

        this.getUserSessionsInfos();

    }

    getUserSessionsInfos = async () => {

        const { match: { params: { userId } }, openLoader, closeLoader } = this.props;
        const { recordsPerPage } = this.state;

        try {

            openLoader();
            const sessions = await SessionModel.getAssignedAdvisorUserSessions(userId);
            this.setState({
                sessions: sessions.data,
                filteredSessions: sessions.data.slice(0, recordsPerPage)
            });
            
        } catch (error) {

            
        } finally {

            closeLoader();

        }

    }

    onPageChange = (e, pageInfo) => {

        const { sessions, recordsPerPage } = this.state;
        //0 * 5 , 1 * 5 => 1*5 , 2 * 5 
        this.setState({activePage: pageInfo.activePage, filteredSessions: sessions.slice((pageInfo.activePage - 1) * recordsPerPage, pageInfo.activePage * recordsPerPage) });
        
    }

    render() {

        const { sessions, filteredSessions, recordsPerPage, activePage } = this.state;

        return !_.isEmpty(filteredSessions) ? (
            <YTable stackable padded='very' selectable style={{marginTop:'20px'}}>
                <YTable.Header>
                    <YRow>
                        <YTable.HeaderCell>{I18n.t('users.session')}</YTable.HeaderCell>
                        <YTable.HeaderCell >{I18n.t('users.startDate')}</YTable.HeaderCell>
                        <YTable.HeaderCell >{I18n.t('users.startEnd')}</YTable.HeaderCell>
                        <YTable.HeaderCell>{I18n.t('users.timeElapsed')}</YTable.HeaderCell>
                        <YTable.HeaderCell>{I18n.t('users.status')}</YTable.HeaderCell>
                    </YRow>
                </YTable.Header>
                <YTable.Body>
                    {
                        _.map(filteredSessions, ({title, status, timeElapsed, endDate, startDate}, index) => (

                            <YRow key={'s-'+ index}>
                                <YTable.Cell>
                                    <span>{_.get(title, 'ES', false ) ? title.ES : I18n.t('users.customSequence')}</span>
                                </YTable.Cell>
                                <YTable.Cell>{moment(startDate).format('DD/MM/YYYY')}</YTable.Cell>
                                <YTable.Cell>{ startDate && moment(startDate).format('HH:mm:ss') } - {endDate && moment(endDate).format('HH:mm:ss') } </YTable.Cell>
                                <YTable.Cell>{ toHHMMSS(timeElapsed) }</YTable.Cell>
                                <YTable.Cell>
                                    <StatusItem status={status}>
                                        <span className="upper">{status}</span>
                                    </StatusItem>
                                </YTable.Cell>
                            </YRow>

                        ))
                    }
                </YTable.Body>
                <YTable.Footer>
                <YRow>
                    <YTable.HeaderCell colSpan='8'>
                        <Menu floated='right'>
                            <Pagination
                                onPageChange={this.onPageChange}
                                totalPages={ Math.ceil(sessions.length / recordsPerPage) }
                                ellipsisItem={null}
                                activePage={activePage}
                                boundaryRange={0}
                                size='mini'
                            />
                        </Menu>
                        <div style={{clear:"both"}}></div>
                    </YTable.HeaderCell>
                </YRow>
            </YTable.Footer>
            </YTable>
        ): <p className="caption regular">{I18n.t('messages.emptyData')}</p>
    }
}
